import React from "react";
import "./box.scss";

const Box = (props, { onClick }) => {
  const className = {
    box: "box",
    purple: props.purple && "box-purple",
    fullheight: props.fullheight && "box-fullheight",
    cursor: "poinnter",
  };

  return (
    <div className={Object.values(className).join(" ")} onClick={onClick}>
      {props.children}
    </div>
  );
};

export default Box;
