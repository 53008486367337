import React, { useContext, useEffect, useState, useRef } from "react";
import Button from "../components/Button";
import MainLayout from "../layout/MainLayout";
import axios from "axios";
import config from "../configs/config";
import Box from "../components/box/Box";
import OfficeForm from "../components/dashboard/office/OfficeForm";
import Loading from "../components/Loading/index";
import { toast } from "react-toastify";
import Modal from "../components/CustomModal/Modal";
import OfficeTable from "../components/dashboard/office/OfficeTable";
import ViewAlert from "../components/dashboard/office/ViewAlert";
import { MainContext } from "../middleware/Contexts";
import Alert from "../components/CustomAlert/Alert";

const Office = () => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [isAddNewOffice, setIsAddNewOffice] = useState(false);
  const [offices, setOffices] = useState([]);
  const [isOnUpdateProcess, setIsOnUpdateProcess] = useState(false);
  const [isLoadingOffices, setIsLoadingOffices] = useState(false);
  const [isOnViewSingleOffice, setIsOnViewSingleOffice] = useState(false);
  const [isOnViewOffice, setIsOnViewOffice] = useState([]);
  const [isOnDeletingOffice, setIsOnDeletingOffice] = useState(false);
  const [selectedOfficeId, setSelectedOfficeId] = useState("");
  const [isDeletingOffice, setIsDeletingOffice] = useState(false);
  const [officeToUpdate, setOfficeToUpdate] = useState(null);
  const [officeValueToSearch, setOfficeValueToSearch] = useState("");
  const [isSearchingOffice, setIsSearchingOffice] = useState(false);

  const [lastPage, setLastPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPrevLoading, setIsPrevLoading] = useState(false);

  // Open and Close modal for adding new client
  const onAddNewOfficeHandler = () => {
    setIsAddNewOffice(!isAddNewOffice);
    setIsOnUpdateProcess(false);
  };

  // Adding added client to the list of clients
  const returnNewAddedOffices = (data) => {
    if (isOnUpdateProcess) {
      offices.map((office) => {
        if (office.id === data.data.data.id) {
          office.name = data.data.data.name;
          office.ownerName = data.data.data.ownerName;
          office.ownerPhone = data.data.data.ownerPhone;
          office.province = data.data.data.province;
          office.district = data.data.data.district;
          office.sector = data.data.data.sector;
          office.price = data.data.data.price;
          office.description = data.data.data.description;
        }
      });
    } else {
      setOffices((current) => [data.data.data, ...current]);
    }
    setTimeout(() => {
      onAddNewOfficeHandler();
    }, 800);
  };

  //update office
  const updateOffice = (office) => {
    setOfficeToUpdate(office);
    setIsOnUpdateProcess(true);
    setIsAddNewOffice(!isAddNewOffice);
  };
  // Get list of Offices
  const getAllOfficesHandler = () => {
    setIsLoadingOffices(true);
    axios
      .get(`${config.apiBaseUrl}/offices/list?page=${nextPage}&limit=10`, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setOffices(response.data.data);
        setLastPage(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoading(false);
        setIsNextLoading(false);
        setIsLoadingOffices(false);
      });
  };
  useEffect(() => {
    getAllOfficesHandler();
  }, []);

  useEffect(() => {
    if (lastPage) getAllOfficesHandler();
  }, [nextPage]);

  // Getting users on the next page
  const getOfficesListOnNextPage = () => {
    setIsNextLoading(true);
    setNextPage(nextPage + 1);
  };
  const getOfficesListOnPrevPage = () => {
    setIsPrevLoading(true);
    setNextPage(nextPage - 1);
  };

  // Deleting office
  const deleteOfficeWarning = (id) => {
    setIsOnDeletingOffice(!isOnDeletingOffice);
    setIsOnViewSingleOffice(isOnViewSingleOffice);
    setSelectedOfficeId(id);
  };
  const deleteOffice = () => {
    setIsDeletingOffice(true);
    axios
      .delete(
        `${config.apiBaseUrl}/offices/delete-office/${selectedOfficeId}`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setOffices(offices.filter((office) => office.id !== selectedOfficeId));
        setIsOnDeletingOffice(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingOffice(false);
      });
  };
  //View single office
  const getSingleOfficeHandler = (id) => {
    setIsOnViewSingleOffice(!isOnViewSingleOffice);
    const data = offices.find((office) => office?.id === id);
    setIsOnViewOffice([data]);
  };

  // Search office
  const searchOfficeHandler = (event) => {
    if (event.keyCode === 13) {
      if (!officeValueToSearch) {
        return toast.error(
          "Please type either name, sector, district or province",
          { theme: "colored" }
        );
      }
      setIsSearchingOffice(true);
      axios
        .get(
          `${config.apiBaseUrl}/offices/search-by-value?data=${officeValueToSearch} `,
          {
            headers: { authorization: authUser.token },
          }
        )
        .then((response) => {
          setOffices(response.data);
        })
        .catch((error) => {
          if (error.response?.data.error) {
            return toast.error(error.response.data.error, { theme: "colored" });
          }
          if (error.response?.data.message) {
            return toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          setIsSearchingOffice(false);
          setLastPage(0);
          setNextPage(1);
        });
    }
  };

  return (
    <MainLayout>
      {/* Office form modal */}
      {isAddNewOffice && (
        <Modal handleClicks={onAddNewOfficeHandler}>
          <OfficeForm
            returnNewAddedOffice={returnNewAddedOffices}
            isUpdateInProcess={isOnUpdateProcess}
            officeToUpdate={officeToUpdate}
            handleView={onAddNewOfficeHandler}
          />
        </Modal>
      )}
      {/* view single appartment */}
      {isOnViewSingleOffice && (
        <Modal handleClicks={getSingleOfficeHandler}>
          <ViewAlert
            singleoffice={isOnViewOffice}
            title="View apartment"
            handleView={getSingleOfficeHandler}
          />
        </Modal>
      )}
      {/* Pop up warning while deleting */}
      {isOnDeletingOffice && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this client"
          handleCancel={deleteOfficeWarning}
          handleContinue={deleteOffice}
          isLoading={isDeletingOffice}
        />
      )}
      <div className="row">
        <div className="col-12 col-md-12 col-sm-12 mb">
          <div className="row">
            <div className="col-6 col-md-6 col-sm-12 searchbox">
              <Button
                width="8rem"
                border="#FF9066 solid 1.5px"
                text="Add Office +"
                color="#FF9066"
                bcolor="white"
                onClick={onAddNewOfficeHandler}
              />
              <div className="group">
                <svg className="icon" aria-hidden="true" viewBox="0 0 24 24">
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                  </g>
                </svg>
                <input
                  placeholder="Search Offices"
                  type="search"
                  className="input"
                  onChange={(event) =>
                    setOfficeValueToSearch(event.target.value)
                  }
                  onKeyDown={searchOfficeHandler}
                />
                {isSearchingOffice && <span>Loading...</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Box>
        <h3>Offices list</h3>
        {offices.length ? (
          <OfficeTable
            offices={offices}
            getSingleOfficeHandler={getSingleOfficeHandler}
            updateOffice={updateOffice}
            deleteOffice={deleteOfficeWarning}
            getAllOfficesHandler={getAllOfficesHandler}
            lastPage={lastPage}
            nextPage={nextPage}
            isPrevLoading={isPrevLoading}
            isNextLoading={isNextLoading}
            handleClickOnNext={getOfficesListOnNextPage}
            handleClickOnPrev={getOfficesListOnPrevPage}
          />
        ) : (
          ""
        )}
        {isLoadingOffices && <Loading />}
      </Box>
    </MainLayout>
  );
};

export default Office;
