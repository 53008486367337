import { Title } from "chart.js";
import React from "react";
import "./Alert.scss";

const Alert = ({
  warningMessage,
  isLoading,
  handleCancel,
  handleContinue,
  title,
}) => {
  return (
    <div className="alert-container">
      <div className="alert-shadow"></div>
      <div className="alert-center">
        <div className="alert-header">
          <h3>{title}</h3>
        </div>
        <div className="alert-content">
          <p>{warningMessage}</p>
          <div>
            <button
              style={{
                border: "#4E4D4F solid 1.5px",
                color: "#4E4D4F",
                backgroundColor: "white",
              }}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              style={{
                border: "#FF9066 solid 1.5px",
                color: "white",
                backgroundColor: "#FF9066",
              }}
              onClick={handleContinue}
            >
              {isLoading ? <div className="on-loading"></div> : "Yes"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
