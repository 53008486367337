import React, { useState, useEffect, useContext } from "react";
import Button from "../../../Button";
import axios from "axios";
import { GrFormClose } from "react-icons/gr";
import config from "../../../../configs/config";
import { MainContext } from "../../../../middleware/Contexts";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CheckoutForm = ({ returnCheckoutClient, id, handleView }) => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [outDate, setOutDate] = useState("");
  const [isCheckingOut, setIsCheckingOut] = useState(false);

  const clearForm = () => {
    setOutDate("");
  };

  const clientCheckout = (e) => {
    e.preventDefault();
    setIsCheckingOut(true);
    let data = null;
    data = {
      id: id,
      outDate,
    };
    axios
      .post(`${config.apiBaseUrl}/houses-clients/checkout `, data, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        clearForm();
        returnCheckoutClient(response);
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => setIsCheckingOut(false));
  };

  return (
    <div>
      <form className="userform" onSubmit={clientCheckout}>
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <h3>Checkout The Client</h3>
        <div className="inputform">
          <div className="row">
            <div className="col-12 col-md-12 col-sm-12 mb">
              <label for="name">Client checkout date</label>
              <input
                type="date"
                placeholder="OutDate"
                required
                onChange={(event) => setOutDate(event.target.value)}
                value={outDate}
              />
            </div>{" "}
          </div>{" "}
        </div>
        <div className="form-button row ">
          <Button
            width="90%"
            border="#FF9066 solid 1px "
            text="Checkout client"
            color="#FF9066"
            bcolor="white"
            className="col-2"
            isLoading={isCheckingOut}
          />
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;
