import React, { useContext, useEffect, useState, useRef } from "react";
import Button from "../components/Button";
import MainLayout from "../layout/MainLayout";
import axios from "axios";
import config from "../configs/config";
import Box from "../components/box/Box";
import HouseForm from "../components/dashboard/house/HouseForm";
import Loading from "../components/Loading/index";
import { toast } from "react-toastify";
import Modal from "../components/CustomModal/Modal";
import HouseTable from "../components/dashboard/house/HouseTable";
import ViewAlert from "../components/dashboard/house/ViewAlert";
import { MainContext } from "../middleware/Contexts";
import Alert from "../components/CustomAlert/Alert";

const House = () => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [isAddNewHouse, setIsAddNewHouse] = useState(false);
  const [houses, setHouses] = useState([]);
  const [selectedHouse, setSelectedHouse] = useState(false);
  const [isOnUpdateProcess, setIsOnUpdateProcess] = useState(false);
  const [isUpdateInProcess, setIsUpdateInProcess] = useState(false);
  const [isLoadingHouses, setIsLoadingHouses] = useState(false);
  const [isOnViewSingleHouse, setIsOnViewSingleHouse] = useState(false);
  const [isOnViewHouse, setIsOnViewHouse] = useState([]);
  const [isOnDeletingHouse, setIsOnDeletingHouse] = useState(false);
  const [selectedHouseId, setSelectedHouseId] = useState("");
  const [isDeletingHouse, setIsDeletingHouse] = useState(false);
  const [houseToUpdate, setHouseToUpdate] = useState(null);
  const [houseValueToSearch, setHouseValueToSearch] = useState("");
  const [isSearchingHouse, setIsSearchingHouse] = useState(false);

  const [lastPage, setLastPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPrevLoading, setIsPrevLoading] = useState(false);

  // Open and Close modal for adding new client
  const onAddNewHouseHandler = () => {
    setIsAddNewHouse(!isAddNewHouse);
    setIsOnUpdateProcess(false);
  };

  // Adding added client to the list of clients
  const returnNewAddedHouses = (data) => {
    if (isOnUpdateProcess) {
      houses.map((house) => {
        if (house.id === data.data.data.id) {
          house.name = data.data.data.name;
          house.ownerName = data.data.data.ownerName;
          house.ownerPhone = data.data.data.ownerPhone;
          house.province = data.data.data.province;
          house.district = data.data.data.district;
          house.sector = data.data.data.sector;
          house.price = data.data.data.price;
          house.description = data.data.data.description;
          house.hasKitchen = data.data.data.hasKitchen;
          house.LivingRooms = data.data.data.LivingRooms;
          house.bedRooms = data.data.data.bedRooms;
          house.bathRooms = data.data.data.bathRooms;
        }
      });
    } else {
      setHouses((current) => [data.data.data, ...current]);
    }
    setTimeout(() => {
      onAddNewHouseHandler();
    }, 800);
  };

  //update house
  const updateHouse = (house) => {
    setHouseToUpdate(house);
    setIsOnUpdateProcess(true);
    setIsAddNewHouse(!isAddNewHouse);
  };
  // Get list of Houses
  const getAllHousesHandler = () => {
    setIsLoadingHouses(true);
    axios
      .get(`${config.apiBaseUrl}/houses/list?page=${nextPage}&limit=10`, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setHouses(response.data.data);
        setLastPage(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoading(false);
        setIsNextLoading(false);
        setIsLoadingHouses(false);
      });
  };
  useEffect(() => {
    getAllHousesHandler();
  }, []);

  useEffect(() => {
    if (lastPage) getAllHousesHandler();
  }, [nextPage]);

  // Getting users on the next page
  const getHousesListOnNextPage = () => {
    setIsNextLoading(true);
    setNextPage(nextPage + 1);
  };
  const getHousesListOnPrevPage = () => {
    setIsPrevLoading(true);
    setNextPage(nextPage - 1);
  };

  // Deleting house
  const deleteHouseWarning = (id) => {
    setIsOnDeletingHouse(!isOnDeletingHouse);
    setIsOnViewSingleHouse(isOnViewSingleHouse);
    setSelectedHouseId(id);
  };
  const deleteHouse = () => {
    setIsDeletingHouse(true);
    axios
      .delete(`${config.apiBaseUrl}/houses/delete-house/${selectedHouseId}`, {
        headers: { authorization: authUser.token },
      })
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setHouses(houses.filter((house) => house.id !== selectedHouseId));
        setIsOnDeletingHouse(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingHouse(false);
      });
  };
  //View single house
  const getSingleHouseHandler = (id) => {
    setIsOnViewSingleHouse(!isOnViewSingleHouse);
    const data = houses.find((house) => house?.id === id);
    setIsOnViewHouse([data]);
  };

  // Search house
  const searchHouseHandler = (event) => {
    if (event.keyCode === 13) {
      if (!houseValueToSearch) {
        return toast.error(
          "Please type either name, sector, district or province",
          { theme: "colored" }
        );
      }
      setIsSearchingHouse(true);
      axios
        .get(
          `${config.apiBaseUrl}/houses/search-by-value?data=${houseValueToSearch} `,
          {
            headers: { authorization: authUser.token },
          }
        )
        .then((response) => {
          setHouses(response.data);
        })
        .catch((error) => {
          if (error.response?.data.error) {
            return toast.error(error.response.data.error, { theme: "colored" });
          }
          if (error.response?.data.message) {
            return toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          setIsSearchingHouse(false);
          setLastPage(0);
          setNextPage(1);
        });
    }
  };

  return (
    <MainLayout>
      {/* House form modal */}
      {isAddNewHouse && (
        <Modal handleClicks={onAddNewHouseHandler}>
          <HouseForm
            returnNewAddedHouse={returnNewAddedHouses}
            isUpdateInProcess={isOnUpdateProcess}
            // selectedHouse={houseToUpdate}
            houseToUpdate={houseToUpdate}
            handleView={onAddNewHouseHandler}
          />
        </Modal>
      )}

      {/* view single house */}
      {isOnViewSingleHouse && (
        <Modal handleClicks={getSingleHouseHandler}>
          <ViewAlert
            singlehouse={isOnViewHouse}
            title="View apartment"
            warningMessage="want see this client"
            handleView={getSingleHouseHandler}
          />
        </Modal>
      )}

      {/* Pop up warning while deleting */}
      {isOnDeletingHouse && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this house"
          handleCancel={deleteHouseWarning}
          handleContinue={deleteHouse}
          isLoading={isDeletingHouse}
        />
      )}

      <div className="row">
        <div className="col-12 col-md-12 col-sm-12 mb">
          <div className="row">
            <div className="col-6 col-md-6 col-sm-12 searchbox">
              <Button
                width="8rem"
                border="#FF9066 solid 1.5px"
                text="Add House +"
                color="#FF9066"
                bcolor="white"
                onClick={onAddNewHouseHandler}
              />
              <div className="group">
                <svg className="icon" aria-hidden="true" viewBox="0 0 24 24">
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                  </g>
                </svg>
                <input
                  placeholder="Search house"
                  type="search"
                  className="input"
                  onChange={(event) =>
                    setHouseValueToSearch(event.target.value)
                  }
                  onKeyDown={searchHouseHandler}
                />
                {isSearchingHouse && <span>Loading...</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Box>
        <h3>Houses list</h3>
        {houses.length ? (
          <HouseTable
            houses={houses}
            getSingleHouseHandler={getSingleHouseHandler}
            updateHouse={updateHouse}
            deleteHouse={deleteHouseWarning}
            getAllHousesHandler={getAllHousesHandler}
            lastPage={lastPage}
            nextPage={nextPage}
            isPrevLoading={isPrevLoading}
            isNextLoading={isNextLoading}
            handleClickOnNext={getHousesListOnNextPage}
            handleClickOnPrev={getHousesListOnPrevPage}
          />
        ) : (
          ""
        )}
        {isLoadingHouses && <Loading />}
      </Box>
    </MainLayout>
  );
};

export default House;
