export const sidebarNav = [
  {
    link: "/dashboard",
    section: "statistics",
    icon: <i className="bx bx-home-alt"></i>,
    text: "Statistics",
    role: ["Admin", "Accountant"],
  },

  {
    section: "apartment",
    icon: <i className="bx bx-arch"></i>,
    text: "Apartment",
    role: ["Admin", "Accountant", "Receptionist"],

    childrens: [
      {
        link: "/apartment/apartment",
        section: "apartment",
        icon: <i className="bx bxs-home-smile"></i>,
        text: "Apartment",
        role: ["Admin", "Accountant", "Receptionist"],
      },
      {
        link: "/apartment/booked",
        section: "booked",
        icon: <i className="bx bxs-home-smile"></i>,
        text: "Booked",
        role: ["Admin", "Accountant", "Receptionist"],
      },
    ],
  },
  {
    link: "/house",
    section: "house",
    icon: <i className="bx bxs-home-smile"></i>,
    text: "Houses",
    role: ["Admin", "Accountant"],
  },

  {
    link: "/offices",
    section: "offices",
    icon: <i className="bx bx-cube"></i>,
    text: "Offices",
    role: ["Admin", "Accountant"],
  },
  {
    section: "website",
    icon: <i className="bx bx-planet"></i>,
    text: "Website",
    role: ["Admin", "Receptionist"],
    childrens: [
      {
        link: "/website/partener",
        section: "partener",
        icon: <i className="bx bx-planet"></i>,
        text: "Partener",
        role: ["Admin"],
      },
      {
        link: "/website/services",
        section: "services",
        icon: <i className="bx bx-planet"></i>,
        text: "Services req",
        role: ["Admin"],
      },
      {
        link: "/website/emails",
        section: "emails",
        icon: <i className="bx bx-planet"></i>,
        text: "Emails",
        role: ["Admin"],
      },
      {
        link: "/website/property",
        section: "property",
        icon: <i className="bx bx-planet"></i>,
        text: "Property",
        role: ["Admin"],
      },
      {
        link: "/website/announcement",
        section: "announcement",
        icon: <i className="bx bx-planet"></i>,
        text: "Announcement",
        role: ["Admin"],
      },
      {
        link: "/website/testimonial",
        section: "testimonial",
        icon: <i className="bx bx-planet"></i>,
        text: "Testimonial",
        role: ["Admin"],
      },
    ],
  },
  {
    section: "finances",
    icon: <i className="bx bx-money"></i>,
    text: "Finances",
    role: ["Admin", "Accountant", "Receptionist"],
    childrens: [
      // {
      //   link: "/finances/home",
      //   section: "Report",
      //   icon: <i className="bx bx-money"></i>,
      //   text: "Report",
      //   role: ["Admin", "Accountant"],
      // },
      {
        link: "/finances/office",
        section: "Offices",
        icon: <i className="bx bx-money"></i>,
        text: "Office",
        role: ["Admin", "Accountant"],
      },
      {
        link: "/finances/house",
        section: "house",
        icon: <i className="bx bx-money"></i>,
        text: "House",
        role: ["Admin", "Accountant"],
      },
      {
        link: "/finances/apartment",
        section: "apartment",
        icon: <i className="bx bx-money"></i>,
        text: "Apartment",
        role: ["Admin", "Accountant", "Receptionist"],
      },
    ],
  },
  // {
  //   link: "/clients",
  //   section: "clients",
  //   icon: <i className="bx bx-user"></i>,
  //   text: "Clients",
  //   role: ["Admin", "Accountant", "Receptionist"],
  // },
  {
    link: "/users",
    section: "users",
    icon: <i className="bx bx-line-chart"></i>,
    text: "Users",
    role: ["Admin"],
  },
  {
    link: "/settings",
    section: "settings",
    icon: <i className="bx bx-cog"></i>,
    text: "Settings",
    role: ["Admin", "Accountant", "Receptionist"],
  },
];
