import axios from "axios";
import { React, useContext, useEffect, useState } from "react";
import config from "../../../configs/config.js";
import { MainContext } from "../../../middleware/Contexts.js";
import Button from "../../Button.js";
import "../../form/form.scss";
import { GrFormClose } from "react-icons/gr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const UserForm = ({
  returnNewAddedUser,
  isUpdateInProcess,
  selectedUser,
  handleView,
}) => {
  useEffect(() => {
    if (isUpdateInProcess) {
      setFullName(selectedUser.fullName);
      setGender(selectedUser.gender);
      setPhone(selectedUser.phone);
      setEmail(selectedUser.email);
      setProvince(selectedUser.province);
      setDistrict(selectedUser.district);
      setSector(selectedUser.sector);
      setRole(selectedUser.role);
    }
  }, []);

  const { authUser, setAuthUser } = useContext(MainContext);

  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [sector, setSector] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAddingNewUser, setIsAddingNewUser] = useState(false);

  const clearForm = () => {
    setFullName("");
    setGender("");
    setPhone("");
    setEmail("");
    setProvince("");
    setDistrict("");
    setSector("");
    setRole("");
    setPassword("");
    setConfirmPassword("");
  };

  const addNewUser = (e) => {
    e.preventDefault();
    setIsAddingNewUser(true);
    let data = null;
    if (isUpdateInProcess) {
      data = {
        id: selectedUser.id,
        fullName,
        gender,
        phone,
        email,
        province,
        district,
        sector,
        role,
      };
    } else {
      data = {
        fullName,
        gender,
        phone,
        email,
        province,
        district,
        sector,
        role,
        password,
        confirmPassword,
      };
    }

    const userUrl = isUpdateInProcess ? "/users/edit" : "/users/add";

    axios
      .post(`${config.apiBaseUrl}${userUrl}`, data, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        clearForm();
        returnNewAddedUser(response);
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => setIsAddingNewUser(false));
  };

  return (
    <>
      <form className="userform" onSubmit={addNewUser}>
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <h3>Add new user</h3>
        <div className="inputform">
          <div className="row">
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">User Full Name</label>
              <input
                type="text"
                value={fullName}
                required
                placeholder="Full name"
                onChange={(event) => setFullName(event.target.value)}
              />

              <label for="name">User mobile</label>
              <input
                type="text"
                value={phone}
                required
                placeholder="Phone"
                onChange={(event) => setPhone(event.target.value)}
              />
              <label for="name">User Email</label>
              <input
                type="email"
                value={email}
                required
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
              />
              <label for="name">User Gender</label>
              <select
                required
                onChange={(event) => setGender(event.target.value)}
                value={gender}
              >
                <option value="">--- Select Gender ---</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>

              <label for="name">User Provinnce</label>
              <input
                type="text"
                value={province}
                required
                placeholder="Province"
                onChange={(event) => setProvince(event.target.value)}
              />
            </div>
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">User District</label>
              <input
                type="text"
                value={district}
                required
                placeholder="District"
                onChange={(event) => setDistrict(event.target.value)}
              />
              <label for="name">User Sector</label>
              <input
                type="text"
                value={sector}
                required
                placeholder=" Sector"
                onChange={(event) => setSector(event.target.value)}
              />
              {!isUpdateInProcess && (
                <>
                  <label for="name">User Password</label>
                  <input
                    type="password"
                    value={password}
                    required
                    placeholder="Password"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </>
              )}
              {!isUpdateInProcess && (
                <>
                  <label for="name">Comfirm User Password</label>
                  <input
                    type="password"
                    value={confirmPassword}
                    required
                    placeholder="Confirm Password"
                    onChange={(event) => setConfirmPassword(event.target.value)}
                  />
                </>
              )}
              <label for="name">User Role</label>
              <select
                required
                onChange={(event) => setRole(event.target.value)}
                value={role}
              >
                <option value="">--- Select Role ---</option>
                <option value="Receptionist">Receptionist</option>
                <option value="Accountant">Accountant</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form-button row ">
          {isUpdateInProcess ? (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Edit user"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewUser}
            />
          ) : (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Save & Add"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewUser}
            />
          )}
        </div>
      </form>
    </>
  );
};
export default UserForm;
