import React, { useRef, forwardRef } from "react";
import { useReactToPrint } from "react-to-print";
import { AiFillPrinter } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import "../../CustomModal/singleview.scss";
import moment from "moment";

const Input = forwardRef((props, ref) => {
  return <input ref={ref} {...props} />;
});
const Alert = ({
  isLoading,
  handleView,
  handleContinue,
  title,
  singleservice,
}) => {
  const printingRef = useRef();
  const hundlePrint = useReactToPrint({
    content: () => printingRef.current,
    documentTitle: "Sampro print",
    onafterprint: () => alert("print is ok"),
  });
  return (
    <div ref={printingRef} className="print">
      <div className="singleview-shadow">
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <div className="singleview-center">
          <div className="singleview-header">
            <h3>{title}</h3>
          </div>
          <div className="singleview-content">
            {singleservice?.map((service) => (
              <div key={service.id}>
                <div className="row">
                  <div className="col-6 col-md-6 col-sm-12 mb">
                    <p>
                      Date:{" "}
                      <span>
                        {moment(service.createdAt).format("yyyy-MM-DD")}
                      </span>
                    </p>
                    <p>
                      Name: <span>{service.fullName}</span>
                    </p>
                    <p>
                      Email: <span>{service.email}</span>
                    </p>

                    <p>
                      Telephone: <span>{service.phone}</span>
                    </p>
                    <p>
                      Service: <span>{service.service}</span>
                    </p>

                    <p>
                      Status: <span>{service.status}</span>
                    </p>
                  </div>
                  <div className="col-6 col-md-6 col-sm-12 mb">
                    <p>
                      Comment:
                      <br />
                      <span>{service.addComment}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="icons">
        <AiFillPrinter onClick={() => hundlePrint()} className="myprint" />
      </div>
    </div>
  );
};

export default Alert;
