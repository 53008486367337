import React, { useState, useEffect, useContext } from "react";
import Button from "../../../Button";
import axios from "axios";
import config from "../../../../configs/config";
import { MainContext } from "../../../../middleware/Contexts";
import { ToastContainer, toast } from "react-toastify";

import { GrFormClose } from "react-icons/gr";
import "react-toastify/dist/ReactToastify.css";

import { formatDate } from '../../../../utils/dateFormatterUtil';

const ClientForm = ({
  isUpdateInProcess,
  returnNewAddedClient,
  clientToUpdate,
  OfficeId,
  handleView,
}) => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nId, setNId] = useState("");
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [sector, setSector] = useState("");
  const [inDate, setInDate] = useState("");
  const [isAddingNewClient, setIsAddingNewClient] = useState(false);

  const clearForm = () => {
    setFullName("");
    setEmail("");
    setNId("");
    setGender("");
    setPhone("");
    setBirthDate("");
    setCountry("");
    setProvince("");
    setDistrict("");
    setSector("");
    setInDate("");
  };

  useEffect(() => {
    if (isUpdateInProcess) {
      setFullName(clientToUpdate.fullName);
      setEmail(clientToUpdate.email);
      setNId(clientToUpdate.nId);
      setGender(clientToUpdate.gender);
      setPhone(clientToUpdate.phone);
      setBirthDate(formatDate(clientToUpdate.birthDate));
      setCountry(clientToUpdate.country);
      setProvince(clientToUpdate.province);
      setDistrict(clientToUpdate.district);
      setSector(clientToUpdate.sector);
      setInDate(formatDate(clientToUpdate.inDate));
    }
  }, []);
  const addNewClient = (e) => {
    e.preventDefault();
    setIsAddingNewClient(true);
    let data = null;
    if (isUpdateInProcess) {
      data = {
        id: clientToUpdate.id,
        OfficeId: OfficeId,
        fullName,
        email,
        nId,
        gender,
        birthDate,
        phone,
        country,
        province,
        district,
        sector,
        inDate,
      };
    } else {
      data = {
        OfficeId: OfficeId,
        fullName,
        email,
        nId,
        gender,
        birthDate,
        phone,
        country,
        province,
        district,
        sector,
        inDate,
      };
    }

    const userUrl = isUpdateInProcess
      ? "/offices-clients/update"
      : "/offices-clients/create";

    axios
      .post(`${config.apiBaseUrl}${userUrl}`, data, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        clearForm();
        returnNewAddedClient(response);
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => setIsAddingNewClient(false));
  };

  return (
    <div>
      <form className="userform" onSubmit={addNewClient}>
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <h3>{isUpdateInProcess ? 'Edit client' : 'Add New Client'}</h3>
        <div className="inputform">
          <div className="row">
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">Client Name</label>
              <input
                type="text"
                value={fullName}
                required
                placeholder="client name"
                onChange={(event) => setFullName(event.target.value)}
              />
              <label for="name">Client Email</label>
              <input
                type="text"
                value={email}
                required
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
              />
              <label for="name">Client mobile number</label>
              <input
                type="text"
                value={phone}
                required
                placeholder="Phone"
                onChange={(event) => setPhone(event.target.value)}
              />
              <label for="name">Client National ID</label>
              <input
                type="text"
                value={nId}
                required
                placeholder="nId"
                onChange={(event) => setNId(event.target.value)}
              />
              <label for="name">Client birthdate</label>
              <input
                type="date"
                required
                onChange={(event) => setBirthDate(event.target.value)}
                value={birthDate}
                placeholder="birthDate"
              />
              <label for="name">Client Gender</label>
              <select
                type="text"
                value={gender}
                required
                onChange={(event) => setGender(event.target.value)}
              >
                <option value="">-----Select gender-----</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">Client's Country</label>
              <input
                type="text"
                value={country}
                required
                placeholder="country"
                onChange={(event) => setCountry(event.target.value)}
              />
              <label for="name">Client's Province</label>
              <input
                type="text"
                value={province}
                required
                placeholder="Province"
                onChange={(event) => setProvince(event.target.value)}
              />
              <label for="name">Client's District</label>
              <input
                type="text"
                value={district}
                required
                placeholder="District"
                onChange={(event) => setDistrict(event.target.value)}
              />
              <label for="name">Client's Sector</label>
              <input
                type="text"
                value={sector}
                required
                placeholder=" Sector"
                onChange={(event) => setSector(event.target.value)}
              />
              <label for="name">Client's CheckIn Date</label>
              <input
                type="date"
                value={inDate}
                required
                placeholder="inDate"
                onChange={(event) => setInDate(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-button row ">
          {isUpdateInProcess ? (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Edit client"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewClient}
            />
          ) : (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Save & Add"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewClient}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default ClientForm;
