import React from "react";
import CustomDropDown from "../../../CustomDropDown/CustomDropDown";
import Button from "../../../Button";

const OfficeTable = ({
  offices,
  addClientToOffice,
  availableOffice,
  lastPage,
  nextPage,
  isNextLoading,
  isPrevLoading,
  handleClickOnNext,
  handleClickOnPrev,
}) => {
  return (
    <div>
      <h3>Offices list</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Office Name</th>
            <th>Province</th>
            <th>District</th>
            <th>Sector</th>
            <th>price</th>
            <th>Available</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {offices?.map((office, index) => (
            <tr key={index}>
              <td>{office?.name}</td>
              <td>{office?.province}</td>
              <td>{office?.district}</td>
              <td>{office?.sector}</td>
              <td>{office?.price}</td>
              <td
                className={office.isAvailable ? "av-gr-status" : "un-rd-status"}
              >
                {office?.isAvailable ? "Yes" : "No"}
              </td>
              <td>
                <CustomDropDown>
                  <button onClick={() => addClientToOffice(office?.id)}>
                    Add client
                  </button>
                  <button onClick={() => availableOffice(office.id)}>
                    Make available
                  </button>
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-btn">
        {(nextPage > 1 || isPrevLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Prev"
            color="#FF9066"
            bcolor="white"
            isLoading={isPrevLoading}
            onClick={handleClickOnPrev}
          />
        )}
        {(nextPage < lastPage || isNextLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Next"
            color="#FF9066"
            bcolor="white"
            isLoading={isNextLoading}
            onClick={handleClickOnNext}
          />
        )}
      </div>
    </div>
  );
};

export default OfficeTable;
