import React, { useState, useEffect, useContext } from "react";
import PublishAlert from "../components/CustomAlert/PublishAlert";
import Button from "../components/Button";
import AnnouncementTable from "../components/dashboard/website/AnnouncementTable";
import AnnouncementPublishedTable from "../components/dashboard/website/AnnouncementPublishedTable";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Alert from "../components/CustomAlert/Alert";
import "react-tabs/style/react-tabs.css";
import MainLayout from "../layout/MainLayout";
import Box from "../components/box/Box";
import axios from "axios";
import Modal from "../components/CustomModal/Modal";
import AnnouncementForm from "../components/dashboard/website/AnnouncementForm";
import { MainContext } from "../middleware/Contexts";
import config from "../configs/config";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../components/Loading";
const Announcement = () => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [announcements, setAnnouncements] = useState([]);
  const [published, setPublished] = useState([]);
  const [isLoadingAnnouncement, setIsLoadingAnnouncement] = useState(false);
  const [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const [isOnUpdateProcess, setIsOnUpdateProcess] = useState(false);
  const [announcementToUpdate, setAnnouncementToUpdate] = useState([]);
  const [isAddNewAnnouncement, setIsAddNewAnnouncement] = useState(false);
  const [isOnPublishAnnouncement, setIsOnPublishAnnouncement] = useState(false);
  const [publishAnnouncementId, setPublishAnnouncementId] = useState(null);
  const [isPublishAnnouncement, setIsPublishAnnouncement] = useState(false);
  const [isOnDeletingAnnouncement, setIsOnDeletingAnnouncement] =
    useState(false);
  const [isOnViewSingleAnnouncement, setIsOnViewSingleAnnouncement] =
    useState(false);
  const [isDeletingAnnouncement, setIsDeletingAnnouncement] = useState(false);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(null);

  const [lastPage, setLastPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPrevLoading, setIsPrevLoading] = useState(false);

  // Open and Close modal for adding new Announcement
  const onAddNewAnnouncementHandler = () => {
    setIsAddNewAnnouncement(!isAddNewAnnouncement);
    setIsOnUpdateProcess(false);
  };

  const returnNewAddedAnnouncements = (data) => {
    if (isOnUpdateProcess) {
      announcements.map((announcement) => {
        if (announcement.id === data.data.data.id) {
          announcement.title = data.data.data.title;
          announcement.description = data.data.data.description;
        }
      });
    } else {
      setAnnouncements((current) => [data.data.data, ...current]);
    }
    setTimeout(() => {
      onAddNewAnnouncementHandler();
    }, 800);
  };
  //List of all announcements
  const getAllAnnouncementsHandler = () => {
    setIsLoadingAnnouncement(true);
    axios
      .get(
        `${config.apiBaseUrl}/announcements/admin?page=${nextPage}&limit=10`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        setAnnouncements(response.data.data);

        setLastPage(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoading(false);
        setIsNextLoading(false);
        setIsLoadingAnnouncement(false);
      });
  };
  useEffect(() => {
    getAllAnnouncementsHandler();
  }, []);

  useEffect(() => {
    getAllAnnouncementsHandler();
  }, [nextPage]);

  // Getting users on the next page
  const getAnnouncementsListOnNextPage = () => {
    setIsNextLoading(true);
    setNextPage(nextPage + 1);
  };
  const getAnnouncementsListOnPrevPage = () => {
    setIsPrevLoading(true);
    setNextPage(nextPage - 1);
  };

  //List of all published
  const getAllPublishedsHandler = () => {
    setIsLoadingPublish(true);
    axios
      .get(`${config.apiBaseUrl}/announcements/website?page=1&limit=100`, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setPublished(response.data.data);

        // setLastPage(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setIsPrevLoading(false);
        // setIsNextLoading(false);
        setIsLoadingPublish(false);
      });
  };
  useEffect(() => {
    getAllPublishedsHandler();
  }, []);

  // Warning for publish announcement
  const publishAnnouncementWarning = (id) => {
    setPublishAnnouncementId(id);
    setIsOnPublishAnnouncement(!isOnPublishAnnouncement);
  };

  // publish announcement
  const publishAnnouncement = () => {
    let data = null;
    setIsPublishAnnouncement(true);
    axios
      .post(
        `${config.apiBaseUrl}/announcements/publish-unpublish/${publishAnnouncementId}`,
        data,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });

        setAnnouncements(
          announcements.map((announcement) => {
            if (announcement.id === publishAnnouncementId) {
              return { ...announcement, status: res.data.data.status };
            }
            return announcement;
          })
        );

        // setAnnouncements(newState);
        setPublished((current) => [res.data.data, ...current]);
        setIsOnPublishAnnouncement(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsPublishAnnouncement(false);
      });
  };

  // Deleting Announcement
  const deleteAnnouncementWarning = (id) => {
    setIsOnDeletingAnnouncement(!isOnDeletingAnnouncement);
    setIsOnViewSingleAnnouncement(isOnViewSingleAnnouncement);
    setSelectedAnnouncementId(id);
  };
  const deleteAnnouncement = () => {
    setIsDeletingAnnouncement(true);
    axios
      .delete(`${config.apiBaseUrl}/announcements/${selectedAnnouncementId}`, {
        headers: { authorization: authUser.token },
      })
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setAnnouncements(
          announcements.filter(
            (announcement) => announcement.id !== selectedAnnouncementId
          )
        );
        setIsOnDeletingAnnouncement(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingAnnouncement(false);
      });
  };
  return (
    <MainLayout>
      {/* Pop up warning while deleting */}
      {isOnDeletingAnnouncement && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this client"
          handleCancel={deleteAnnouncementWarning}
          handleContinue={deleteAnnouncement}
          isLoading={isDeletingAnnouncement}
        />
      )}

      {/* Publish Announcement */}
      {isOnPublishAnnouncement && (
        <PublishAlert
          title="Publish"
          warningMessage="Do you want to change the status of this announcement"
          handleCancel={publishAnnouncementWarning}
          handleContinue={publishAnnouncement}
          isLoading={isPublishAnnouncement}
        />
      )}
      {/* Announcement form modal */}
      {isAddNewAnnouncement && (
        <Modal handleClicks={onAddNewAnnouncementHandler}>
          <AnnouncementForm
            returnNewAddedAnnouncement={returnNewAddedAnnouncements}
            isUpdateInProcess={isOnUpdateProcess}
            announcementToUpdate={announcementToUpdate}
            handleView={onAddNewAnnouncementHandler}
          />
        </Modal>
      )}
      <div className="row">
        <div className="col-4 col-md-6 col-sm-12 mb">
          <Button
            width="10rem"
            border="#FF9066 solid 1.5px"
            text="Add Announcement"
            color="#FF9066"
            bcolor="white"
            onClick={onAddNewAnnouncementHandler}
          />
        </div>
      </div>

      <Box>
        <h3>Announcements list</h3>
        {announcements.length ? (
          <AnnouncementTable
            items={announcements}
            publishAnnouncement={publishAnnouncementWarning}
            deleteAnnouncement={deleteAnnouncementWarning}
            lastPage={lastPage}
            nextPage={nextPage}
            isPrevLoading={isPrevLoading}
            isNextLoading={isNextLoading}
            handleClickOnNext={getAnnouncementsListOnNextPage}
            handleClickOnPrev={getAnnouncementsListOnPrevPage}
          />
        ) : null}
        {!isLoadingAnnouncement && !announcements.length ? (
          <p>No data found</p>
        ) : null}
        {isLoadingAnnouncement && <Loading />}
      </Box>
    </MainLayout>
  );
};

export default Announcement;
