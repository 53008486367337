import React, { useState, useEffect, useContext } from "react";
import MainLayout from "../layout/MainLayout";
import Box from "../components/box/Box";
import Button from "../components/Button";
import ServiceTable from "../components/dashboard/website/ServiceTable";
import axios from "axios";
import Modal from "../components/CustomModal/Modal";
import config from "../configs/config";
import Loading from "../components/Loading";
import { MainContext } from "../middleware/Contexts";
import ViewService from "../components/dashboard/website/ViewService";
import Alert from "../components/CustomAlert/Alert";
import { toast } from "react-toastify";

const Service = () => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [services, setServices] = useState([]);
  const [isOnViewSingleService, setIsOnViewSingleService] = useState(false);
  const [isOnViewService, setIsOnViewService] = useState(false);
  const [isOnDeletingService, setIsOnDeletingService] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [isDeletingService, setIsDeletingService] = useState(false);
  const [isLoadingServices, setIsLoadingServices] = useState(false);

  const [lastPage, setLastPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPrevLoading, setIsPrevLoading] = useState(false);

  // Get list of Services
  const getAllServicesHandler = () => {
    setIsLoadingServices(true);
    axios
      .get(`${config.apiBaseUrl}/services?page=${nextPage}&limit=10`, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setServices(response.data.data);
        setLastPage(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoading(false);
        setIsNextLoading(false);
        setIsLoadingServices(false);
      });
  };
  useEffect(() => {
    getAllServicesHandler();
  }, []);

  useEffect(() => {
    if (lastPage) getAllServicesHandler();
  }, [nextPage]);

  // Getting users on the next page
  const getServicesListOnNextPage = () => {
    setIsNextLoading(true);
    setNextPage(nextPage + 1);
  };
  const getServicesListOnPrevPage = () => {
    setIsPrevLoading(true);
    setNextPage(nextPage - 1);
  };

  //View single service
  const getSingleServiceHandler = (id) => {
    setIsOnViewSingleService(!isOnViewSingleService);
    const data = services.find((service) => service?.id === id);
    setIsOnViewService([data]);
  };
  // Deleting service
  const deleteServiceWarning = (id) => {
    setIsOnDeletingService(!isOnDeletingService);
    setIsOnViewSingleService(isOnViewSingleService);
    setSelectedServiceId(id);
  };
  const deleteService = () => {
    setIsDeletingService(true);
    axios
      .delete(`${config.apiBaseUrl}/services/${selectedServiceId}`, {
        headers: { authorization: authUser.token },
      })
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setServices(
          services.filter((service) => service.id !== selectedServiceId)
        );
        setIsOnDeletingService(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingService(false);
      });
  };

  return (
    <MainLayout>
      {isOnViewSingleService && (
        <Modal handleClicks={getSingleServiceHandler}>
          <ViewService
            singleservice={isOnViewService}
            title="View service"
            handleView={getSingleServiceHandler}
          />
        </Modal>
      )}
      {/* Pop up warning while deleting */}
      {isOnDeletingService && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this requested services"
          handleCancel={deleteServiceWarning}
          handleContinue={deleteService}
          isLoading={isDeletingService}
        />
      )}

      <Box>
        <h3>Requested services</h3>
        {services.length ? (
          <ServiceTable
            services={services}
            getSingleServiceHandler={getSingleServiceHandler}
            deleteService={deleteServiceWarning}
            lastPage={lastPage}
            nextPage={nextPage}
            isPrevLoading={isPrevLoading}
            isNextLoading={isNextLoading}
            handleClickOnNext={getServicesListOnNextPage}
            handleClickOnPrev={getServicesListOnPrevPage}
          />
        ) : null}
        {isLoadingServices ? <Loading /> : null}
      </Box>
    </MainLayout>
  );
};

export default Service;
