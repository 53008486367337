import React, { useState } from "react";
import "./CustomDropDown.scss";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
const CustomDropDown = (props) => {
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const toggleDropDown = () => {
    setIsOpenDropDown(!isOpenDropDown);
  };
  return (
    <div className="drop-down-container">
      <div onClick={toggleDropDown} className="line-to-toggle">
        <p> Action</p>
        {isOpenDropDown ? (
          <RiCloseCircleFill className="delete" />
        ) : (
          <IoIosArrowDropdownCircle className="edit" />
        )}
      </div>
      {isOpenDropDown && (
        <div className="line-to-drop-down" onClick={toggleDropDown}>
          {props.children}
        </div>
      )}
    </div>
  );
};

export default CustomDropDown;
