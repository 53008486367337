import React, { useRef, forwardRef } from "react";
import "../../CustomModal/singleview.scss";
import { AiFillPrinter } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
// import { useRef } from "react";
const Input = forwardRef((props, ref) => {
  return <input ref={ref} {...props} />;
});
const Alert = ({
  isLoading,
  handleView,
  handleContinue,
  title,
  singleclients,
}) => {
  const printingRef = useRef();
  const hundlePrint = useReactToPrint({
    content: () => printingRef.current,
    documentTitle: "Sampro print",
    onafterprint: () => alert("print is ok"),
  });
  return (
    <div ref={printingRef} className="print">
      <div className="singleview-shadow">
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <div className="singleview-center">
          <div className="singleview-header">
            <h3>{title}</h3>
          </div>
          <div className="singleview-content">
            {singleclients.map((client) => (
              <div key={client.id} className="row">
                <div className="col-6">
                  <p>
                    Fullname: <span>{client.fullName}</span>
                  </p>

                  <p>
                    Email: <span>{client.email}</span>
                  </p>
                  <p>
                    Date:
                    <span>
                      {" "}
                      {moment(client.createdAt).format("yyyy-MM-DD")}
                    </span>
                  </p>
                  <p>
                    Gender: <span>{client.gender}</span>
                  </p>

                  <p>
                    Telephone: <span> {client.phone}</span>
                  </p>
                  <p>
                    Country: <span>{client.country}</span>
                  </p>
                  <p>
                    Apartment: <span>{client.Apartment.name}</span>
                  </p>
                  <p>
                    House: <span>{client.ApartmentsHouse.name}</span>
                  </p>
                </div>
                <div className="col-6">
                  <p>
                    Adults: <span> {client.adults}</span>
                  </p>
                  <p>
                    Children: <span> {client.children}</span>
                  </p>
                  <p>
                    Check-in Date:{" "}
                    <span>
                      {moment(client.arrivalDate).format("yyyy-MM-DD")}
                    </span>
                  </p>
                  <p>
                    Check-out Date:{" "}
                    <span>
                      {moment(client.departureDate).format("yyyy-MM-DD")}
                    </span>
                  </p>
                  <p>
                    Room Preference: <span>{client.roomPreference}</span>
                  </p>
                  <p>
                    Any Comment: <span>{client.anythingElse}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="icons">
        <AiFillPrinter onClick={() => hundlePrint()} className="myprint" />
      </div>
    </div>
  );
};

export default Alert;
