import React, { useContext, useEffect, useState } from "react";
import Box from "../components/box/Box";
import MainLayout from "../layout/MainLayout";
import Button from "../components/Button";
import PropertyForm from "../components/dashboard/website/PropertyForm";
import Modal from "../components/CustomModal/Modal";
import Loading from "../components/Loading";
import CustomDropDown from "../components/CustomDropDown/CustomDropDown";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import config from "../configs/config";
import Alert from "../components/CustomAlert/Alert";
import { MainContext } from "../middleware/Contexts";

const WebProperty = () => {
  const [isTogglePropertyForm, setIsTogglePropertyForm] = useState(false);
  const [isFetchingProperty, setIsFetchingProperty] = useState(false);

  const [allProperties, setAllProperties] = useState([]);

  const [isToggleDeletingPro, setIsToggleDeletingPro] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState(false);
  const [isisDeletingProperty, setIsisDeletingProperty] = useState(false);
  const [propertyToUpdate, setPropertyToUpdate] = useState(null);

  const { authUser, setAuthUser } = useContext(MainContext);

  useEffect(() => {
    getAllWebsiteProperty();
  }, []);

  // get all website property
  const getAllWebsiteProperty = () => {
    setIsFetchingProperty(true);
    axios
      .get(`${config.apiBaseUrl}/website-properties`)
      .then((response) => {
        setAllProperties(response.data);
      })
      .catch((error) => {})
      .finally(() => setIsFetchingProperty(false));
  };

  // toggle property modal
  const togglePropertyFormHandler = () => {
    setPropertyToUpdate(null);
    setIsTogglePropertyForm(!isTogglePropertyForm);
  };

  // called when add or update property
  const returnAddedWebProperty = (data) => {
    togglePropertyFormHandler();
    getAllWebsiteProperty();
  };

  // delete property
  const deleteWebProperty = () => {
    setIsisDeletingProperty(true);
    axios
      .delete(
        `${config.apiBaseUrl}/website-properties?id=${propertyToDelete}`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setAllProperties(
          allProperties.filter((pro) => pro.id !== propertyToDelete)
        );
        setIsToggleDeletingPro(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsisDeletingProperty(false);
      });
  };

  // Toggle delete property
  const togglePropertyDeleteAlert = (id) => {
    setIsToggleDeletingPro(!isToggleDeletingPro);
    setPropertyToDelete(id);
  };

  // toggle edit property
  const toggUpdateProperty = (data) => {
    setIsTogglePropertyForm(!isTogglePropertyForm);
    setPropertyToUpdate(data);
  };

  return (
    <MainLayout>
      <div className="row">
        <div className="col-6 col-md-6 col-sm-12 mb">
          <Button
            width="8rem"
            border="#FF9066 solid 1.5px"
            text="Add Property +"
            color="#FF9066"
            bcolor="white"
            onClick={togglePropertyFormHandler}
          />
        </div>
        <div>
          {isTogglePropertyForm && (
            <Modal handleClicks={togglePropertyFormHandler}>
              <PropertyForm
                returnAddedWebProperty={returnAddedWebProperty}
                propertyToUpdate={propertyToUpdate}
                handleView={togglePropertyFormHandler}
              />
            </Modal>
          )}
        </div>
        <div>
          {isToggleDeletingPro && (
            <Alert
              title="Warning"
              warningMessage="Are you sure you want delete this user"
              handleCancel={togglePropertyDeleteAlert}
              handleContinue={deleteWebProperty}
              isLoading={isisDeletingProperty}
            />
          )}
        </div>
      </div>
      <Box>
        <h3>Website properties</h3>
        <div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Rooms</th>
                <th>Price</th>
                <th>Type</th>
                <th>Location</th>
                <th>Location</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allProperties.map((item) => (
                <tr key={item?.id}>
                  <td>
                    <img src={item?.imageLink} />
                  </td>
                  <td>{item?.name}</td>
                  <td>{item.houseRooms}</td>
                  <td>{item.price}</td>
                  <td>{item.type}</td>
                  <td>{item.locationName}</td>
                  <td>{item.roadNumber}</td>
                  <td>
                    <CustomDropDown>
                      {/* No need of view action because all data are displayed */}
                      {/* <button>View</button> */}
                      <button onClick={() => toggUpdateProperty(item)}>
                        Edit
                      </button>
                      <button
                        onClick={() => togglePropertyDeleteAlert(item.id)}
                      >
                        Delete
                      </button>
                    </CustomDropDown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!allProperties.length && !isFetchingProperty ? (
            <p>No data found</p>
          ) : null}
        </div>
        {isFetchingProperty && <Loading />}
      </Box>
    </MainLayout>
  );
};

export default WebProperty;
