import axios from "axios";
import { React, useContext, useEffect, useState } from "react";
import config from "../../../configs/config.js";
import { MainContext } from "../../../middleware/Contexts.js";
import Button from "../../Button.js";
import { GrFormClose } from "react-icons/gr";
import "../../form/form.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "./ViewAlert.js";
export const ApartmentForm = ({
  returnNewAddedApartment,
  isUpdateInProcess,
  apartmentToUpdate,
  handleView,
}) => {
  useEffect(() => {
    if (isUpdateInProcess) {
      setApartmentName(apartmentToUpdate.name);
      setOwnerName(apartmentToUpdate.ownerName);
      setPhone(apartmentToUpdate.ownerPhone);
      setProvince(apartmentToUpdate.province);
      setDistrict(apartmentToUpdate.district);
      setSector(apartmentToUpdate.sector);
    }
  }, []);

  const { authUser, setAuthUser } = useContext(MainContext);

  const [name, setApartmentName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerPhone, setPhone] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [sector, setSector] = useState("");

  const [isAddingNewApartment, setIsAddingNewApartment] = useState(false);

  const clearForm = () => {
    setApartmentName("");
    setOwnerName("");
    setPhone("");
    setProvince("");
    setDistrict("");
    setSector("");
  };

  const addNewApartment = (e) => {
    e.preventDefault();
    setIsAddingNewApartment(true);
    let data = null;
    if (isUpdateInProcess) {
      data = {
        id: apartmentToUpdate.id,
        name,
        ownerName,
        ownerPhone,
        province,
        district,
        sector,
      };
    } else {
      data = {
        name,
        ownerName,
        ownerPhone,
        province,
        district,
        sector,
      };
    }

    const userUrl = isUpdateInProcess
      ? "/apartments/update-apartment "
      : "/apartments/add-new";

    axios
      .post(`${config.apiBaseUrl}${userUrl}`, data, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        clearForm();
        returnNewAddedApartment(response);
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => setIsAddingNewApartment(false));
  };

  return (
    <>
      <form className="userform" onSubmit={addNewApartment}>
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <h3>{isUpdateInProcess ? 'Edit Apartment' : 'Add New Apartment'}</h3>
        <div className="inputform">
          <div className="row">
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">Apartment Name</label>
              <input
                type="text"
                value={name}
                required
                placeholder="Apartment name"
                onChange={(event) => setApartmentName(event.target.value)}
              />
              <label for="name">Apartment owner's Name</label>
              <input
                type="text"
                required
                onChange={(event) => setOwnerName(event.target.value)}
                value={ownerName}
                placeholder="Owner name"
              />
              <label for="name">Apartment owner's mobile</label>
              <input
                type="text"
                value={ownerPhone}
                required
                placeholder="Phone"
                onChange={(event) => setPhone(event.target.value)}
              />
            </div>
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">Apartment Province</label>
              <input
                type="text"
                value={province}
                required
                placeholder="Province"
                onChange={(event) => setProvince(event.target.value)}
              />
              <label for="name">Apartment District</label>
              <input
                type="text"
                value={district}
                required
                placeholder="District"
                onChange={(event) => setDistrict(event.target.value)}
              />
              <label for="name">Apartment Sector</label>
              <input
                type="text"
                value={sector}
                required
                placeholder="Sector"
                onChange={(event) => setSector(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-button row ">
          <div className="col-6 col-md-6 col-sm-12 mb">
            {isUpdateInProcess ? (
              <Button
                width="90%"
                border="#FF9066 solid 1px "
                text="Edit apartment"
                color="#FF9066"
                bcolor="white"
                className="col-2"
                isLoading={isAddingNewApartment}
              />
            ) : (
              <Button
                width="90%"
                border="#FF9066 solid 1px "
                text="Save & Add"
                color="#FF9066"
                bcolor="white"
                className="col-2"
                isLoading={isAddingNewApartment}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
};
export default ApartmentForm;
