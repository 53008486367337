import { React, useState, useContext } from "react";
import { MainContext } from "../../middleware/Contexts";
import "./sidebar.scss";
import { Link } from "react-router-dom";
const SidebarItem = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [active, setActive] = useState(false);
  const { authUser, setAuthUser } = useContext(MainContext);
  const role = authUser.role;
  const closeSidebar = () => {
    document.querySelector(".main__content").style.transform =
      "scale(1) translateX(0)";
    setTimeout(() => {
      document.body.classList.remove("sidebar-open");
      document.querySelector(".main__content").style = "";
    }, 500);
  };
  // protectis sidebar menu by roles is done here
  let data = [];

  for (let i = 0; i < items.childrens?.length; i++) {
    for (let j = 0; j < items.childrens[i].role?.length; j++) {
      if (items.childrens[i].role[j] == authUser.role) {
        data.push(items.childrens[i]);
      }
    }
  }

  if (items.childrens) {
    return (
      <div className="sidebar__menu__item">
        <div className="sidebar__menu__item__icon">{items.icon}</div>
        <div
          className="sidebar__menu__item__txt"
          onClick={() => setActive(!active)}
        >
          <p>{items.text}</p>
        </div>
        <i
          className="bx bx-chevron-right arrow"
          onClick={() => setActive(!active)}
        ></i>
        <div
          className={
            active
              ? "sidebar__menu__item__childrens active"
              : "sidebar__menu__item__childrens"
          }
        >
          {data?.map((child, index) => (
            <SidebarItem key={index} items={child} />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Link
          to={items.link}
          className="sidebar__menu__item"
          onClick={closeSidebar}
        >
          <div className="sidebar__menu__item__icon">{items.icon}</div>
          <div className="sidebar__menu__item__txt">{items.text}</div>
        </Link>
      </div>
    );
  }
};

export default SidebarItem;
