import React from "react";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
import Button from "../../Button";
const TestimonialTable = ({
  updateTestimony,
  items,
  deleteTestimonial,
  publishTestimonial,
  lastPage,
  nextPage,
  isNextLoading,
  isPrevLoading,
  handleClickOnNext,
  handleClickOnPrev,
}) => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Title</th>
            <th>Testimony</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item) => (
            <tr key={item?.id}>
              <td>
                <img src={item?.photoUrl} alt="profiles" />
              </td>
              <td>{item?.attestantName}</td>
              <td>{item?.attestantPosition}</td>
              <td>{item?.testimony}</td>
              <td>{item?.status}</td>
              <td>
                <CustomDropDown>
                  <button onClick={() => updateTestimony(item)}>Edit</button>
                  <button onClick={() => deleteTestimonial(item.id)}>
                    Delete
                  </button>
                  {item?.status == "unpublished" ? (
                    <button onClick={() => publishTestimonial(item.id)}>
                      Publish
                    </button>
                  ) : (
                    <button onClick={() => publishTestimonial(item.id)}>
                      Unpublish
                    </button>
                  )}
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-btn">
        {(nextPage > 1 || isPrevLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Prev"
            color="#FF9066"
            bcolor="white"
            isLoading={isPrevLoading}
            onClick={handleClickOnPrev}
          />
        )}
        {(nextPage < lastPage || isNextLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Next"
            color="#FF9066"
            bcolor="white"
            isLoading={isNextLoading}
            onClick={handleClickOnNext}
          />
        )}
      </div>
    </div>
  );
};

export default TestimonialTable;
