import axios from "axios";
import { React, useContext, useEffect, useState } from "react";
import config from "../../../configs/config.js";
import { MainContext } from "../../../middleware/Contexts.js";
import Button from "../../Button.js";
import { GrFormClose } from "react-icons/gr";
import "../../form/form.scss";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const OfficeForm = ({
  returnNewAddedOffice,
  isUpdateInProcess,
  officeToUpdate,
  handleView,
}) => {
  useEffect(() => {
    if (isUpdateInProcess) {
      setOfficeName(officeToUpdate.name);
      setOwnerName(officeToUpdate.ownerName);
      setPhone(officeToUpdate.ownerPhone);
      setProvince(officeToUpdate.province);
      setDistrict(officeToUpdate.district);
      setSector(officeToUpdate.sector);
      setPrice(officeToUpdate.price);
      setDescription(officeToUpdate.description);
    }
  }, []);

  const { authUser, setAuthUser } = useContext(MainContext);

  const [name, setOfficeName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerPhone, setPhone] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [sector, setSector] = useState("");

  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");

  const [isAddingNewOffice, setIsAddingNewOffice] = useState(false);

  const clearForm = () => {
    setOfficeName("");
    setOwnerName("");
    setPhone("");
    setProvince("");
    setDistrict("");
    setSector("");
    setPrice("");
    setDescription("");
  };

  const addNewOffice = (e) => {
    e.preventDefault();
    setIsAddingNewOffice(true);
    let data = null;
    if (isUpdateInProcess) {
      data = {
        id: officeToUpdate.id,
        name,
        ownerName,
        ownerPhone,
        province,
        district,
        sector,
        price,
        description,
      };
    } else {
      data = {
        name,
        ownerName,
        ownerPhone,
        province,
        district,
        sector,
        price,
        description,
      };
    }

    const officeUrl = isUpdateInProcess
      ? "/offices/update-office "
      : "/offices/add-new";

    axios
      .post(`${config.apiBaseUrl}${officeUrl}`, data, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        clearForm();
        returnNewAddedOffice(response);
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => setIsAddingNewOffice(false));
  };

  return (
    <>
      <form className="userform" onSubmit={addNewOffice}>
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <h3>{isUpdateInProcess ? 'Edit Office' : 'Add New Office'}</h3>
        <div className="inputform">
          <div className="row">
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">Office Name</label>
              <input
                type="text"
                value={name}
                required
                placeholder="Office name"
                onChange={(event) => setOfficeName(event.target.value)}
              />
              <label for="name">Office owner's Name</label>
              <input
                type="text"
                required
                onChange={(event) => setOwnerName(event.target.value)}
                value={ownerName}
                placeholder="Owner name"
              />

              <label for="name">Office owner's mobile</label>
              <input
                type="text"
                value={ownerPhone}
                required
                placeholder="Phone"
                onChange={(event) => setPhone(event.target.value)}
              />

              <label for="name">Office Province</label>
              <input
                type="text"
                value={province}
                required
                placeholder="Province"
                onChange={(event) => setProvince(event.target.value)}
              />
            </div>
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">Office District</label>
              <input
                type="text"
                value={district}
                required
                placeholder="District"
                onChange={(event) => setDistrict(event.target.value)}
              />
              <label for="name">Office Sector</label>
              <input
                type="text"
                value={sector}
                required
                placeholder="Sector"
                onChange={(event) => setSector(event.target.value)}
              />

              <label for="name">Price of Office</label>
              <input
                type="number"
                value={price}
                required
                placeholder="Price"
                onChange={(event) => setPrice(event.target.value)}
              />

              <label for="name">Additional comment</label>
              <input
                type="text"
                value={description}
                placeholder="Description"
                onChange={(event) => setDescription(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-button row ">
          {isUpdateInProcess ? (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Edit office"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewOffice}
            />
          ) : (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Save & Add"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewOffice}
            />
          )}
        </div>
      </form>
    </>
  );
};
export default OfficeForm;
