import React, { useContext, useState, useEffect } from "react";
import MainLayout from "../../../layout/MainLayout";
import axios from "axios";
import config from "../../../configs/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
import { MainContext } from "../../../middleware/Contexts";
import "../../tables/tables.scss";
import Box from "../../box/Box";
import moment from "moment";
import Loading from "../../Loading";
import Alert from "../../CustomAlert/Alert";
import ViewEmail from "../website/ViewEmail";
import Modal from "../../CustomModal/Modal";
import Button from "../../Button";
const Emails = () => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [contacts, setContacts] = useState([]);
  const [isDeletingEmail, setIsDeletingEmail] = useState(false);
  const [isOnDeletingEmail, setIsOnDeletingEmail] = useState(false);
  const [isOnOpenSingleEmail, setIsOnOpenSingleEmail] = useState(false);
  const [selectedEmailId, setSelectedEmailId] = useState("");
  const [isOnViewSingleEmail, setIsOnViewSingleEmail] = useState(false);
  const [isOnViewEmail, setIsOnViewEmail] = useState([]);

  const [lastPage, setLastPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPrevLoading, setIsPrevLoading] = useState(false);

  const [isLoadingContactUs, setIsLoadingContactUs] = useState(false);

  useEffect(() => {
    getAllEmailsHandler();
  }, []);
  const getAllEmailsHandler = () => {
    setIsLoadingContactUs(true);
    axios
      .get(`${config.apiBaseUrl}/contacts?page=${nextPage}&limit=10`, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setContacts(response.data.data);
        setLastPage(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoading(false);
        setIsNextLoading(false);
        setIsLoadingContactUs(false);
      });
  };

  useEffect(() => {
    if (lastPage) getAllEmailsHandler();
  }, [nextPage]);

  // Getting users on the next page
  const getContactsListOnNextPage = () => {
    setIsNextLoading(true);
    setNextPage(nextPage + 1);
  };
  const getContactsListOnPrevPage = () => {
    setIsPrevLoading(true);
    setNextPage(nextPage - 1);
  };

  // Deleting emails
  const deleteEmailWarning = (id) => {
    setIsOnDeletingEmail(!isOnDeletingEmail);
    setIsOnOpenSingleEmail(!isOnOpenSingleEmail);
    setSelectedEmailId(id);
  };
  const deleteEmails = () => {
    setIsDeletingEmail(true);
    axios
      .delete(`${config.apiBaseUrl}/contacts/${selectedEmailId}`, {
        headers: { authorization: authUser.token },
      })
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setContacts(
          contacts.filter((contact) => contact.id !== selectedEmailId)
        );
        setIsDeletingEmail(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingEmail(false);
      });
  };

  //view single email
  const getSingleEmailHandler = (id) => {
    setIsOnViewSingleEmail(!isOnViewSingleEmail);
    const data = contacts.find((contact) => contact?.id == id);
    setIsOnViewEmail([data]);
  };

  return (
    <MainLayout>
      {isOnDeletingEmail && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this email"
          handleCancel={deleteEmailWarning}
          handleContinue={deleteEmails}
          isLoading={isDeletingEmail}
        />
      )}
      {isOnViewSingleEmail && (
        <Modal handleClicks={getSingleEmailHandler}>
          <ViewEmail
            singleclients={isOnViewEmail}
            title="View client"
            warningMessage="want see this client"
            handleView={getSingleEmailHandler}
            singleemail={isOnViewEmail}
          />
        </Modal>
      )}
      <Box>
        <h3>Emails from clients</h3>
        <div className="tables">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Message</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {contacts.length
                ? contacts.map((contact) => (
                    <tr key={contact.id}>
                      <td>{moment(contact.createdAt).format("yyyy-MM-DD")}</td>
                      <td>{contact.name}</td>

                      <td>{contact.email}</td>
                      <td>{contact.phone}</td>
                      <td>{contact.message}</td>
                      <td>
                        <CustomDropDown>
                          <button
                            onClick={() => getSingleEmailHandler(contact.id)}
                          >
                            View
                          </button>
                          <button
                            onClick={() => deleteEmailWarning(contact.id)}
                          >
                            Delete
                          </button>
                        </CustomDropDown>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
          {/* Loader */}
          {isLoadingContactUs && <Loading />}

          <div className="pagination-btn">
            {(nextPage > 1 || isPrevLoading) && (
              <Button
                width="7rem"
                border="#FF9066 solid 1.5px"
                text="Prev"
                color="#FF9066"
                bcolor="white"
                isLoading={isPrevLoading}
                onClick={getContactsListOnPrevPage}
              />
            )}
            {(nextPage < lastPage || isNextLoading) && (
              <Button
                width="7rem"
                border="#FF9066 solid 1.5px"
                text="Next"
                color="#FF9066"
                bcolor="white"
                isLoading={isNextLoading}
                onClick={getContactsListOnNextPage}
              />
            )}
          </div>
        </div>
      </Box>
    </MainLayout>
  );
};

export default Emails;
