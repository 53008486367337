import React from "react";
import { Bar } from "react-chartjs-2";
import Box from "../components/box/Box";
import DashboardWrapper, {
  DashboardWrapperMain,
  DashboardWrapperRight,
} from "../components/dashboard-wrapper/DashboardWrapper";
import SummaryBox, {
  SummaryBoxSpecial,
} from "../components/summary-box/SummaryBox";
import { colors, data } from "../constants";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import OverallList from "../components/overall-list/OverallList";
import RevenueList from "../components/revenue-list/RevenueList";
import MainLayout from "../layout/MainLayout";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  return (
    <MainLayout>
      <DashboardWrapper>
        <DashboardWrapperMain>
          <div className="row">
            <div className="col-12 col-md-12">
              <SummaryBox />
            </div>
            {/* <div className="col-4 hide-md">
              <Box>
                <div className="mb">
                  <RevenueList />
                </div>
              </Box>
            </div> */}
          </div>
        </DashboardWrapperMain>
        <DashboardWrapperRight>
          <div className="title mb">Overall</div>
          <div className="mb">
            <OverallList />
          </div>
        </DashboardWrapperRight>
      </DashboardWrapper>
    </MainLayout>
  );
};

export default Dashboard;
