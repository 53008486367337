import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blank from "./pages/Blank";
import Users from "./pages/Users";
import Dashboard from "./pages/Dashboard";
import Login from "./components/login/Login";
import ForgetPassword from "./components/forgetpassword/ForgetPassword";
import ResetPassword from "./components/forgetpassword/ResetPassword";
import Website from "./pages/Website";
import Setting from "./pages/Setting";
import WebProperty from "./pages/WebProperty";
import Apartment from "./pages/Apartment";
import Finance from "./pages/Finance";
import FinanceApartment from "./pages/FinanceApartment";
import House from "./pages/House";
import Emails from "./components/dashboard/website/Emails";
import Offices from "./pages/Offices";
import Announcement from "./pages/Announcement";
import Testimonials from "./pages/Testimonials";
import Profile from "./pages/Profile";
import Service from "./pages/Service";
import Booked from "./pages/Booked";
import Parteners from "./pages/Parteners";
import FinanceHouse from "./pages/FinanceHouse";
import FinanceOffice from "./pages/FinanceOffice";
import { MainContext } from "./middleware/Contexts";
import { useEffect, useState } from "react";
import "./assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "./scss/App.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import ReactPdf
function App() {
  const [authUser, setAuthUser] = useState({
    email: "",
    fullName: "",
    id: "",
    role: "",
    token: "",
  });

  useEffect(() => {
    setAuthUser(JSON.parse(localStorage.getItem("authUser")));
  }, []);

  return (
    <MainContext.Provider value={{ authUser, setAuthUser }}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          {authUser?.token ? (
            <>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="apartment/apartment" element={<Apartment />} />
              <Route path="apartment/booked" element={<Booked />} />
              <Route path="house" element={<House />} />
              <Route path="offices" element={<Offices />} />
              <Route path="website/website" element={<Website />} />
              <Route path="website/emails" element={<Emails />} />
              <Route path="website/services" element={<Service />} />
              <Route path="website/property" element={<WebProperty />} />
              <Route path="website/announcement" element={<Announcement />} />
              <Route path="website/testimonial" element={<Testimonials />} />
              <Route path="website/partener" element={<Parteners />} />
              <Route path="finances/home" element={<Finance />} />
              <Route path="finances/office" element={<FinanceOffice />} />
              <Route path="finances/apartment" element={<FinanceApartment />} />
              <Route path="finances/house" element={<FinanceHouse />} />
              <Route path="users" element={<Users />} />
              <Route path="settings" element={<Profile />} />
              <Route path="profile" element={<Profile />} />
            </>
          ) : (
            <Route path="/" element={<Login />} />
          )}
          <Route path="resetPwd" element={<ResetPassword />} />
          <Route path="forgetpassword" element={<ForgetPassword />} />
        </Routes>
      </BrowserRouter>
    </MainContext.Provider>
  );
}

export default App;
