import React, { useContext, useEffect, useState } from "react";
import "./summary-box.scss";
import Box from "../box/Box";
import { MainContext } from "../../middleware/Contexts";
import axios from "axios";
import config from "../../configs/config";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { colors } from "../../constants";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SummaryBox = ({ item }) => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [property, setProperty] = useState("");
  const [booking, setBooking] = useState("");
  const [emails, setEmails] = useState("");
  const [clients, setClients] = useState("");
  const [revenue, setRevenue] = useState("");
  const [revenueWeek, setRevenueWeek] = useState("");
  const [isLoadingProperty, setIsLoadingProperty] = useState(false);
  const [housePaid, setHousePaid] = useState("");
  const [officePaid, setOfficePaid] = useState("");
  const [apatsPaid, setApatsPaid] = useState("");
  // Get total nbr of Propertys
  const getTotalProperty = () => {
    setIsLoadingProperty(true);
    axios
      .get(`${config.apiBaseUrl}/dashboard/count-properties `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setProperty(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingProperty(false);
      });
  };

  // Get total nbr of total-bookings
  const getTotalBookings = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/total-bookings `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setBooking(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  // Get total nbr of total-setEmails
  const getTotalEmails = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/total-contact-us `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setEmails(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const getTotalRevenue = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/count-revenue `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setRevenue(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const getTotalClients = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/count-participants `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setClients(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  const getTotalApatsPaid = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/count-aprts-paid-report `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setApatsPaid(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  const getTotalHousePaid = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/count-houses-paid-report `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setHousePaid(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  const getTotalOfficePaid = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/count-offices-paid-report `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setOfficePaid(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  const getTotalRevenueWeek = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/count-revenue-week `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setRevenueWeek(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  useEffect(() => {
    getTotalOfficePaid();
    getTotalHousePaid();
    getTotalApatsPaid();
    getTotalRevenue();
    getTotalClients();
    getTotalEmails();
    getTotalRevenueWeek();
    getTotalBookings();
    getTotalProperty();
  }, []);
  const formatter = new Intl.NumberFormat("en-rw", {
    style: "currency",
    currency: "rwf",
  });

  let client =
    clients.totalClientsOfApartments +
    clients.totalClientsOfHouses +
    clients.totalClientsOfOffices;
  let allRevenue =
    revenue.totalApartmentsPayments +
    revenue.totalHousesPayments +
    revenue.totalOfficesPayments;
  let allRevenueWeek =
    revenueWeek?.totalApartmentsPayments +
    revenueWeek?.totalHousesPayments +
    revenueWeek?.totalOfficesPayments;

  let avApts = ((apatsPaid / property.totalHousesOfApartment) * 100).toFixed();
  let avHouse = ((housePaid / property.totalHouses) * 100).toFixed();
  let avOffices = (
    (officePaid / property.totalHousesOfApartment) *
    100
  ).toFixed();

  return (
    <div className="row">
      <div className="col-4 col-md-6 col-sm-12 mb">
        <Box>
          <div className="summary-box">
            <div className="summary-box__info">
              <div className="summary-box__info__title">
                <div>Bookings</div>
                <span>Total bookings</span>
              </div>
              <div className="summary-box__info__value">{20}</div>
            </div>
            <div className="summary-box__chart">
              <CircularProgressbarWithChildren
                value={70}
                strokeWidth={10}
                styles={buildStyles({
                  pathColor: 70 < 50 ? colors.red : colors.purple,
                  trailColor: "transparent",
                  strokeLinecap: "round",
                })}
              >
                <div className="summary-box__chart__value">{70}%</div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </Box>
      </div>
      <div className="col-4 col-md-6 col-sm-12 mb">
        <Box>
          <div className="summary-box">
            <div className="summary-box__info">
              <div className="summary-box__info__title">
                <div>Clients</div>
                <span>Total clients</span>
              </div>
              <div className="summary-box__info__value">{client}</div>
            </div>
            <div className="summary-box__chart">
              <CircularProgressbarWithChildren
                value={70}
                strokeWidth={10}
                styles={buildStyles({
                  pathColor: 70 < 50 ? colors.red : colors.purple,
                  trailColor: "transparent",
                  strokeLinecap: "round",
                })}
              >
                <div className="summary-box__chart__value">{70}%</div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </Box>
      </div>
      <div className="col-4 col-md-6 col-sm-12 mb">
        <Box>
          <div className="summary-box">
            <div className="summary-box__info">
              <div className="summary-box__info__title">
                <div>Emails</div>
                <span>Total emails </span>
              </div>
              <div className="summary-box__info__value">{emails}</div>
            </div>
            <div className="summary-box__chart">
              <CircularProgressbarWithChildren
                value={70}
                strokeWidth={10}
                styles={buildStyles({
                  pathColor: 70 < 50 ? colors.red : colors.purple,
                  trailColor: "transparent",
                  strokeLinecap: "round",
                })}
              >
                <div className="summary-box__chart__value">{70}%</div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </Box>
      </div>
      <div className="col-4 col-md-6 col-sm-12 mb">
        <Box>
          <div className="summary-box">
            <div className="summary-box__info">
              <div className="summary-box__info__title">
                <div>Apartment</div>
                <span>Total apartments </span>
              </div>
              <div className="summary-box__info__value">
                {property.totalApartment}
              </div>
            </div>
            <div className="summary-box__chart">
              <CircularProgressbarWithChildren
                value={avApts}
                strokeWidth={10}
                styles={buildStyles({
                  pathColor: avApts < 50 ? colors.red : colors.purple,
                  trailColor: "transparent",
                  strokeLinecap: "round",
                })}
              >
                <div className="summary-box__chart__value">{avApts}%</div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </Box>
      </div>
      <div className="col-4 col-md-6 col-sm-12 mb">
        <Box>
          <div className="summary-box">
            <div className="summary-box__info">
              <div className="summary-box__info__title">
                <div>Houses</div>
                <span>Total Houses </span>
              </div>
              <div className="summary-box__info__value">
                {property.totalHouses}
              </div>
            </div>
            <div className="summary-box__chart">
              <CircularProgressbarWithChildren
                value={avHouse}
                strokeWidth={10}
                styles={buildStyles({
                  pathColor: avHouse < 50 ? colors.red : colors.purple,
                  trailColor: "transparent",
                  strokeLinecap: "round",
                })}
              >
                <div className="summary-box__chart__value">{avHouse}%</div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </Box>
      </div>
      <div className="col-4 col-md-6 col-sm-12 mb">
        <Box>
          <div className="summary-box">
            <div className="summary-box__info">
              <div className="summary-box__info__title">
                <div>Offices</div>
                <span>Total Offices </span>
              </div>
              <div className="summary-box__info__value">
                {property.totalOffices}
              </div>
            </div>
            <div className="summary-box__chart">
              <CircularProgressbarWithChildren
                value={avOffices}
                strokeWidth={10}
                styles={buildStyles({
                  pathColor: avOffices < 50 ? colors.red : colors.purple,
                  trailColor: "transparent",
                  strokeLinecap: "round",
                })}
              >
                <div className="summary-box__chart__value">{avOffices}%</div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </Box>
      </div>
      <div className="col-4 col-md-6 col-sm-12 mb">
        <Box>
          <div className="summary-box">
            <div className="summary-box__info">
              <div className="summary-box__info__title">
                <div>Revenue</div>
                <span>Total Revenue a week </span>
              </div>
              <div className="summary-box__info__value">
                {formatter.format(allRevenueWeek)}
              </div>
            </div>
          </div>
        </Box>
      </div>
      <div className="col-4 col-md-6 col-sm-12 mb">
        <Box>
          <div className="summary-box">
            <div className="summary-box__info">
              <div className="summary-box__info__title">
                <div>Revenue</div>
                <span>Revenue since the beginning </span>
              </div>
              <div className="summary-box__info__value">
                {formatter.format(allRevenue)}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default SummaryBox;
