import React from "react";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
const PartenerTable = ({
  items,
  updatePartener,
  deletePartener,
  publishPartener,
}) => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Image</th>

            <th>partener Link</th>
            <th>status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item) => (
            <tr key={item?.id}>
              <td>
                <img src={item?.photoUrl} alt="Partener" />
              </td>
              <td>{item?.title}</td>

              <td>{item?.status}</td>
              <td>
                <CustomDropDown>
                  <button onClick={() => updatePartener(item)}>Edit</button>
                  <button onClick={() => deletePartener(item.id)}>
                    Delete
                  </button>
                  {item?.status == "unpublished" ? (
                    <button onClick={() => publishPartener(item.id)}>
                      Publish
                    </button>
                  ) : (
                    <button onClick={() => publishPartener(item.id)}>
                      Unpublish
                    </button>
                  )}
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartenerTable;
