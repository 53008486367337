import axios from "axios";
import React, { useContext } from "react";
import config from "../../../configs/config";
import { MainContext } from "../../../middleware/Contexts";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../Button";

const ApartmentTable = ({
  apartments,
  getSingleApartmentHandler,
  updateApartment,
  deleteApartment,
  addHouseToAppartment,
  getAllApartmentsHandler,
  lastPage,
  nextPage,
  isNextLoading,
  isPrevLoading,
  handleClickOnNext,
  handleClickOnPrev,
}) => {
  const { authUser, setAuthUser } = useContext(MainContext);

  const activateApartmentHandler = (id) => {
    axios
      .post(
        `${config.apiBaseUrl}/apartments/update-apartment`,
        { id, isActive: true },
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        getAllApartmentsHandler();
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => { });
  };
  const deactivateApartmentHandler = (id) => {
    axios
      .post(
        `${config.apiBaseUrl}/apartments/update-apartment`,
        { id, isActive: false },
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        getAllApartmentsHandler();
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => { });
  };
  return (
    <div>
      {" "}
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Apartment Name</th>
            <th>Owner Name</th>
            <th>Phone</th>
            <th>Province</th>
            <th>District</th>
            <th>Sector</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {apartments.map((apartment) => (
            <tr key={apartment.id}>
              <td>{apartment.name}</td>
              <td>{apartment.ownerName}</td>
              <td>{apartment.ownerPhone}</td>
              <td>{apartment.province}</td>
              <td>{apartment.district}</td>
              <td>{apartment.sector}</td>

              <td>
                <CustomDropDown>
                  <button
                    onClick={() => getSingleApartmentHandler(apartment.id)}
                  >
                    View
                  </button>
                  <button onClick={() => updateApartment(apartment)}>
                    Edit
                  </button>
                  <button onClick={() => deleteApartment(apartment.id)}>
                    Delete
                  </button>
                  <button onClick={() => addHouseToAppartment(apartment.id)}>
                    Add house
                  </button>
                  {apartment.isActive ? (
                    <button
                      onClick={() => deactivateApartmentHandler(apartment.id)}
                    >
                      Deactivate
                    </button>
                  ) : (
                    <button
                      onClick={() => activateApartmentHandler(apartment.id)}
                    >
                      Activate
                    </button>
                  )}
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination-btn">
        {(nextPage > 1 || isPrevLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Prev"
            color="#FF9066"
            bcolor="white"
            isLoading={isPrevLoading}
            onClick={handleClickOnPrev}
          />
        )}
        {(nextPage < lastPage || isNextLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Next"
            color="#FF9066"
            bcolor="white"
            isLoading={isNextLoading}
            onClick={handleClickOnNext}
          />
        )}
      </div>
    </div>
  );
};

export default ApartmentTable;
