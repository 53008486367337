import React, { useState, useEffect, useContext } from "react";
import { Upload, message } from "antd";
import axios from "axios";
import { CloudinaryContext, Transformation, Image } from "cloudinary-react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import config from "../../../configs/config";
import { ToastContainer, toast } from "react-toastify";
import { GrFormClose } from "react-icons/gr";
import Button from "../../Button";
import { MainContext } from "../../../middleware/Contexts";
const TestimonialForm = ({
  handleView,
  testimonyToUpdate,
  returnNewAddedTestimony,
  isUpdateInProcess,
}) => {
  const [photoUrl, setPhotoUrl] = useState("");
  const [attestantName, setAttestantName] = useState("");
  const [attestantPosition, setAttestantPosition] = useState("");
  const [testimony, setTestimony] = useState("");
  const [loading, setLoading] = useState(false);
  const { authUser, setAuthUser } = useContext(MainContext);
  const [isAddingNewTestimony, setIsAddingNewTestimony] = useState(false);

  useEffect(() => {
    if (testimonyToUpdate) {
      setPhotoUrl(testimonyToUpdate.photoUrl);
      setAttestantName(testimonyToUpdate.attestantName);
      setAttestantPosition(testimonyToUpdate.attestantPosition);
      setTestimony(testimonyToUpdate.testimony);
    }
  }, []);

  const clearForm = () => {
    setPhotoUrl("");
    setAttestantName("");
    setAttestantPosition("");
    setTestimony("");
  };
  const addNewTestimony = (e) => {
    e.preventDefault();
    setIsAddingNewTestimony(true);
    let data = null;
    if (isUpdateInProcess) {
      data = {
        id: testimonyToUpdate.id,
        photoUrl,
        attestantName,
        attestantPosition,
        testimony,
      };
    } else {
      data = {
        photoUrl,
        attestantName,
        attestantPosition,
        testimony,
      };
    }

    const userUrl = isUpdateInProcess
      ? "/testimonials/update"
      : "/testimonials/create";

    axios
      .post(`${config.apiBaseUrl}${userUrl}`, data, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        clearForm();
        returnNewAddedTestimony(response);
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => setIsAddingNewTestimony(false));
  };

  const handleUpload = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setPhotoUrl(info.file.response.secure_url);
      setLoading(false);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      setLoading(false);
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  const customRequest = async ({ file, onSuccess, onError }) => {
    setPhotoUrl("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "tia1ip8h");
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dvejxosqk/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      onSuccess(data);
    } catch (err) {
      onError(err);
    }
  };
  return (
    <div>
      <form className="userform" onSubmit={addNewTestimony}>
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <h3>{isUpdateInProcess ? "Edit Testimony" : "Add new Testimony"}</h3>
        <div className="inputform">
          <div className="row">
            <div className="col-12 col-md-12 col-sm-12 mb">
              <label for="name">Select image</label>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={customRequest}
                onChange={handleUpload}
              >
                {photoUrl ? (
                  <CloudinaryContext cloudName="dvejxosqk">
                    <Image publicId={photoUrl}>
                      <Transformation width="150" height="150" crop="thumb" />
                    </Image>
                  </CloudinaryContext>
                ) : (
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
              <label for="name">Name</label>
              <input
                type="text"
                required
                onChange={(event) => setAttestantName(event.target.value)}
                value={attestantName}
                placeholder="owner name"
              />

              <label for="name">Title</label>
              <input
                type="text"
                value={attestantPosition}
                required
                placeholder="Title"
                onChange={(event) => setAttestantPosition(event.target.value)}
              />

              <label for="name">Testimonial</label>
              <textarea
                type="text"
                value={testimony}
                required
                placeholder="testimony"
                onChange={(event) => setTestimony(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-button row ">
          {isUpdateInProcess ? (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Edit testimony"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewTestimony}
            />
          ) : (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Save & Add"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewTestimony}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default TestimonialForm;
