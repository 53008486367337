import React from "react";
import Button from "../../Button";
import "../../tables/tables.scss";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
const Tables = ({
  users,
  handleClickOnNext,
  handleClickOnPrev,
  lastPage,
  nextPage,
  isNextLoading,
  isPrevLoading,
  getSingleUserHandler,
  deleteUser,
  updateUser,
}) => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Gender</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Role</th>

            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.fullName}</td>
              <td>{user.gender}</td>
              <td>{user.email}</td>
              <td>{user.phone}</td>
              <td>{user.role}</td>

              <td>
                <CustomDropDown>
                  <button onClick={() => getSingleUserHandler(user.id)}>
                    View
                  </button>
                  <button onClick={() => updateUser(user)}>Edit</button>
                  <button onClick={() => deleteUser(user.id)}>Delete</button>
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-btn">
        {(nextPage > 1 || isPrevLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Prev"
            color="#FF9066"
            bcolor="white"
            isLoading={isPrevLoading}
            onClick={handleClickOnPrev}
          />
        )}
        {(nextPage < lastPage || isNextLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Next"
            color="#FF9066"
            bcolor="white"
            isLoading={isNextLoading}
            onClick={handleClickOnNext}
          />
        )}
      </div>
    </div>
  );
};

export default Tables;
