import React, { useRef } from "react";
import Box from "../components/box/Box";
import WebsiteBox from "../components/dashboard/website/websitebox";
import MainLayout from "../layout/MainLayout";
import { Editor } from "@tinymce/tinymce-react";

const Website = () => {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <MainLayout>
      <div>
        <WebsiteBox />
      </div>

      <Box>
        <Editor
          tinymceScriptSrc={
            "pzujokc07jcky4tcqpkli7nsatb4g0446k7qi57pl2b9u4u2" +
            "/tinymce/tinymce.min.js"
          }
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue="<p>This is the initial content of the editor.</p>"
          init={{
            height: 300,
            //menubar: false,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "preview",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </Box>
    </MainLayout>
  );
};

export default Website;
