import React, { useState, useEffect, useContext } from "react";
import MainLayout from "../layout/MainLayout";
import Button from "../components/Button";
import Box from "../components/box/Box";
import config from "../configs/config";
import TestimonialForm from "../components/dashboard/website/TestimonialForm";
import axios from "axios";
import Alert from "../components/CustomAlert/Alert";
import PublishAlert from "../components/CustomAlert/PublishAlert";
import { ToastContainer, toast } from "react-toastify";
import { MainContext } from "../middleware/Contexts";
import Modal from "../components/CustomModal/Modal";
import TestimonialTable from "../components/dashboard/website/TestimonialTable";

const Testimonials = () => {
  const [allTestimonials, setAllTestimonials] = useState([]);
  const [isAddNewTestimony, setIsAddNewTestimony] = useState(false);
  const [isFetchingTestimonials, setIsFetchingTestimonials] = useState(false);
  const [isOnUpdateProcess, setIsOnUpdateProcess] = useState(false);
  const [testimonyToUpdate, setTestimonyToUpdate] = useState(null);
  const { authUser, setAuthUser } = useContext(MainContext);
  const [isOnDeletingTestimony, setIsOnDeletingTestimony] = useState(false);
  const [isOnViewSingleTestimony, setIsOnViewSingleTestimony] = useState(false);
  const [isDeletingTestimony, setIsDeletingTestimony] = useState(false);
  const [selectedTestimonyId, setSelectedTestimonyId] = useState(null);
  const [isOnPublishTestimony, setIsOnPublishTestimony] = useState(false);
  const [publishTestimonyId, setPublishTestimonyId] = useState(null);
  const [isPublishTestimony, setIsPublishTestimony] = useState(false);

  const [lastPage, setLastPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPrevLoading, setIsPrevLoading] = useState(false);

  // get all Testimonials
  const getAllTestimonials = () => {
    setIsFetchingTestimonials(true);
    axios
      .get(
        `${config.apiBaseUrl}/testimonials/admin?page=${nextPage}&limit=10`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        setAllTestimonials(response.data.data);
        setLastPage(response.data.lastPage);
      })
      .catch((error) => {})
      .finally(() => {
        setIsPrevLoading(false);
        setIsNextLoading(false);
        setIsFetchingTestimonials(false);
      });
  };
  useEffect(() => {
    getAllTestimonials();
  }, []);

  useEffect(() => {
    if (lastPage) getAllTestimonials();
  }, [nextPage]);

  // Getting users on the next page
  const getTestimonialsListOnNextPage = () => {
    setIsNextLoading(true);
    setNextPage(nextPage + 1);
  };
  const getTestimonialsListOnPrevPage = () => {
    setIsPrevLoading(true);
    setNextPage(nextPage - 1);
  };

  // Open and Close modal for adding new client
  const onAddNewTestimonyHandler = () => {
    setIsAddNewTestimony(!isAddNewTestimony);
    setIsOnUpdateProcess(false);
  };

  // Adding added client to the list of clients
  const returnNewAddedTestimony = (data) => {
    if (isOnUpdateProcess) {
      allTestimonials.map((testimony) => {
        if (testimony.id === data.data.data.id) {
          testimony.photoUrl = data.data.data.photoUrl;
          testimony.attestantName = data.data.data.attestantName;
          testimony.attestantPosition = data.data.data.attestantPosition;
          testimony.testimony = data.data.data.testimony;
        }
      });
    } else {
      setAllTestimonials((current) => [data.data.data, ...current]);
    }
    setTimeout(() => {
      onAddNewTestimonyHandler();
    }, 800);
  };

  //update testimony
  const updateTestimony = (item) => {
    setTestimonyToUpdate(item);
    setIsOnUpdateProcess(true);
    setIsAddNewTestimony(!isAddNewTestimony);
  };

  // Warning for publish testimony
  const publishTestimonyWarning = (id) => {
    setPublishTestimonyId(id);
    setIsOnPublishTestimony(!isOnPublishTestimony);
  };

  // publish testimony
  let data = null;
  const publishTestimony = () => {
    setIsPublishTestimony(true);
    axios
      .post(
        `${config.apiBaseUrl}/testimonials/publish-unpublish/${publishTestimonyId}`,
        data,

        {
          headers: { authorization: authUser.token },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });

        setAllTestimonials(
          allTestimonials.map((testimony) => {
            if (testimony.id === publishTestimonyId) {
              return { ...testimony, status: res.data.data.status };
            }
            return testimony;
          })
        );

        setIsOnPublishTestimony(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsPublishTestimony(false);
      });
  };

  // Deleting testimony
  const deleteTestimonyWarning = (id) => {
    setIsOnDeletingTestimony(!isOnDeletingTestimony);
    setIsOnViewSingleTestimony(isOnViewSingleTestimony);
    setSelectedTestimonyId(id);
  };
  const deleteTestimony = () => {
    setIsDeletingTestimony(true);
    axios
      .delete(`${config.apiBaseUrl}/testimonials/${selectedTestimonyId}`, {
        headers: { authorization: authUser.token },
      })
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setAllTestimonials(
          allTestimonials.filter(
            (testimony) => testimony.id !== selectedTestimonyId
          )
        );
        setIsOnDeletingTestimony(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingTestimony(false);
      });
  };
  return (
    <MainLayout>
      {/* Pop up warning while deleting */}
      {isOnDeletingTestimony && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this testimony"
          handleCancel={deleteTestimonyWarning}
          handleContinue={deleteTestimony}
          isLoading={isDeletingTestimony}
        />
      )}
      {/* Publish Testimony */}
      {isOnPublishTestimony && (
        <PublishAlert
          title="Publish"
          warningMessage="Are you sure you want to change the status of this testimony"
          handleCancel={publishTestimonyWarning}
          handleContinue={publishTestimony}
          isLoading={isPublishTestimony}
        />
      )}
      {/* testimonial form modal */}
      {isAddNewTestimony && (
        <Modal handleClicks={onAddNewTestimonyHandler}>
          <TestimonialForm
            returnNewAddedTestimony={returnNewAddedTestimony}
            isUpdateInProcess={isOnUpdateProcess}
            testimonyToUpdate={testimonyToUpdate}
            handleView={onAddNewTestimonyHandler}
          />
        </Modal>
      )}
      <div className="row">
        <div className="col-12 col-md-12 col-sm-12 mb">
          <Button
            width="8rem"
            border="#FF9066 solid 1.5px"
            text="Add Testimony"
            color="#FF9066"
            bcolor="white"
            onClick={onAddNewTestimonyHandler}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-12 col-sm-12 mb">
          <Box>
            <h3>Testmonials list</h3>
            <TestimonialTable
              items={allTestimonials}
              isAddNewTestimony={onAddNewTestimonyHandler}
              updateTestimony={updateTestimony}
              publishTestimonial={publishTestimonyWarning}
              deleteTestimonial={deleteTestimonyWarning}
              lastPage={lastPage}
              nextPage={nextPage}
              isPrevLoading={isPrevLoading}
              isNextLoading={isNextLoading}
              handleClickOnNext={getTestimonialsListOnNextPage}
              handleClickOnPrev={getTestimonialsListOnPrevPage}
            />
          </Box>
        </div>
      </div>
    </MainLayout>
  );
};

export default Testimonials;
