import React from "react";
import Button from "../../Button";
import { CiEdit } from "react-icons/ci";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
import "../../tables/tables.scss";
const BookingTable = ({ bookings, getSingleBookingHandler, deleteBooking }) => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Gender</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Check-in</th>

            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {bookings?.map((booking) => (
            <tr key={booking.id}>
              <td>{booking.fullName}</td>
              <td>{booking.gender}</td>
              <td>{booking.email}</td>
              <td>{booking.phone}</td>
              <td>{booking.arrivalDate.slice(0, 10)}</td>

              <td>
                <CustomDropDown>
                  <button onClick={() => getSingleBookingHandler(booking.id)}>
                    View
                  </button>

                  <button onClick={() => deleteBooking(booking.id)}>
                    Delete
                  </button>
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <Button
          width="7rem"
          border="#FF9066 solid 1.5px"
          text="Prev"
          color="#FF9066"
          bcolor="white"
          //   isLoading={isPrevLoading}
          //   onClick={handleClickOnPrev}
        />
        <Button
          width="7rem"
          border="#FF9066 solid 1.5px"
          text="Next"
          color="#FF9066"
          bcolor="white"
          //   isLoading={isPrevLoading}
          //   onClick={handleClickOnPrev}
        />
      </div>
    </div>
  );
};

export default BookingTable;
