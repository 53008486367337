import { Title } from "chart.js";
import React, { useRef, forwardRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
import { AiFillPrinter } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import "../../CustomModal/singleview.scss";
import moment from "moment";
import { moneyFormatter } from "../../../utils/MoneyFormatter";

// import { useRef } from "react";
const Input = forwardRef((props, ref) => {
  return <input ref={ref} {...props} />;
});
const Alert = ({
  isLoading,
  handleView,
  handleContinue,
  title,
  singleApartment,
  updateApatsHouse,
  deleteApatsHouse,
}) => {
  const printingRef = useRef();
  const hundlePrint = useReactToPrint({
    content: () => printingRef.current,
    documentTitle: "Sampro print",
    onafterprint: () => alert("print is ok"),
  });

  return (
    <div ref={printingRef} className="print">
      <div className="singleview-shadow">
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <div className="singleview-center">
          <div className="singleview-header">
            <h3>{title}</h3>
          </div>
          <div className="singleview-content">
            {singleApartment.map((apartment) => (
              <div key={apartment.id}>
                <div className="row">
                  <div className="col-6 col-md-6 col-sm-12 mb">
                    <p>
                      Apartment name: <span>{apartment?.name}</span>
                    </p>
                    <p>
                      Apartment owner: <span>{apartment?.ownerName}</span>
                    </p>
                    <p>
                      Telephone: <span>{apartment?.ownerPhone}</span>
                    </p>
                    <p>
                      Active:{" "}
                      <span
                        className={
                          apartment.isActive ? "av-gr-status" : "un-rd-status"
                        }
                      >
                        {apartment?.isActive ? "Yes" : "No"}
                      </span>
                    </p>
                  </div>
                  <div className="col-6 col-md-6 col-sm-12 mb">
                    <p>
                      Province: <span>{apartment?.province}</span>
                    </p>
                    <p>
                      District: <span> {apartment?.district}</span>
                    </p>
                    <p>
                      Sector: <span>{apartment?.sector}</span>
                    </p>
                  </div>
                </div>

                <div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>House Name</th>
                        <th>Price</th>
                        <th>Living rooms</th>
                        <th>Rooms / Baths</th>
                        <th>Available</th>
                        <th>Description</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {apartment.ApartmentsHouses.map((house) => (
                        <tr key={house.id}>
                          <td>{house.name}</td>
                          <td>{moneyFormatter(house.price)}</td>
                          <td>{house.LivingRooms}</td>
                          <td>
                            {house.bedRooms} room(s) / {house.bathRooms} bath(s){" "}
                            {house.hasKitchen ? "+ kitchen" : ""}
                          </td>
                          <td
                            className={
                              house.isAvailable
                                ? "av-gr-status"
                                : "un-rd-status"
                            }
                          >
                            {house.isAvailable ? "Yes" : "No"}
                          </td>
                          <td>{house.description}</td>

                          <td>
                            <CustomDropDown>
                              <button onClick={() => updateApatsHouse(house)}>
                                Edit
                              </button>
                              <button
                                onClick={() => deleteApatsHouse(house.id)}
                              >
                                Delete
                              </button>
                            </CustomDropDown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="icons">
        <AiFillPrinter onClick={() => hundlePrint()} className="myprint" />
      </div>
    </div>
  );
};

export default Alert;
