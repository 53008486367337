import { Title } from "chart.js";
import React, { useRef, forwardRef } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import { useReactToPrint } from "react-to-print";
import "../../../CustomModal/singleview.scss";
import moment from "moment";
// import { useRef } from "react";
const Input = forwardRef((props, ref) => {
  return <input ref={ref} {...props} />;
});
const Alert = ({ handleView, title, singleClient }) => {
  const printingRef = useRef();
  const hundlePrint = useReactToPrint({
    content: () => printingRef.current,
    documentTitle: "Sampro print",
    onafterprint: () => alert("print is ok"),
  });
  return (
    <div ref={printingRef} className="print">
      <div className="singleview-shadow">
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <div className="singleview-center">
          <div className="singleview-header">
            <h3>{title}</h3>
          </div>
          <div className="singleview-content">
            <div>
              <div className="row">
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <p>
                    Full name: <span>{singleClient.fullName}</span>
                  </p>
                  <p>
                    Email: <span>{singleClient.email}</span>
                  </p>
                  <p>
                    National ID: <span>{singleClient.nId}</span>
                  </p>
                  <p>
                    Gender: <span>{singleClient.gender}</span>
                  </p>
                  <p>
                    Birth date:{" "}
                    <span>
                      {moment(singleClient.birthDate).format("yyyy-MM-DD")}
                    </span>
                  </p>
                  {singleClient?.Houses_Payment && (
                    <p>
                      Last payment:{" "}
                      <span>{singleClient?.Houses_Payment?.amount}</span>
                    </p>
                  )}
                </div>
                <div className="col-6 col-md-6 col-sm-12 mb">
                  <p>
                    Phone: <span>{singleClient.phone}</span>
                  </p>
                  <p>
                    Country: <span>{singleClient.country}</span>
                  </p>

                  <p>
                    Province: <span>{singleClient.province}</span>
                  </p>
                  <p>
                    District: <span> {singleClient.district}</span>
                  </p>
                  <p>
                    Sector: <span>{singleClient.sector}</span>
                  </p>
                  <p>
                    Checkin date:{" "}
                    <span>
                      {moment(singleClient.inDate).format("yyyy-MM-DD")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {singleClient?.Houses_LastPayments.length ? (
              <div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>lastPaidDate</th>
                      <th>nextPayDate</th>
                      <th>amount</th>
                      <th>Payment method</th>
                    </tr>
                  </thead>
                  <tbody>
                    {singleClient?.Houses_LastPayments?.map((payment) => (
                      <tr key={payment.id}>
                        <td>
                          {moment(payment.lastPaidDate).format("yyyy-MM-DD")}
                        </td>
                        <td>
                          {moment(payment.nextPayDate).format("yyyy-MM-DD")}
                        </td>
                        <td>{payment.amount}</td>
                        <td>{payment.paymentMethods}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="icons">
        <AiFillPrinter onClick={() => hundlePrint()} className="myprint" />
      </div>
    </div>
  );
};

export default Alert;
