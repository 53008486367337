import React from "react";
import { GrAddCircle } from "react-icons/gr";
import { BiUserPlus } from "react-icons/bi";
import { MdEventAvailable } from "react-icons/md";
import CustomDropDown from "../../../CustomDropDown/CustomDropDown";
import Button from "../../../Button";
import { moneyFormatter } from "../../../../utils/MoneyFormatter";

const HouseTable = ({
  houses,
  addClientToHouse,
  availableHouse,
  lastPage,
  nextPage,
  isNextLoading,
  isPrevLoading,
  handleClickOnNext,
  handleClickOnPrev,
}) => {
  return (
    <div>
      <h3>Houses list</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>House name</th>
            <th>Living rooms</th>
            <th>Rooms / Baths</th>
            <th>With kitchen</th>
            <th>Description</th>
            <th>Price</th>
            <th>Available</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {houses?.map((house) => (
            <tr key={house?.id}>
              <td>{house?.name}</td>
              <td>{house?.LivingRooms}</td>
              <td>
                {house?.bedRooms} room(s)/ {house?.bathRooms} bath(s)
              </td>
              <td>{house?.hasKitchen ? "Yes" : "No"}</td>
              <td>{house?.description}</td>
              <td>{moneyFormatter(house?.price)}</td>
              <td>{house?.isAvailable ? "Yes" : "No"}</td>

              <td>
                <CustomDropDown>
                  <button onClick={() => addClientToHouse(house?.id)}>
                    Add client
                  </button>
                  <button onClick={() => availableHouse(house.id)}>
                    Make available
                  </button>
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-btn">
        {(nextPage > 1 || isPrevLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Prev"
            color="#FF9066"
            bcolor="white"
            isLoading={isPrevLoading}
            onClick={handleClickOnPrev}
          />
        )}
        {(nextPage < lastPage || isNextLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Next"
            color="#FF9066"
            bcolor="white"
            isLoading={isNextLoading}
            onClick={handleClickOnNext}
          />
        )}
      </div>
    </div>
  );
};

export default HouseTable;
