import React, { useState, useEffect, useContext } from "react";
import Box from "../components/box/Box";
import Alert from "../components/CustomAlert/Alert";
import { ToastContainer, toast } from "react-toastify";
import Modal from "../components/CustomModal/Modal";
import MainLayout from "../layout/MainLayout";
import Button from "../components/Button";
import axios from "axios";
import config from "../configs/config";
import PartenerForm from "../components/dashboard/website/PartenerForm";
import { MainContext } from "../middleware/Contexts";
import PartenerTable from "../components/dashboard/website/PartenerTable";
import PublishAlert from "../components/CustomAlert/PublishAlert";
const Parteners = () => {
  const [partener, setPartener] = useState([]);
  const [isFetchingParteners, setIsFetchingParteners] = useState(false);
  const { authUser, setAuthUser } = useContext(MainContext);
  const [isAddNewPartener, setIsAddNewPartener] = useState(false);
  const [isOnUpdateProcess, setIsOnUpdateProcess] = useState(false);
  const [partenerToUpdate, setPartenerToUpdate] = useState(false);
  const [isOnPublishPartener, setIsOnPublishPartener] = useState(false);
  const [publishPartenerId, setPublishPartenerId] = useState("");
  const [isPublishPartener, setIsPublishPartener] = useState(false);
  const [selectedPartenerId, setSelectedPartenerId] = useState("");
  const [isOnDeletingPartener, setIsOnDeletingPartener] = useState(false);
  const [isDeletingPartener, setIsDeletingPartener] = useState(false);
  const [isOnViewSinglePartener, setIsOnViewSinglePartener] = useState(false);

  // get all Parteners
  const getAllParteners = () => {
    setIsFetchingParteners(true);
    axios
      .get(`${config.apiBaseUrl}/partners/admin`, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setPartener(response.data.data);
      })
      .catch((error) => {})
      .finally(() => setIsFetchingParteners(false));
  };
  useEffect(() => {
    getAllParteners();
  }, []);

  // Open and Close modal for adding new partener

  const onAddNewPartenerHandler = () => {
    setIsAddNewPartener(!isAddNewPartener);
    setIsOnUpdateProcess(false);
  };
  // Adding parteners to the list of parteners
  const returnNewAddedPartener = (data) => {
    if (isOnUpdateProcess) {
      partener.map((partener) => {
        if (partener.id === data.data.data.id) {
          partener.photoUrl = data.data.data.photoUr;
          partener.title = data.data.data.title;
        }
      });
    } else {
      setPartener((current) => [data.data.data, ...current]);
    }
    setTimeout(() => {
      onAddNewPartenerHandler();
    }, 800);
  };
  //update partener
  const updatePartener = (item) => {
    setPartenerToUpdate(item);
    setIsOnUpdateProcess(true);
    setIsAddNewPartener(!isAddNewPartener);
  };
  // Warning for publish partener
  const publishPartenerWarning = (id) => {
    setPublishPartenerId(id);
    setIsOnPublishPartener(!isOnPublishPartener);
  };
  // publish partener
  let data = null;
  const publishPartener = () => {
    setIsPublishPartener(true);
    axios
      .post(
        `${config.apiBaseUrl}/partners/publish-unpublish/${publishPartenerId}`,
        data,

        {
          headers: { authorization: authUser.token },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });

        setPartener(
          partener.map((partener) => {
            if (partener.id === publishPartenerId) {
              return { ...partener, status: res.data.data.status };
            }
            return partener;
          })
        );

        setIsOnPublishPartener(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsPublishPartener(false);
      });
  };
  // Deleting partener
  const deletePartenerWarning = (id) => {
    setIsOnDeletingPartener(!isOnDeletingPartener);
    setIsOnViewSinglePartener(isOnViewSinglePartener);
    setSelectedPartenerId(id);
  };
  const deletePartener = () => {
    setIsDeletingPartener(true);
    axios
      .delete(`${config.apiBaseUrl}/partners/${selectedPartenerId}`, {
        headers: { authorization: authUser.token },
      })
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setPartener(
          partener.filter((partener) => partener.id !== selectedPartenerId)
        );
        setIsOnDeletingPartener(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingPartener(false);
      });
  };
  return (
    <MainLayout>
      {/* Pop up warning while deleting */}
      {isOnDeletingPartener && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this partener"
          handleCancel={deletePartenerWarning}
          handleContinue={deletePartener}
          isLoading={isDeletingPartener}
        />
      )}
      {/* Publish Partener */}
      {isOnPublishPartener && (
        <PublishAlert
          title="Publish"
          warningMessage="Ready"
          handleCancel={publishPartenerWarning}
          handleContinue={publishPartener}
          isLoading={isPublishPartener}
        />
      )}
      {isAddNewPartener && (
        <Modal handleClicks={onAddNewPartenerHandler}>
          <PartenerForm
            returnNewAddedPartener={returnNewAddedPartener}
            isUpdateInProcess={isOnUpdateProcess}
            partenerToUpdate={partenerToUpdate}
            handleView={onAddNewPartenerHandler}
          />
        </Modal>
      )}
      <div className="row">
        <div className="col-12 col-md-12 col-sm-12 mb">
          <Button
            width="8rem"
            border="#FF9066 solid 1.5px"
            text="Add partener"
            color="#FF9066"
            bcolor="white"
            onClick={onAddNewPartenerHandler}
          />
        </div>
      </div>
      <Box>
        <h3>Partners</h3>
        <PartenerTable
          items={partener}
          updatePartener={updatePartener}
          publishPartener={publishPartenerWarning}
          deletePartener={deletePartenerWarning}
        />
      </Box>
    </MainLayout>
  );
};

export default Parteners;
