import React from "react";
import { useRef, useState, useContext, useEffect } from "react";
import axios from "axios";
import Button from "../../Button";
import { Editor } from "@tinymce/tinymce-react";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../configs/config";
import { MainContext } from "../../../middleware/Contexts";

import { GrFormClose } from "react-icons/gr";
const AnnouncementForm = ({
  returnNewAddedAnnouncement,
  isUpdateInProcess,
  announcementToUpdate,
  handleView,
}) => {
  const [title, setTitle] = useState([]);
  const [isAddingNewAnnouncement, setIsAddingNewAnnouncement] = useState(false);
  const [description, setDescription] = useState("");
  const { authUser, setAuthUser } = useContext(MainContext);

  const clearForm = () => {
    setTitle("");
    setDescription("");
  };
  useEffect(() => {
    if (isUpdateInProcess) {
      setTitle(announcementToUpdate.title);
      setDescription(announcementToUpdate.description);
    }
  }, []);

  function handleEditorChange(content, editor) {
    setDescription(content);
  }

  //add announcements
  const addNewAnnouncement = (e) => {
    e.preventDefault();
    if (!description) {
      return toast.error("Please add description content", {
        theme: "colored",
      });
    }
    setIsAddingNewAnnouncement(true);
    let data = null;
    if (isUpdateInProcess) {
      data = {
        id: announcementToUpdate.id,
        title,
        description,
      };
    } else {
      data = {
        title,
        description,
      };
    }

    const announcementUrl = isUpdateInProcess
      ? "/announcements/create"
      : "/announcements/create";

    axios
      .post(`${config.apiBaseUrl}${announcementUrl}`, data, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        clearForm();

        returnNewAddedAnnouncement(response);
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => setIsAddingNewAnnouncement(false));
  };

  return (
    <div>
      <div className="icons">
        <GrFormClose onClick={handleView} className="close" />
      </div>
      <form className="announcement userform" onSubmit={addNewAnnouncement}>
        <div>
          <input
            type="text"
            value={title}
            required
            placeholder="Announcement title"
            onChange={(event) => setTitle(event.target.value)}
          />
          <br />
          <label>Announcement Description</label>
          <Editor
            tinymceScriptSrc={
              "pzujokc07jcky4tcqpkli7nsatb4g0446k7qi57pl2b9u4u2" +
              "/tinymce/tinymce.min.js"
            }
            initialValue="<p></p>"
            init={{
              height: 300,
              //menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "preview",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
            value={description}
            onEditorChange={handleEditorChange}
          />
        </div>

        <div className="form-button row ">
          {isUpdateInProcess ? (
            <Button
              width="30%"
              border="#FF9066 solid 1px "
              text="Edit & Save"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewAnnouncement}
            />
          ) : (
            <Button
              width="30%"
              border="#FF9066 solid 1px "
              text="Save & Add"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewAnnouncement}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default AnnouncementForm;
