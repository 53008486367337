import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import config from "../../configs/config.js";
import axios from "axios";
import { MainContext } from "../../middleware/Contexts";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();

  const { authUser, setAuthUser } = useContext(MainContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loginHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(`${config.apiBaseUrl}/users/login`, { email, password })
      .then((response) => {
        setAuthUser({
          email: response.data.email,
          fullName: response.data.fullName,
          id: response.data.id,
          role: response.data.role,
          token: response.data.token,
        });
        localStorage.setItem("authUser", JSON.stringify(response.data));

        navigate("/dashboard");
      })
      .catch((error) => {
        if (error.response.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
        if (error.response.data.errors.length) {
          return toast.error(error.response.data.errors[0].msg, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="login">
        <div className="login-page">
          <div className="form">
            <h2>LOGIN</h2>
            <form className="login-form" onSubmit={loginHandler}>
              <input
                type="text"
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
              />
              <input
                type="password"
                placeholder="password"
                onChange={(event) => setPassword(event.target.value)}
              />

              <button type="submit">
                {isLoading ? <div className="on-loading"></div> : "login"}
              </button>

              <p className="message">
                Not loged in? <a href="/forgetpassword">Forget password</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
