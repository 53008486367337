import React, { useContext } from "react";
import "./topnav.scss";
import { data } from "../../constants";
import { MainContext } from "../../middleware/Contexts";
import { Link } from "react-router-dom";
const TopNav = () => {
  const { authUser, setAuthUser } = useContext(MainContext);

  const openSidebar = () => {
    document.body.classList.add("sidebar-open");
  };

  return (
    <div className="topnav">
      <div className="user-info">
        <div className="user-info__img">
          <img src={data.user.img} alt="" />
        </div>
        <div className="user-info__name">
          <Link to="/profile">
            <span>{authUser.fullName}</span>
          </Link>
        </div>
      </div>
      <div className="sidebar-toggle" onClick={openSidebar}>
        <i className="bx bx-menu-alt-right"></i>
      </div>
    </div>
  );
};

export default TopNav;
