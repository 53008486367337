import React, { useContext, useEffect, useState } from "react";
import "./sidebar.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { images } from "../../constants";
import { sidebarNav } from "../../configs/sidebarNav";
import SidebarItem from "./SidebarItem";
import { MainContext } from "../../middleware/Contexts";

const Sidebar = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const { authUser, setAuthUser } = useContext(MainContext);
  // const [data, setData] = useState([]);
  const location = useLocation();
  const handleLogout = () => {
    localStorage.removeItem("authUser");

    navigate("/");
  };
  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];

    const activeItem = sidebarNav.findIndex((item) => item.section === curPath);

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const closeSidebar = () => {
    document.querySelector(".main__content").style.transform =
      "scale(1) translateX(0)";
    setTimeout(() => {
      document.body.classList.remove("sidebar-open");
      document.querySelector(".main__content").style = "";
    }, 500);
  };
  // protectis sidebar menu by roles is done here
  let data = [];

  for (let i = 0; i < sidebarNav.length; i++) {
    for (let j = 0; j < sidebarNav[i].role.length; j++) {
      if (sidebarNav[i].role[j] == authUser.role) {
        data.push(sidebarNav[i]);
      }
    }
  }

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img src={images.logo} alt="logo" />
        <div className="sidebar-close" onClick={closeSidebar}>
          <i className="bx bx-x"></i>
        </div>
      </div>
      <div className="sidebar__menu">
        {data.map((item, index) => (
          <SidebarItem items={item} key={`nav-${index}`} />
        ))}
        <div className="sidebar__menu__item">
          <div className="sidebar__menu__item__icon">
            <i className="bx bx-log-out"></i>
          </div>
          <div className="sidebar__menu__item__txt">
            <Link
              to="/"
              onClick={() => {
                handleLogout();
              }}
            >
              Logout
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
