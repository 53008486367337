import React, { useContext, useEffect, useState, useRef } from "react";
import Button from "../components/Button";
import MainLayout from "../layout/MainLayout";
import axios from "axios";
import config from "../configs/config";
import Box from "../components/box/Box";
import ApartmentForm from "../components/dashboard/apartment/ApartmentForm";
import Loading from "../components/Loading/index";
import SearchForm from "../components/dashboard/apartment/SearchForm";
import { toast } from "react-toastify";
import Modal from "../components/CustomModal/Modal";
import ApartmentTable from "../components/dashboard/apartment/ApartmentTable";
import ViewAlert from "../components/dashboard/apartment/ViewAlert";
import { MainContext, ApartmentContext } from "../middleware/Contexts";
import Alert from "../components/CustomAlert/Alert";
import HouseForm from "../components/dashboard/apartment/ApartmentHouseForm";
const Apartment = () => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [isAddNewApartment, setIsAddNewApartment] = useState(false);
  const [isAddNewHouse, setIsAddNewHouse] = useState(false);
  const [apartments, setApartments] = useState([]);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [isOnUpdateProcess, setIsOnUpdateProcess] = useState(false);
  const [isUpdateInProcess, setIsUpdateInProcess] = useState(false);
  const [isUpdateHouseInProcess, setIsUpdateHouseInProcess] = useState(false);
  const [isLoadingApartments, setIsLoadingApartments] = useState(false);
  const [isOnViewSingleApartment, setIsOnViewSingleApartment] = useState(false);
  const [isOnViewApartment, setIsOnViewApartment] = useState([]);
  const [isOnDeletingApartment, setIsOnDeletingApartment] = useState(false);
  const [selectedApartmentId, setSelectedApartmentId] = useState("");
  const [isDeletingApartment, setIsDeletingApartment] = useState(false);
  const [apartmentToUpdate, setApartmentToUpdate] = useState(null);
  const [houseToUpdate, setHouseToUpdate] = useState(null);
  const [apartmentValueToSearch, setApartmentValueToSearch] = useState("");
  const [isSearchingApartment, setIsSearchingApartment] = useState(false);

  const [lastPage, setLastPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPrevLoading, setIsPrevLoading] = useState(false);

  // Open and Close modal for adding new appartment
  const onAddNewApartmentHandler = () => {
    setIsAddNewApartment(!isAddNewApartment);
    setIsOnUpdateProcess(false);
  };

  // Open and Close modal for adding new house
  const onAddNewHouseHandler = (apartmentId) => {
    if (!isAddNewHouse) {
      apartments.map((apt) => {
        if (apt.id === apartmentId) {
          setSelectedApartment(apt);
        }
      });
    }

    setIsAddNewHouse(!isAddNewHouse);
    setIsOnUpdateProcess(false);
    setIsUpdateHouseInProcess(false);
  };

  // Adding added apartment to the list of apartments
  const returnNewAddedApartments = (data) => {
    if (isOnUpdateProcess) {
      apartments.map((apartment) => {
        if (apartment.id === data.data.data.id) {
          apartment.name = data.data.data.name;
          apartment.ownerName = data.data.data.ownerName;
          apartment.ownerPhone = data.data.data.ownerPhone;
          apartment.province = data.data.data.province;
          apartment.district = data.data.data.district;
          apartment.sector = data.data.data.sector;
        }
      });
    } else {
      // setApartments((current) => [data.data.data, ...current]);
      getAllApartmentsHandler();
    }
    setTimeout(() => {
      onAddNewApartmentHandler();
    }, 800);
  };
  // adding house
  const returnNewAddedHouse = () => {
    getAllApartmentsHandler();
    setTimeout(() => {
      setIsAddNewHouse(false);
    }, 800);
  };

  //update apartment
  const updateApartment = (apartment) => {
    setApartmentToUpdate(apartment);
    setIsOnUpdateProcess(true);
    setIsAddNewApartment(!isAddNewApartment);
  };
  // Get list of Apartments
  const getAllApartmentsHandler = () => {
    setIsLoadingApartments(true);
    axios
      .get(
        `${config.apiBaseUrl}/apartments/get-all?page=${nextPage}&limit=10`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        setApartments(response.data.data);

        setLastPage(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoading(false);
        setIsNextLoading(false);
        setIsLoadingApartments(false);
      });
  };
  useEffect(() => {
    getAllApartmentsHandler();
  }, []);

  useEffect(() => {
    if (lastPage) getAllApartmentsHandler();
  }, [nextPage]);

  // Getting users on the next page
  const getApartmentsListOnNextPage = () => {
    setIsNextLoading(true);
    setNextPage(nextPage + 1);
  };
  const getApartmentsListOnPrevPage = () => {
    setIsPrevLoading(true);
    setNextPage(nextPage - 1);
  };

  // console.log("apartmentContext", apartmentContext);
  // Deleting apartment
  const deleteApartmentWarning = (id) => {
    setIsOnDeletingApartment(!isOnDeletingApartment);
    setIsOnViewSingleApartment(isOnViewSingleApartment);
    setSelectedApartmentId(id);
  };
  const deleteApartment = () => {
    setIsDeletingApartment(true);
    axios
      .delete(
        `${config.apiBaseUrl}/apartments/delete-apartment/${selectedApartmentId}`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setApartments(
          apartments.filter((apartment) => apartment.id !== selectedApartmentId)
        );
        setIsOnDeletingApartment(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingApartment(false);
      });
  };
  //View single apartment
  const getSingleApartmentHandler = (id) => {
    setIsOnViewSingleApartment(!isOnViewSingleApartment);
    const data = apartments.find((apartment) => apartment?.id == id);
    setIsOnViewApartment([data]);
  };
  // Search apartment
  const searchApartmentHandler = (event) => {
    if (event.keyCode === 13) {
      if (!apartmentValueToSearch) {
        return toast.error(
          "Please type either name, sector, district or province",
          { theme: "colored" }
        );
      }

      setIsSearchingApartment(true);
      axios
        .post(
          `${config.apiBaseUrl}/apartments/search-by-value?data=${apartmentValueToSearch} `,
          null,
          {
            headers: { authorization: authUser.token },
          }
        )
        .then((response) => {
          setApartments(response.data);
        })
        .catch((error) => {
          if (error.response?.data.error) {
            return toast.error(error.response.data.error, { theme: "colored" });
          }
          if (error.response?.data.message) {
            return toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          setIsSearchingApartment(false);
          setLastPage(0);
          setNextPage(1);
        });
    }
  };
  const [isOnDeletingApatsHouse, setIsOnDeletingApatsHouse] = useState(false);
  const [isDeletingApatsHouse, setIsDeletingApatsHouse] = useState(false);
  const [selectedApatsHouseId, setSelectedHouseApatsId] = useState("");
  const [isOnViewSingleApatsHouse, setIsOnViewSingleApatsHouse] =
    useState(false);

  const deleteApatsHouseWarning = (id) => {
    setIsOnViewSingleApartment(!isOnViewSingleApartment);
    setIsOnDeletingApatsHouse(!isOnDeletingApatsHouse);
    setIsOnViewSingleApatsHouse(isOnViewSingleApatsHouse);
    setSelectedHouseApatsId(id);
  };
  const deleteApatsHouse = () => {
    setIsDeletingApatsHouse(true);
    axios
      .delete(
        `${config.apiBaseUrl}/apartments/delete-house/${selectedApatsHouseId}`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        getAllApartmentsHandler();
        setIsOnDeletingApatsHouse(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingApatsHouse(false);
      });
  };
  const updateApatsHouse = (house) => {
    setHouseToUpdate(house);
    setIsOnViewSingleApartment(!isOnViewSingleApartment);
    setIsUpdateHouseInProcess(true);
    setIsAddNewHouse(!isAddNewHouse);
  };
  return (
    <MainLayout>
      {isOnDeletingApatsHouse && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this house"
          handleCancel={deleteApatsHouseWarning}
          handleContinue={deleteApatsHouse}
          isLoading={isDeletingApatsHouse}
        />
      )}

      {/* Apartment form modal */}
      {isAddNewApartment && (
        <Modal handleClicks={onAddNewApartmentHandler}>
          <ApartmentForm
            returnNewAddedApartment={returnNewAddedApartments}
            isUpdateInProcess={isOnUpdateProcess}
            // selectedApartment={apartmentToUpdate}
            apartmentToUpdate={apartmentToUpdate}
            handleView={onAddNewApartmentHandler}
          />
        </Modal>
      )}
      {isAddNewHouse && (
        <Modal handleClicks={onAddNewHouseHandler}>
          <HouseForm
            returnNewAddedHouse={returnNewAddedHouse}
            isUpdateHouseInProcess={isUpdateHouseInProcess}
            selectedApartment={selectedApartment}
            houseToUpdate={houseToUpdate}
            handleView={onAddNewHouseHandler}
          />
        </Modal>
      )}

      {/* view single appartment */}
      {isOnViewSingleApartment && (
        <Modal handleClicks={getSingleApartmentHandler}>
          <ViewAlert
            singleApartment={isOnViewApartment}
            title="View apartment"
            warningMessage="want see this client"
            handleView={getSingleApartmentHandler}
            updateApatsHouse={updateApatsHouse}
            deleteApatsHouse={deleteApatsHouseWarning}
          />
        </Modal>
      )}

      {/* Pop up warning while deleting */}
      {isOnDeletingApartment && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this apartment"
          handleCancel={deleteApartmentWarning}
          handleContinue={deleteApartment}
          isLoading={isDeletingApartment}
        />
      )}

      <div className="row">
        <div className="col-12 col-md-12 col-sm-12 mb">
          <div className="row">
            <div className="col-6 col-md-6 col-sm-12 searchbox">
              <Button
                width="9rem"
                border="#FF9066 solid 1.5px"
                text="Add Apartment +"
                color="#FF9066"
                bcolor="white"
                onClick={onAddNewApartmentHandler}
              />
              <div className="group">
                <svg className="icon" aria-hidden="true" viewBox="0 0 24 24">
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                  </g>
                </svg>
                <input
                  placeholder="Search Apartments"
                  type="search"
                  className="input"
                  onChange={(event) =>
                    setApartmentValueToSearch(event.target.value)
                  }
                  onKeyDown={searchApartmentHandler}
                />
                {isSearchingApartment && <span>Loading...</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Box>
        <h3>Apartments list</h3>
        {apartments.length ? (
          <ApartmentTable
            apartments={apartments}
            getSingleApartmentHandler={getSingleApartmentHandler}
            updateApartment={updateApartment}
            deleteApartment={deleteApartmentWarning}
            addHouseToAppartment={onAddNewHouseHandler}
            getAllApartmentsHandler={getAllApartmentsHandler}
            lastPage={lastPage}
            nextPage={nextPage}
            isPrevLoading={isPrevLoading}
            isNextLoading={isNextLoading}
            handleClickOnNext={getApartmentsListOnNextPage}
            handleClickOnPrev={getApartmentsListOnPrevPage}
          />
        ) : (
          ""
        )}
        {isLoadingApartments && <Loading />}
      </Box>
    </MainLayout>
  );
};

export default Apartment;
