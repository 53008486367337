import React, { useContext, useEffect, useState, useRef } from "react";
import Button from "../components/Button";
import Alert from "../components/CustomAlert/Alert";
import Loading from "../components/Loading/index";

import { toast } from "react-toastify";
import { MainContext } from "../middleware/Contexts";
import MainLayout from "../layout/MainLayout";
import axios from "axios";
import config from "../configs/config";
import Box from "../components/box/Box";
import AvailableAlert from "../components/CustomAlert/AvailableAlert";
import CheckoutForm from "../components/dashboard/finance/office/CheckoutForm";
import OfficeTable from "../components/dashboard/finance/office/OfficeTable";
import OfficeNotPayedTable from "../components/dashboard/finance/office/OfficeNotPayedTable";
import Modal from "../components/CustomModal/Modal";
import ViewClient from "../components/dashboard/finance/office/ViewAlert";
import OverDueClientTable from "../components/dashboard/finance/office/OverDueClientTable";
import ClientForm from "../components/dashboard/finance/office/ClientForm";
import PaymentForm from "../components/dashboard/finance/office/PaymentForm";
import PayedClientTable from "../components/dashboard/finance/office/PayedClientTable";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const FinanceOffice = () => {
  const [offices, setOffices] = useState([]);
  const { authUser, setAuthUser } = useContext(MainContext);
  const [officeClients, setOfficeClients] = useState([]);
  const [overdueClients, setOverdueClients] = useState([]);
  const [isOnDeletingClient, setIsOnDeletingClient] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [isDeletingClient, setIsDeletingClient] = useState(false);
  const [clientToUpdate, setClientToUpdate] = useState(null);
  const [isOnViewSingleClient, setIsOnViewSingleClient] = useState(false);
  const [singleClientData, setSingleClientData] = useState(null);
  const [addOfficeClient, setAddOfficeClient] = useState(null);
  const [isAddNewPayment, setIsAddNewPayment] = useState(false);
  const [payedClients, setPayedClients] = useState([]);
  const [addOfficeId, setAddOfficeId] = useState(null);
  const [isAddNewClient, setIsAddNewClient] = useState(false);
  const [isOnUpdateProcess, setIsOnUpdateProcess] = useState(false);
  const [isPutNewCheckout, setIsPutNewCheckout] = useState(false);
  const [checkoutClientId, setCheckoutClientId] = useState(false);
  const [selectedOfficeId, setSelectedOfficeId] = useState(null);
  const [isOnOfficeAvailable, setIsOnOfficeAvailable] = useState(false);
  const [isOfficeAvailable, setIsOfficeAvailable] = useState(false);

  const [officeClientValueToSearch, setOfficeClientValueToSearch] =
    useState("");
  const [isSearchingOfficeClient, setIsSearchingOfficeClient] = useState(false);

  const [dateRangeFom, setDateRangeFom] = useState("");
  const [dateRangeTo, setDateRangeTo] = useState("");
  const [isSearchingInRange, setIsSearchingInRange] = useState(false);

  const [clientPaymentToUpdate, setClientPaymentToUpdate] = useState(null);
  const [isUpdateClientPy, setIsUpdateClientPy] = useState(false);

  const [isLoadingOffices, setIsLoadingOffices] = useState(false);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [isLoadingPaidClients, setIsLoadingPaidClients] = useState(false);
  const [isLoadingOverdueClients, setIsLoadingOverdueClients] = useState(false);

  const [lastPageClient, setLastPageClient] = useState(0);
  const [nextPageClient, setNextPageClient] = useState(1);
  const [isNextLoadingClient, setIsNextLoadingClient] = useState(false);
  const [isPrevLoadingClient, setIsPrevLoadingClient] = useState(false);
  const [lastPagePaid, setLastPagePaid] = useState(0);
  const [nextPagePaid, setNextPagePaid] = useState(1);
  const [isNextLoadingPaid, setIsNextLoadingPaid] = useState(false);
  const [isPrevLoadingPaid, setIsPrevLoadingPaid] = useState(false);
  const [lastPageOverdue, setLastPageOverdue] = useState(0);
  const [nextPageOverdue, setNextPageOverdue] = useState(1);
  const [isNextLoadingOverdue, setIsNextLoadingOverdue] = useState(false);
  const [isPrevLoadingOverdue, setIsPrevLoadingOverdue] = useState(false);
  const [lastPageOffice, setLastPageOffice] = useState(0);
  const [nextPageOffice, setNextPageOffice] = useState(1);
  const [isNextLoadingOffice, setIsNextLoadingOffice] = useState(false);
  const [isPrevLoadingOffice, setIsPrevLoadingOffice] = useState(false);

  const getAllOfficesHandler = () => {
    setIsLoadingOffices(true);
    axios
      .get(
        `${config.apiBaseUrl}/offices/list?page=${nextPageOffice}}&limit=10`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        setOffices(response.data.data);
        setLastPageOffice(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoadingOffice(false);
        setIsNextLoadingOffice(false);
        setIsLoadingOffices(false);
      });
  };
  useEffect(() => {
    getAllOfficesHandler();
  }, []);

  useEffect(() => {
    if (lastPageOffice) getAllOfficesHandler();
  }, [nextPageOffice]);

  const getOfficesListOnNextPage = () => {
    setIsNextLoadingOffice(true);
    setNextPageOffice(nextPageOffice + 1);
  };
  const getOfficesListOnPrevPage = () => {
    setIsPrevLoadingOffice(true);
    setNextPageOffice(nextPageOffice - 1);
  };

  //List of all clients
  const getAllClientsHandler = () => {
    setIsLoadingClients(true);
    axios
      .get(
        `${config.apiBaseUrl}/offices-clients?page=${nextPageClient}&limit=10`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        setOfficeClients(response.data.data);
        setLastPageClient(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoadingClient(false);
        setIsNextLoadingClient(false);
        setIsLoadingClients(false);
      });
  };
  useEffect(() => {
    getAllClientsHandler();
  }, []);

  //List of all PayedClients
  const getAllPayedClientsHandler = () => {
    setIsLoadingPaidClients(true);
    axios
      .get(
        `${config.apiBaseUrl}/offices-finances/paid-report?page=${nextPagePaid}&limit=10`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        setPayedClients(response.data.data);
        setLastPagePaid(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoadingPaid(false);
        setIsNextLoadingPaid(false);
        setIsLoadingPaidClients(false);
      });
  };
  useEffect(() => {
    getAllPayedClientsHandler();
  }, []);

  //List of all OverdueClients
  const getAllOverdueClientsHandler = () => {
    setIsLoadingOverdueClients(true);
    axios
      .get(
        `${config.apiBaseUrl}/offices-finances/overdue-report?page=${nextPageOverdue}&limit=10`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        setOverdueClients(response.data.data);
        setLastPageOverdue(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoadingOverdue(false);
        setIsNextLoadingOverdue(false);
        setIsLoadingOverdueClients(false);
      });
  };
  useEffect(() => {
    getAllOverdueClientsHandler();
  }, []);

  useEffect(() => {
    if (lastPageClient) getAllClientsHandler();
  }, [nextPageClient]);
  useEffect(() => {
    if (lastPagePaid) getAllPayedClientsHandler();
  }, [nextPagePaid]);
  useEffect(() => {
    if (lastPageOverdue) getAllOverdueClientsHandler();
  }, [nextPageOverdue]);

  // Getting users on the next page
  const getClientsListOnNextPage = () => {
    setIsNextLoadingClient(true);
    setNextPageClient(nextPageClient + 1);
  };
  const getClientsListOnPrevPage = () => {
    setIsPrevLoadingClient(true);
    setNextPageClient(nextPageClient - 1);
  };
  const getPaidListOnNextPage = () => {
    setIsNextLoadingPaid(true);
    setNextPagePaid(nextPagePaid + 1);
  };
  const getPaidListOnPrevPage = () => {
    setIsPrevLoadingPaid(true);
    setNextPagePaid(nextPagePaid - 1);
  };
  const getOverdueListOnNextPage = () => {
    setIsNextLoadingOverdue(true);
    setNextPageOverdue(nextPageOverdue + 1);
  };
  const getOverdueListOnPrevPage = () => {
    setIsPrevLoadingOverdue(true);
    setNextPageOverdue(nextPageOverdue - 1);
  };

  // Open and Close modal for adding new client in office
  const onAddNewClientHandler = (id) => {
    setAddOfficeId(id);
    setIsAddNewClient(!isAddNewClient);
    setIsOnUpdateProcess(false);
  };

  //adding new client
  const returnNewAddedClient = (data) => {
    setTimeout(() => {
      onAddNewClientHandler();
    }, 800);
    getAllClientsHandler();
    getAllOfficesHandler();
  };

  //update client
  const updateClient = (client, id) => {
    setAddOfficeId(id);
    setIsOnUpdateProcess(true);
    setClientToUpdate(client);

    setIsAddNewClient(!isAddNewClient);
  };
  //adding new Payment
  const returnNewAddedPayment = (data) => {
    setTimeout(() => {
      onAddNewPaymentHandler();
    }, 800);
    getAllPayedClientsHandler();
    getAllOverdueClientsHandler();
  };

  // Open and Close modal for adding Payment
  const onAddNewPaymentHandler = (client) => {
    setIsUpdateClientPy(false);
    setAddOfficeClient(client);
    setIsAddNewPayment(!isAddNewPayment);
  };

  // Open and Close modal for checkout
  const onCheckoutHandler = (id) => {
    setCheckoutClientId(id);
    setIsPutNewCheckout(!isPutNewCheckout);
  };

  //checkout client
  const returnCheckoutClient = (data) => {
    setPayedClients((current) => [data.data.data, ...current]);
    // setOffices((current) => [data.data.data, ...current]);
    setTimeout(() => {
      onCheckoutHandler();
    }, 800);
  };

  //View single client
  const getSingleClientHandler = (id) => {
    setIsOnViewSingleClient(!isOnViewSingleClient);
    const data = officeClients.find((client) => client?.id == id);
    if (data) {
      setSingleClientData({
        OfficeId: data.OfficeId,
        birthDate: data.birthDate,
        country: data.country,
        createdAt: data.createdAt,
        district: data.district,
        email: data.email,
        fullName: data.fullName,
        gender: data.gender,
        id: data.id,
        inDate: data.inDate,
        nId: data.nId,
        officeName: data.officeName,
        outDate: data.outDate,
        phone: data.phone,
        province: data.province,
        sector: data.sector,
        updatedAt: data.updatedAt,
        Offices_Payment: null,
        Offices_LastPayments: [],
      });
    }
  };

  const getSingleClientWithPayment = (id) => {
    setIsOnViewSingleClient(!isOnViewSingleClient);
    const data = payedClients.find((client) => client?.id == id);
    setSingleClientData(data);
  };
  const getSingleClientOverdue = (id) => {
    setIsOnViewSingleClient(!isOnViewSingleClient);
    const data = overdueClients.find((client) => client?.id == id);
    setSingleClientData(data);
  };

  // Warning for deleting client
  const deleteClientWarning = (id) => {
    setSelectedClientId(id);
    setIsOnDeletingClient(!isOnDeletingClient);
  };

  // Deleting client
  const deleteClient = () => {
    setIsDeletingClient(true);
    axios
      .delete(
        `${config.apiBaseUrl}/offices-clients/delete/${selectedClientId}`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setOfficeClients(
          officeClients.filter((client) => client.id !== selectedClientId)
        );
        setIsOnDeletingClient(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingClient(false);
      });
  };

  // Warning for making office available
  const officeAvalableWarning = (id) => {
    setSelectedOfficeId(id);
    setIsOnOfficeAvailable(!isOnOfficeAvailable);
  };

  // Available office
  let data = null;
  const availableOffice = () => {
    setIsOfficeAvailable(true);
    axios
      .post(
        `${config.apiBaseUrl}/offices/make-available/${selectedOfficeId}`,
        data,

        {
          headers: { authorization: authUser.token },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setOffices(
          offices.map((item) =>
            item.id === selectedOfficeId ? { ...item, isAvailable: true } : item
          )
        );
        setOfficeClients(
          officeClients.filter((client) => client.id !== selectedOfficeId)
        );
        setIsOnOfficeAvailable(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsOfficeAvailable(false);
      });
  };

  // search office clients
  const searchOfficeClientHandler = (event) => {
    if (event.keyCode === 13) {
      if (!officeClientValueToSearch) {
        return toast.error(
          "Please type either fullName, phone, email or national ID",
          {
            theme: "colored",
          }
        );
      }

      setIsSearchingOfficeClient(true);
      axios
        .get(
          `${config.apiBaseUrl}/houses-clients/search?searchValue=${officeClientValueToSearch} `,
          {
            headers: { authorization: authUser.token },
          }
        )
        .then((response) => {
          setOfficeClients(response.data);
        })
        .catch((error) => {
          if (error.response?.data.error) {
            return toast.error(error.response.data.error, { theme: "colored" });
          }
          if (error.response?.data.message) {
            return toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          setIsSearchingOfficeClient(false);
          setLastPageClient(0);
          setNextPageClient(1);
        });
    }
  };

  // Search by range
  const searchPaymentByRangeHandler = ({ isPaid, isOverDue }) => {
    if (!dateRangeFom || !dateRangeTo) {
      return toast.error("Please select range dates", {
        theme: "colored",
      });
    }
    setIsSearchingInRange(true);
    const paidURL = `offices-finances/paid-range-report?startDate=${dateRangeFom}&endDate=${dateRangeTo}&page=1&limit=10`;
    const overDueURL = `offices-finances/overdue-range-report?startDate=${dateRangeFom}&endDate=${dateRangeTo}&page=1&limit=10`;
    const urlToUser = isPaid ? paidURL : overDueURL;
    axios
      .get(`${config.apiBaseUrl}/${urlToUser}`, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        if (isPaid) {
          setPayedClients(response.data.data);
        }
        if (isOverDue) {
          setOverdueClients(response.data.data);
        }
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsSearchingInRange(false);
        setLastPagePaid(0);
        setNextPagePaid(1);
        setLastPageOverdue(0);
        setNextPageOverdue(1);
      });
  };
  // get payed excel
  const exportExcelPaidMethod = () => {
    if (!dateRangeFom || !dateRangeTo) {
      return toast.error("Please select range of dates", {
        theme: "colored",
      });
    }
    axios
      .get(
        `${config.apiBaseUrl}/export-data/offices-paid-range-report?startDate=${dateRangeFom}&endDate=${dateRangeTo}`,
        {
          headers: { authorization: authUser.token },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `paid_client.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        alert("Something went wrong");
      });
  };
  // get overdue excel
  const exportExcelOverDueMethod = () => {
    if (!dateRangeFom || !dateRangeTo) {
      return toast.error("Please select range of dates", {
        theme: "colored",
      });
    }
    axios
      .get(
        `${config.apiBaseUrl}/export-data/offices-paid-range-report?startDate=${dateRangeFom}&endDate=${dateRangeTo}`,
        {
          headers: { authorization: authUser.token },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `overdue_client.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        alert("Something went wrong");
      });
  };

  // Update payment
  const updateClientPayment = (clientPayment) => {
    setClientPaymentToUpdate(clientPayment);
    setIsUpdateClientPy(!isUpdateClientPy);
    setIsAddNewPayment(!isAddNewPayment);
  };

  return (
    <MainLayout>
      {/* deleting a client */}
      {isOnDeletingClient && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this user"
          handleCancel={deleteClientWarning}
          handleContinue={deleteClient}
          isLoading={isDeletingClient}
        />
      )}
      {/* office availability */}
      {isOnOfficeAvailable && (
        <AvailableAlert
          title="Warning"
          warningMessage="Are you sure you want to make this office available"
          handleCancel={officeAvalableWarning}
          handleContinue={availableOffice}
          isLoading={isOfficeAvailable}
        />
      )}
      {/* adding new client */}
      {isAddNewClient && (
        <Modal handleClicks={onAddNewClientHandler}>
          <ClientForm
            returnNewAddedClient={returnNewAddedClient}
            isUpdateInProcess={isOnUpdateProcess}
            clientToUpdate={clientToUpdate}
            OfficeId={addOfficeId}
            handleView={onAddNewClientHandler}
          />
        </Modal>
      )}
      {isAddNewPayment && (
        <Modal handleClicks={onAddNewPaymentHandler}>
          <PaymentForm
            returnNewAddedPayment={returnNewAddedPayment}
            isUpdateInProcess={isUpdateClientPy}
            paymentToUpdate={clientPaymentToUpdate}
            OfficesClient={addOfficeClient}
            handleView={onAddNewPaymentHandler}
          />
        </Modal>
      )}

      {/*checkout client */}
      {isPutNewCheckout && (
        <Modal handleClicks={onCheckoutHandler}>
          <CheckoutForm
            returnCheckoutClient={returnCheckoutClient}
            id={checkoutClientId}
            handleView={onCheckoutHandler}
          />
        </Modal>
      )}

      {/* view single client */}
      {isOnViewSingleClient && (
        <Modal handleClicks={getSingleClientHandler}>
          <ViewClient
            singleClient={singleClientData}
            title="View Client"
            handleView={getSingleClientHandler}
          />
        </Modal>
      )}
      <div className="row">
        <div className="col-12 col-md-12 col-sm-12 mb">
          <Box>
            <OfficeTable
              offices={offices}
              addClientToOffice={onAddNewClientHandler}
              availableOffice={officeAvalableWarning}
              lastPage={lastPageOffice}
              nextPage={nextPageOffice}
              isPrevLoading={isPrevLoadingOffice}
              isNextLoading={isNextLoadingOffice}
              handleClickOnNext={getOfficesListOnNextPage}
              handleClickOnPrev={getOfficesListOnPrevPage}
            />
            {isLoadingOffices && <Loading />}
          </Box>
        </div>
      </div>
      <Tabs>
        <TabList className="title mb">
          <Tab>Clients</Tab>
          <Tab>Paid</Tab>
          <Tab>Overdue</Tab>
        </TabList>
        <TabPanel>
          <Box>
            <div className="searchbox">
              <h3>Offices clients list</h3>

              <div className="group">
                <svg className="icon" aria-hidden="true" viewBox="0 0 24 24">
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                  </g>
                </svg>
                <input
                  placeholder="Search by name, phone, email or ID number"
                  type="search"
                  className="input"
                  onChange={(event) =>
                    setOfficeClientValueToSearch(event.target.value)
                  }
                  onKeyDown={searchOfficeClientHandler}
                />
                {isSearchingOfficeClient && <span>Loading...</span>}
              </div>
            </div>
            <OfficeNotPayedTable
              clients={officeClients}
              getSingleClientHandler={getSingleClientHandler}
              addPaymentToOffice={onAddNewPaymentHandler}
              deleteClient={deleteClientWarning}
              removeClientToOffice={onCheckoutHandler}
              updateClient={updateClient}
              lastPage={lastPageClient}
              nextPage={nextPageClient}
              isPrevLoading={isPrevLoadingClient}
              isNextLoading={isNextLoadingClient}
              handleClickOnNext={getClientsListOnNextPage}
              handleClickOnPrev={getClientsListOnPrevPage}
            />
            {isLoadingClients && <Loading />}
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <div className="row">
              <div className="col-6 col-md-6 col-sm-12">
                <div className="date-range-container">
                  <div className="date-range">
                    <label>From</label>
                    <input
                      type="date"
                      className="date-range-search"
                      onChange={(event) => setDateRangeFom(event.target.value)}
                    />
                  </div>
                  <div className="date-range">
                    <label>To</label>
                    <input
                      type="date"
                      className="date-range-search"
                      onChange={(event) => setDateRangeTo(event.target.value)}
                    />
                  </div>

                  <Button
                    width="6rem"
                    border="#FF9066 solid 1px "
                    text="Search"
                    color="#FF9066"
                    bcolor="white"
                    className="col-2"
                    onClick={() =>
                      searchPaymentByRangeHandler({
                        isPaid: true,
                        isOverDue: false,
                      })
                    }
                  />
                  {isSearchingInRange && <span>Loading...</span>}
                </div>{" "}
              </div>{" "}
              <div className="col-6 col-md-6 col-sm-12">
                <Button
                  width="10rem"
                  border="#4CAF50 solid 1px "
                  text="Download excel"
                  color="#fff"
                  bcolor="#4CAF50"
                  className="col-2"
                  onClick={() => exportExcelPaidMethod()}
                />
              </div>
            </div>
            <PayedClientTable
              clients={payedClients}
              updatePayment={updateClientPayment}
              getSingleClientHandler={getSingleClientWithPayment}
              addPaymentToOffice={onAddNewPaymentHandler}
              lastPage={lastPagePaid}
              nextPage={nextPagePaid}
              isPrevLoading={isPrevLoadingPaid}
              isNextLoading={isNextLoadingPaid}
              handleClickOnNext={getPaidListOnNextPage}
              handleClickOnPrev={getPaidListOnPrevPage}
            />
            {isLoadingPaidClients && <Loading />}
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <div className="row">
              <div className="col-6 col-md-6 col-sm-12">
                <div className="date-range-container">
                  <div className="date-range">
                    <label>From</label>
                    <input
                      type="date"
                      className="date-range-search"
                      onChange={(event) => setDateRangeFom(event.target.value)}
                    />
                  </div>
                  <div className="date-range">
                    <label>To</label>
                    <input
                      type="date"
                      className="date-range-search"
                      onChange={(event) => setDateRangeTo(event.target.value)}
                    />
                  </div>

                  <Button
                    width="6rem"
                    border="#FF9066 solid 1px "
                    text="Search"
                    color="#FF9066"
                    bcolor="white"
                    className="col-2"
                    onClick={() =>
                      searchPaymentByRangeHandler({
                        isPaid: false,
                        isOverDue: true,
                      })
                    }
                  />
                  {isSearchingInRange && <span>Loading...</span>}
                </div>{" "}
              </div>
              <div className="col-6 col-md-6 col-sm-12">
                <Button
                  width="10rem"
                  border="#4CAF50 solid 1px "
                  text="Download excel"
                  color="#fff"
                  bcolor="#4CAF50"
                  className="col-2"
                  onClick={() => exportExcelOverDueMethod()}
                />
              </div>
            </div>
            <OverDueClientTable
              clients={overdueClients}
              getSingleClientHandler={getSingleClientOverdue}
              addPaymentToOffice={onAddNewPaymentHandler}
              lastPage={lastPageOverdue}
              nextPage={nextPageOverdue}
              isPrevLoading={isPrevLoadingOverdue}
              isNextLoading={isNextLoadingOverdue}
              handleClickOnNext={getOverdueListOnNextPage}
              handleClickOnPrev={getOverdueListOnPrevPage}
            />
            {isLoadingOverdueClients && <Loading />}
          </Box>
        </TabPanel>
      </Tabs>
    </MainLayout>
  );
};

export default FinanceOffice;
