import React, { useContext, useEffect, useState } from "react";
import Tables from "../components/dashboard/user/Tables";
import MainLayout from "../layout/MainLayout";
import Box from "../components/box/Box";
import axios from "axios";
import config from "../configs/config";
import { MainContext } from "../middleware/Contexts";
import Button from "../components/Button";
import Modal from "../components/CustomModal/Modal";
import UserForm from "../components/dashboard/user/UserForm";
import Loading from "../components/Loading/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../components/CustomAlert/Alert";
import ViewAlert from "../components/dashboard/user/ViewAlert";

const Users = () => {
  const { authUser, setAuthUser } = useContext(MainContext);

  const [isAddNewUser, setIsAddNewUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [isOnViewSingleUser, setIsOnViewSingleUser] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPrevLoading, setIsPrevLoading] = useState(false);
  const [isOnDeletingUser, setIsOnDeletingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUpdateInProcess, setIsUpdateInProcess] = useState(false);
  const [searchUserValue, setSearchUserValue] = useState("");
  const [isOnViewUser, setIsOnViewUser] = useState([]);
  const [isSearchingUser, setIsSearchingUser] = useState(false);

  useEffect(() => {
    if (authUser) {
      getUsersList();
    }
  }, [authUser]);

  useEffect(() => {
    getUsersList();
  }, [nextPage]);

  // Getting list of users
  const getUsersList = () => {
    axios
      .get(`${config.apiBaseUrl}/users/list?page=${nextPage}&limit=10`, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setUsers(response.data.data);
        setLastPage(response.data.lastPage);
      })
      .catch((error) => {
        alert("Something went wrong! can't load list of users");
      })
      .finally(() => {
        setIsNextLoading(false);
        setIsPrevLoading(false);
      });
  };

  // Getting users on the next page
  const getUsersListOnNextPage = () => {
    setIsNextLoading(true);
    setNextPage(nextPage + 1);
  };
  const getUsersListOnPrevPage = () => {
    setIsPrevLoading(true);
    setNextPage(nextPage - 1);
  };

  // Open and closing model
  const handleModel = () => {
    setIsAddNewUser(!isAddNewUser);
  };

  // Open form to add new user
  const onAddNewUserHandler = () => {
    setIsUpdateInProcess(false);
    setSelectedUser(null);
    setIsAddNewUser(true);
  };

  // Adding added user to the list of users
  const returnNewAddedUser = (data) => {
    if (isUpdateInProcess) {
      const userIndex = users.findIndex(
        (user) => user.id === data.data.data.id
      );
      users[userIndex].district = data.data.data.district;
      users[userIndex].email = data.data.data.email;
      users[userIndex].fullName = data.data.data.fullName;
      users[userIndex].gender = data.data.data.gender;
      users[userIndex].phone = data.data.data.phone;
      users[userIndex].province = data.data.data.province;
      users[userIndex].role = data.data.data.role;
      users[userIndex].sector = data.data.data.sector;
      setIsAddNewUser(false);
    } else {
      setUsers((current) => [data.data.data, ...current]);
      setIsAddNewUser(false);
    }
  };
  //view single user
  const getSingleUserHandler = (id) => {
    setIsOnViewSingleUser(!isOnViewSingleUser);
    const userdata = users.find((client) => client?.id === id);
    setIsOnViewUser([userdata]);
  };
  // Warning for deleting user
  const deleteUserWarning = (id) => {
    setSelectedUserId(id);
    setIsOnDeletingUser(!isOnDeletingUser);
  };

  // Deleting user
  const deleteUser = () => {
    setIsDeletingUser(true);
    axios
      .delete(`${config.apiBaseUrl}/users/delete/${selectedUserId}`, {
        headers: { authorization: authUser.token },
      })
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setUsers(users.filter((user) => user.id !== selectedUserId));
        setIsOnDeletingUser(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingUser(false);
      });
  };

  // return user to update
  const returnUserToUpdate = (data) => {
    setSelectedUser(data);
    setIsUpdateInProcess(true);
    setIsAddNewUser(true);
  };

  // Search user
  const searchUsersHandler = (event) => {
    if (event.keyCode === 13) {
      if (!searchUserValue) {
        return toast.error("Please type either name or email", {
          theme: "colored",
        });
      }
      setIsSearchingUser(true);
      axios
        .get(`${config.apiBaseUrl}/users/search?name=${searchUserValue}`, {
          headers: { authorization: authUser.token },
        })
        .then((response) => {
          setUsers(response.data);
          setLastPage(0);
        })
        .catch((error) => {
          console.log(error);
          if (error.response?.data.error) {
            return toast.error(error.response.data.error, { theme: "colored" });
          }
          if (error.response?.data.message) {
            return toast.error(error.response.data.message, {
              theme: "colored",
            });
          } else {
            toast.error(
              "Something went wrong please try again later or reload the page",
              { theme: "colored" }
            );
          }
        })
        .finally(() => setIsSearchingUser(false));
    }
  };

  return (
    <MainLayout>
      {isOnDeletingUser && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this user"
          handleCancel={deleteUserWarning}
          handleContinue={deleteUser}
          isLoading={isDeletingUser}
        />
      )}
      {isOnViewSingleUser && (
        <Modal handleClicks={getSingleUserHandler}>
          <ViewAlert
            singleclients={isOnViewUser}
            title="View client"
            warningMessage="want see this client"
            handleView={getSingleUserHandler}
          />
        </Modal>
      )}
      {isAddNewUser && (
        <Modal handleClicks={handleModel}>
          <UserForm
            returnNewAddedUser={returnNewAddedUser}
            isUpdateInProcess={isUpdateInProcess}
            selectedUser={selectedUser}
            handleView={handleModel}
          />
        </Modal>
      )}
      <div className="row">
        <div className="col-6 col-md-6 col-sm-12 mb searchbox">
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Add user +"
            color="#FF9066"
            bcolor="white"
            onClick={onAddNewUserHandler}
          />
          <div className="group">
            <svg className="icon" aria-hidden="true" viewBox="0 0 24 24">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
            <input
              placeholder="Search house"
              type="search"
              className="input"
              onChange={(event) => setSearchUserValue(event.target.value)}
              onKeyDown={searchUsersHandler}
            />
            {isSearchingUser && <span>Loading...</span>}
          </div>
        </div>
      </div>

      <Box>
        <h4>Users</h4>
        {users.length ? (
          <Tables
            users={users}
            handleClickOnNext={getUsersListOnNextPage}
            handleClickOnPrev={getUsersListOnPrevPage}
            lastPage={lastPage}
            nextPage={nextPage}
            isPrevLoading={isPrevLoading}
            isNextLoading={isNextLoading}
            deleteUser={deleteUserWarning}
            updateUser={returnUserToUpdate}
            getSingleUserHandler={getSingleUserHandler}
          />
        ) : (
          <Loading />
        )}
      </Box>
    </MainLayout>
  );
};

export default Users;
