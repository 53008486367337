import React from "react";
import CustomDropDown from "../../../CustomDropDown/CustomDropDown";
import moment from "moment";
import Button from "../../../Button";

const ClientTable = ({
  clients,
  deleteClient,
  addPaymentToHouse,
  getSingleClientHandler,
  updateClient,
  removeClientToHouse,
  lastPage,
  nextPage,
  isNextLoading,
  isPrevLoading,
  handleClickOnNext,
  handleClickOnPrev,
}) => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>House</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Checkin date</th>
            <th>Checkout date</th>
            <th>Pay</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client.id}>
              <td>
                {client.fullName}
                {!client.Houses_Payment && (
                  <span className="pending-warning">Pending</span>
                )}
              </td>
              <td>{client.houseName}</td>
              <td>{client.email}</td>
              <td>{client.phone}</td>
              <td>{moment(client.inDate).format("yyyy-MM-DD")}</td>
              <td>
                {client?.outDate ? (
                  moment(client.outDate).format("yyyy-MM-DD")
                ) : (
                  <p>Not out</p>
                )}
              </td>
              <td>
                <CustomDropDown>
                  <button onClick={() => getSingleClientHandler(client.id)}>
                    View
                  </button>
                  <button onClick={() => updateClient(client, client.HouseId)}>
                    Edit
                  </button>
                  <button onClick={() => deleteClient(client.id)}>
                    Delete
                  </button>
                  <button onClick={() => addPaymentToHouse(client)}>
                    Add payment
                  </button>
                  <button onClick={() => removeClientToHouse(client.id)}>
                    Checkout
                  </button>
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-btn">
        {(nextPage > 1 || isPrevLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Prev"
            color="#FF9066"
            bcolor="white"
            isLoading={isPrevLoading}
            onClick={handleClickOnPrev}
          />
        )}
        {(nextPage < lastPage || isNextLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Next"
            color="#FF9066"
            bcolor="white"
            isLoading={isNextLoading}
            onClick={handleClickOnNext}
          />
        )}
      </div>
    </div>
  );
};

export default ClientTable;
