import React, { useContext, useEffect, useState } from "react";
import { Upload, message } from "antd";
import { CloudinaryContext, Transformation, Image } from "cloudinary-react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { MainContext } from "../../../middleware/Contexts";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../Button";
import config from "../../../configs/config";
// import "./PropertyForm.scss";

import { GrFormClose } from "react-icons/gr";

const PropertyForm = ({
  handleView,
  returnAddedWebProperty,
  propertyToUpdate,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const [propertyName, setPropertyName] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [houseRooms, setHouseRooms] = useState(0);
  const [propertyPrice, setPropertyPrice] = useState("");
  const [locationName, setLocationName] = useState("");
  const [roadNumber, setRoadNumber] = useState("");
  const [isSavingProperty, setIsSavingProperty] = useState(false);
  const [propertyId, setPropertyId] = useState("");

  const { authUser, setAuthUser } = useContext(MainContext);

  useEffect(() => {
    if (propertyToUpdate) {
      setPropertyName(propertyToUpdate.name);
      setPropertyType(propertyToUpdate.type);
      setHouseRooms(propertyToUpdate.houseRooms);
      setPropertyPrice(propertyToUpdate.price);
      setLocationName(propertyToUpdate.locationName);
      setRoadNumber(propertyToUpdate.roadNumber);
      setImageUrl(propertyToUpdate.imageLink);
      setPropertyId(propertyToUpdate.id);
    }
  }, []);

  const handleUpload = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setImageUrl(info.file.response.secure_url);
      setLoading(false);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      setLoading(false);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    setImageUrl("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "tia1ip8h");
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dvejxosqk/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      onSuccess(data);
    } catch (err) {
      onError(err);
    }
  };

  const clearForm = () => {
    setPropertyName("");
    setPropertyType("");
    setHouseRooms("");
    setPropertyPrice("");
    setLocationName("");
    setRoadNumber("");
    setImageUrl("");
    setPropertyId("");
  };

  // Save property
  const saveWebsiteProperty = (e) => {
    e.preventDefault();
    setIsSavingProperty(false);
    const data = {
      imageLink: imageUrl,
      name: propertyName,
      type: propertyType,
      houseRooms: houseRooms,
      price: propertyPrice,
      locationName: locationName,
      roadNumber: roadNumber,
    };

    axios
      .post(`${config.apiBaseUrl}/website-properties`, data, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        console.log(response);
        clearForm();

        returnAddedWebProperty(response);
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => setIsSavingProperty(false));
  };

  // Edit property
  const updateWebsiteProperty = (e) => {
    e.preventDefault();
    setIsSavingProperty(false);
    const data = {
      id: propertyId,
      imageLink: imageUrl,
      name: propertyName,
      type: propertyType,
      houseRooms: houseRooms,
      price: propertyPrice,
      locationName: locationName,
      roadNumber: roadNumber,
    };

    axios
      .put(`${config.apiBaseUrl}/website-properties`, data, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        console.log(response);
        clearForm();

        returnAddedWebProperty(response);
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => setIsSavingProperty(false));
  };

  return (
    <div>
      <form className="userform">
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <h2>Property form</h2>
        <div className="inputform">
          <div className="row">
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label>Select an Image</label>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={customRequest}
                onChange={handleUpload}
              >
                {imageUrl ? (
                  <CloudinaryContext cloudName="dvejxosqk">
                    <Image publicId={imageUrl}>
                      <Transformation width="150" height="150" crop="thumb" />
                    </Image>
                  </CloudinaryContext>
                ) : (
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
              <br />
              <div>
                <label>Property name</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter property name"
                  value={propertyName}
                  onChange={(event) => setPropertyName(event.target.value)}
                />
              </div>
              <div>
                <label>Select property type</label>
                <br />
                <select
                  onChange={(event) => setPropertyType(event.target.value)}
                  value={propertyType}
                >
                  <option value="">---- Select type ----</option>
                  <option value="Apartment">Apartment</option>
                  <option value="Office">Office</option>
                  <option value="Residential">Residential</option>
                </select>
              </div>
            </div>
            <div className="col-6 col-md-6 col-sm-12 mb">
              <div>
                <label>Property price</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter property price"
                  value={propertyPrice}
                  onChange={(event) => setPropertyPrice(event.target.value)}
                />
              </div>
              <div>
                <label>Location</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter a location of property"
                  value={locationName}
                  onChange={(event) => setLocationName(event.target.value)}
                />
              </div>
              <div>
                <label>Road number</label>
                <br />
                <input
                  type="text"
                  placeholder="EX: KN 01, St"
                  value={roadNumber}
                  onChange={(event) => setRoadNumber(event.target.value)}
                />
              </div>
              <div>
                <label>Room(s) number</label>
                <br />
                <input
                  type="number"
                  placeholder="How many number of room(s)"
                  value={houseRooms}
                  onChange={(event) => setHouseRooms(event.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        {propertyToUpdate ? (
          <Button
            width="30%"
            border="#FF9066 solid 1px "
            text="Edit"
            color="#FF9066"
            bcolor="white"
            className="col-2"
            onClick={updateWebsiteProperty}
            isLoading={isSavingProperty}
          />
        ) : (
          <Button
            width="30%"
            border="#FF9066 solid 1px "
            text="Save"
            color="#FF9066"
            bcolor="white"
            className="col-2"
            onClick={saveWebsiteProperty}
            isLoading={isSavingProperty}
          />
        )}
      </form>
    </div>
  );
};

export default PropertyForm;
