import React, { useContext, useEffect, useState } from "react";
import MainLayout from "../layout/MainLayout";
import Box from "../components/box/Box";
import Button from "../components/Button";
import { images } from "../assets";
import "../components/dashboard/profile/profile.scss";
import config from "../configs/config";
import { MainContext } from "../middleware/Contexts";
import axios from "axios";

const Profile = () => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [users, setUsers] = useState([]);

  // Getting list of users
  const getUsersList = () => {
    axios
      .get(`${config.apiBaseUrl}/users/list`, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        alert("Something went wrong! can't load list of users");
      })
      .finally(() => {});
  };
  useEffect(() => {
    getUsersList();
  }, []);

  let userData = [];

  for (let i = 0; i < users.length; i++) {
    if (users[i].id == authUser.id) {
      userData.push(users[i]);
    }
  }
  return (
    <MainLayout>
      <div className="row">
        <div className="col-6 col-md-6 col-sm-12 mb">
          <Box>
            <div className="profile">
              <img src={images.UserImage} alt="profile" />
            </div>
            {userData?.map((data) => (
              <div className="profile_content" key={data.id}>
                <p className="row">
                  <span className="title">Name: </span>
                  <span className="none">{data.fullName}</span>
                </p>
                <p className="row">
                  <span className="title">Gender:</span>
                  <span className="none">{data.gender}</span>
                </p>
                <p className="row">
                  <span className="title">Birth day: </span>
                  <span className="none">karera</span>
                </p>
                <p className="row">
                  <span className="title">Title: </span>
                  <span className="none">{data.role}</span>
                </p>
                <p className="row">
                  <span className="title">Email: </span>
                  <span className="none">{data.email}</span>
                </p>
                <p className="row">
                  <span className="title">Telephone:</span>
                  <span className="none">{data.phone}</span>
                </p>
                <p className="row">
                  <span className="title">Province:</span>
                  <span className="none">{data.province}</span>
                </p>
                <p className="row">
                  <span className="title">District:</span>
                  <span className="none">{data.district}</span>
                </p>
                <p className="row">
                  <span className="title">Sector:</span>
                  <span className="none">{data.sector}</span>
                </p>
              </div>
            ))}
            <Button
              width="7rem"
              border="#FF9066 solid 1.5px"
              text="Edit +"
              color="#FF9066"
              bcolor="white"
            />
          </Box>
        </div>
        <div className="col-6 col-md-6 col-sm-12 mb">
          <div className="check">
            <Box>
              <p>Profile</p>
            </Box>
          </div>
          <div className="check">
            <Box>
              <p>Profile</p>
            </Box>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Profile;
