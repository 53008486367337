import React, { useContext, useState } from "react";
import { MainContext } from "../../../middleware/Contexts";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import config from "../../../configs/config";
import Button from "../../Button";
import { moneyFormatter } from "../../../utils/MoneyFormatter";

const HouseTable = ({
  houses,
  getSingleHouseHandler,
  updateHouse,
  deleteHouse,
  getAllHousesHandler,
  lastPage,
  nextPage,
  isNextLoading,
  isPrevLoading,
  handleClickOnNext,
  handleClickOnPrev,
}) => {
  const { authUser, setAuthUser } = useContext(MainContext);

  const activateHouseHandler = (id) => {
    axios
      .post(
        `${config.apiBaseUrl}/houses/update-house`,
        { id, isActive: true },
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        getAllHousesHandler();
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => {});
  };
  const deactivateHouseHandler = (id) => {
    axios
      .post(
        `${config.apiBaseUrl}/houses/update-house`,
        { id, isActive: false },
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        getAllHousesHandler();
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => {});
  };
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>House Name</th>
            <th>Owner Name</th>
            <th>Phone</th>
            <th>Province</th>
            <th>District</th>
            <th>Sector</th>
            <th>Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {houses.map((house) => (
            <tr key={house.id}>
              <td>{house.name}</td>
              <td>{house.ownerName}</td>
              <td>{house.ownerPhone}</td>
              <td>{house.province}</td>
              <td>{house.district}</td>
              <td>{house.sector}</td>
              <td>{moneyFormatter(house.price)}</td>
              <td>
                <CustomDropDown>
                  <button onClick={() => getSingleHouseHandler(house.id)}>
                    View
                  </button>
                  <button onClick={() => updateHouse(house)}>Edit</button>
                  {house.isActive ? (
                    <button onClick={() => deactivateHouseHandler(house.id)}>
                      Deactivate
                    </button>
                  ) : (
                    <button onClick={() => activateHouseHandler(house.id)}>
                      Activate
                    </button>
                  )}
                  <button onClick={() => deleteHouse(house.id)}>Delete</button>
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-btn">
        {(nextPage > 1 || isPrevLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Prev"
            color="#FF9066"
            bcolor="white"
            isLoading={isPrevLoading}
            onClick={handleClickOnPrev}
          />
        )}
        {(nextPage < lastPage || isNextLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Next"
            color="#FF9066"
            bcolor="white"
            isLoading={isNextLoading}
            onClick={handleClickOnNext}
          />
        )}
      </div>
    </div>
  );
};

export default HouseTable;
