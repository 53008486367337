import { Title } from "chart.js";
import React, { useRef, forwardRef } from "react";
import { useReactToPrint } from "react-to-print";
import { AiFillPrinter } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import "../../CustomModal/singleview.scss";
import moment from "moment";
import { moneyFormatter } from "../../../utils/MoneyFormatter";

// import { useRef } from "react";
const Input = forwardRef((props, ref) => {
  return <input ref={ref} {...props} />;
});
const Alert = ({
  isLoading,
  handleView,
  handleContinue,
  title,
  singleoffice,
}) => {
  const printingRef = useRef();
  const hundlePrint = useReactToPrint({
    content: () => printingRef.current,
    documentTitle: "Sampro print",
    onafterprint: () => alert("print is ok"),
  });
  return (
    <div ref={printingRef} className="print">
      <div className="singleview-shadow">
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <div className="singleview-center">
          <div className="singleview-header">
            <h3>View Office</h3>
          </div>
          <div className="singleview-content">
            {singleoffice.map((office) => (
              <div key={office.id}>
                <div className="row">
                  <div className="col-6 col-md-6 col-sm-12 mb">
                    <p>
                      Office name: <span>{office.name}</span>
                    </p>
                    <p>
                      Office owner: <span>{office.ownerName}</span>
                    </p>
                    <p>
                      Birthdate:{" "}
                      <span>
                        {moment(office.birthDate).format("yyyy-MM-DD")}
                      </span>
                    </p>

                    <p>
                      Telephone: <span>{office.ownerPhone}</span>
                    </p>
                    <p>
                      Price: <span>{moneyFormatter(office.price)}</span>
                    </p>
                  </div>
                  <div className="col-6 col-md-6 col-sm-12 mb">
                    <p>
                      Province: <span>{office.province}</span>
                    </p>
                    <p>
                      District: <span> {office.district}</span>
                    </p>
                    <p>
                      Sector: <span>{office.sector}</span>
                    </p>
                    <p>
                      Description: <span>{office.description}</span>
                    </p>
                    <p>
                      Available:{" "}
                      <span
                        className={
                          office.isAvailable ? "av-gr-status" : "un-rd-status"
                        }
                      >
                        {office.isAvailable ? "Yes" : "No"}
                      </span>
                    </p>
                    <p>
                      Active:{" "}
                      <span
                        className={
                          office.isActive ? "av-gr-status" : "un-rd-status"
                        }
                      >
                        {office.isActive ? "Yes" : "No"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="icons">
        <AiFillPrinter onClick={() => hundlePrint()} className="myprint" />
      </div>
    </div>
  );
};

export default Alert;
