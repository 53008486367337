import React from "react";
import Box from "../../box/Box";
import "./website.scss";
const Website = () => {
  return (
    <div>
      <div className="website">
        <div className="card">
          <Box />
          <Box />
          <Box />
          <Box />
        </div>
      </div>
    </div>
  );
};

export default Website;
