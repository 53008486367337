import React from "react";
import CustomDropDown from "../../../CustomDropDown/CustomDropDown";
import { moneyFormatter } from "../../../../utils/MoneyFormatter";

const HouseTable = ({ houses, addClientToHouse, availableHouse }) => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>House Name</th>
            <th>Living rooms</th>
            <th>Rooms / Baths</th>
            <th>With a kitchen</th>
            <th>Description</th>
            <th>Price</th>
            <th>Available</th>
            <th>Checkin</th>
          </tr>
        </thead>
        <tbody>
          {houses?.map((house) => (
            <tr key={house.id}>
              <td>{house.name}</td>
              <td>{house.LivingRooms}</td>
              <td>
                {house.bedRooms} room(s) / {house.bathRooms} bath(s)
              </td>
              <td>{house.hasKitchen ? "Yes" : "No"}</td>
              <td>{house.description}</td>
              <td>{moneyFormatter(house.price)}</td>
              <td
                className={house.isAvailable ? "av-gr-status" : "un-rd-status"}
              >
                {house.isAvailable ? "Yes" : "No"}
              </td>
              <td>
                <CustomDropDown>
                  <button
                    onClick={() =>
                      addClientToHouse(house.id, house.ApartmentId)
                    }
                  >
                    Add client
                  </button>
                  <button onClick={() => availableHouse(house.id)}>
                    Make available
                  </button>
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HouseTable;
