import images from "./images";

const data = {
  user: {
    name: "Karera",
    img: images.userImg,
  },
  summary: [
    {
      title: "Bookings",
      subtitle: "Total bookings today",
      value: "$1.000",
      percent: 70,
    },
    {
      title: "Clients",
      subtitle: "Total clients today",
      value: "3000",
      percent: 49,
    },
    {
      title: "Revenue",
      subtitle: "Total revenue today",
      value: "$678",
      percent: 38,
    },
    {
      title: "Emails",
      subtitle: "Total emails today",
      value: "2345",
      percent: 55,
    },
    {
      title: "Apartment",
      subtitle: "Total bookings today",
      value: "$1.000",
      percent: 70,
    },
    {
      title: "Houses",
      subtitle: "Total clients today",
      value: "3000",
      percent: 49,
    },
    {
      title: "Offices",
      subtitle: "Total revenue today",
      value: "$678",
      percent: 38,
    },
  ],
  revenueSummary: {
    title: "Revenue",
    value: "$678",
    chartData: {
      labels: ["May", "Jun", "July", "Aug", "May", "Jun", "July", "Aug"],
      data: [300, 300, 280, 380, 200, 300, 280, 350],
    },
  },
  overall: [
    {
      value: "300K",
      title: "Bookings",
    },
    {
      value: "9.876K",
      title: "Clients",
    },
    {
      value: "1.234K",
      title: "Emails",
    },
    {
      value: "$5678",
      title: "Revenue",
    },
    {
      value: "300K",
      title: "Apartmnts",
    },
    {
      value: "9.876K",
      title: "Houses",
    },
    {
      value: "1.234K",
      title: "Offices",
    },
    {
      value: "$5678",
      title: "Services",
    },
  ],
  revenueByChannel: [
    {
      title: "Apartment",
      value: 70,
    },
    {
      title: "House",
      value: 40,
    },
    {
      title: "Offices",
      value: 60,
    },
    {
      title: "Services",
      value: 30,
    },
    {
      title: "Booking",
      value: 70,
    },
    {
      title: "Clients",
      value: 40,
    },
    {
      title: "Emails",
      value: 60,
    },
    {
      title: "Revenue",
      value: 30,
    },
  ],
  revenueByMonths: {
    labels: [
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
    ],
    data: [250, 200, 300, 280, 100, 220, 310, 190, 200, 120, 250, 350],
  },
};

export default data;
