import React from "react";
import "./Modal.scss";

const Modal = ({ children, handleClicks }) => {
  return (
    <>
      <div className="modal-container">
        <div className="modal-shadow" onClick={handleClicks}></div>
        <div className="modal-center">{children}</div>
      </div>
    </>
  );
};

export default Modal;
