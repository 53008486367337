import React from "react";
import "./Button.scss";

const Button = ({ color, bcolor, onClick, text, width, border, isLoading }) => {
  return (
    <button
      className="reusable-btn"
      style={{
        width: width,
        height: "2.5rem",
        fontSize: "16px",
        cursor: "pointer",
        borderRadius: "5px",
        border: border,
        margin: "0 0.4rem",
        color: color,
        backgroundColor: bcolor,
      }}
      onClick={onClick}
    >
      {isLoading ? <div className="on-loading"></div> : text}
    </button>
  );
};

export default Button;
