import React, { useState, useEffect, useContext } from "react";
import Button from "../../../Button";
import axios from "axios";
import { GrFormClose } from "react-icons/gr";
import config from "../../../../configs/config";
import { MainContext } from "../../../../middleware/Contexts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDate } from "../../../../utils/dateFormatterUtil"

const PaymentForm = ({
  returnNewAddedPayment,
  paymentToUpdate,
  ApartmentsClient,
  handleView,
  isUpdateInProcess
}) => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [lastPaidDate, setLastPaidDate] = useState("");
  const [nextPayDate, setNextPayDate] = useState("");
  const [paymentMethods, setPaymentMethods] = useState("");
  const [amount, setAmount] = useState("");
  const [isAddingNewPayment, setIsAddingNewPayment] = useState(false);

  const clearForm = () => {
    setLastPaidDate("");
    setNextPayDate("");
    setPaymentMethods("");
    setAmount("");
  };

  useEffect(() => {
    if (isUpdateInProcess) {
      setLastPaidDate(formatDate(paymentToUpdate.Apartments_Payment.lastPaidDate));
      setNextPayDate(formatDate(paymentToUpdate.Apartments_Payment.nextPayDate));
      setPaymentMethods(paymentToUpdate.Apartments_Payment.paymentMethods);
      setAmount(paymentToUpdate.Apartments_Payment.amount);
    } else {
      if (ApartmentsClient.Apartments_Payment) {

        const existingDate = new Date(ApartmentsClient.Apartments_Payment?.nextPayDate);
        const modifiedDate = new Date(ApartmentsClient.Apartments_Payment?.nextPayDate);

        setLastPaidDate(formatDate(ApartmentsClient.Apartments_Payment?.nextPayDate));
        setNextPayDate(formatDate(modifiedDate.setMonth(existingDate.getMonth() + 1)));
      }
      setAmount(ApartmentsClient.ApartmentsHouse.price);
    }
  }, [])


  const addNewPayment = (e) => {
    e.preventDefault();
    setIsAddingNewPayment(true);
    let data = null;
    if (isUpdateInProcess) {
      data = {
        id: paymentToUpdate.Apartments_Payment.id,
        ApartmentsClientId: paymentToUpdate.Apartments_Payment.ApartmentsClientId,
        lastPaidDate,
        nextPayDate,
        paymentMethods,
        amount,
      };
    } else {
      data = {
        ApartmentsClientId: ApartmentsClient.id,
        lastPaidDate,
        nextPayDate,
        paymentMethods,
        amount,
      };
    }

    const userUrl = isUpdateInProcess ? "/apts-finances/update" : "/apts-finances";

    axios
      .post(`${config.apiBaseUrl}${userUrl}`, data, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        clearForm();
        returnNewAddedPayment(response);
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => setIsAddingNewPayment(false));
  };

  return (
    <div>
      <form className="userform" onSubmit={addNewPayment}>
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <h3>{isUpdateInProcess ? 'Edit' : 'Add New'} Payment</h3>
        <div>
          <p><b>Client Name:</b> {isUpdateInProcess ? paymentToUpdate?.fullName : ApartmentsClient?.fullName}</p>
          <p><b>Client Phone:</b> {isUpdateInProcess ? paymentToUpdate?.phone : ApartmentsClient?.phone}</p>
        </div>
        <br />
        <div className="inputform">
          <div className="row">
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">Last Payment Date</label>
              <input
                type="date"
                placeholder="lastPaidDate"
                required
                onChange={(event) => setLastPaidDate(event.target.value)}
                value={lastPaidDate}
              />
              <label for="name">Payment method</label>

              <select
                type="text"
                value={paymentMethods}
                required
                onChange={(event) => setPaymentMethods(event.target.value)}
              >
                <option>Select paymentMethods</option>
                <option>Cash</option>
                <option>Mobile Money</option>
                <option>Check</option>
                <option>Bank</option>
              </select>
            </div>
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">Next Payment Date</label>
              <input
                type="date"
                placeholder="nextPayDate"
                required
                onChange={(event) => setNextPayDate(event.target.value)}
                value={nextPayDate}
              />

              <label for="name">Payment amount</label>
              <input
                type="text"
                value={amount}
                required
                placeholder="amount"
                onChange={(event) => setAmount(event.target.value)}
              />
            </div>{" "}
          </div>{" "}
        </div>
        <div className="form-button row ">
          {isUpdateInProcess ? (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Edit payment"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewPayment}
            />
          ) : (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Save & Add"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewPayment}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default PaymentForm;
