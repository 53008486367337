import { Title } from "chart.js";
import React, { useRef, forwardRef } from "react";
import { useReactToPrint } from "react-to-print";
import { AiFillPrinter } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import "../../CustomModal/singleview.scss";
import moment from "moment";
// import { useRef } from "react";
import { moneyFormatter } from "../../../utils/MoneyFormatter";

const Input = forwardRef((props, ref) => {
  return <input ref={ref} {...props} />;
});
const Alert = ({
  isLoading,
  handleView,
  handleContinue,
  title,
  singlehouse,
}) => {
  const printingRef = useRef();
  const hundlePrint = useReactToPrint({
    content: () => printingRef.current,
    documentTitle: "Sampro print",
    onafterprint: () => alert("print is ok"),
  });
  return (
    <div ref={printingRef} className="print">
      <div className="singleview-shadow">
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <div className="singleview-center">
          <div className="singleview-header">
            <h3>View house</h3>
          </div>
          <div className="singleview-content">
            {singlehouse.map((house) => (
              <div key={house.id}>
                <div className="row">
                  <div className="col-6 col-md-6 col-sm-12 mb">
                    <p>
                      House name: <span>{house.name}</span>
                    </p>
                    <p>
                      House owner: <span>{house.ownerName}</span>
                    </p>
                    <p>
                      Birthdate:{" "}
                      <span>
                        {moment(house.birthDate).format("yyyy-MM-DD")}
                      </span>
                    </p>
                    <p>
                      Telephone: <span>{house.ownerPhone}</span>
                    </p>
                    <p>
                      Province: <span>{house.province}</span>
                    </p>
                    <p>
                      District: <span>{house.district}</span>
                    </p>
                    <p>
                      Sector: <span>{house.sector}</span>
                    </p>{" "}
                  </div>
                  <div className="col-6 col-md-6 col-sm-12 mb">
                    <p>
                      Living rooms: <span>{house.LivingRooms}</span>
                    </p>
                    <p>
                      Bed rooms: <span>{house.bedRooms}</span>
                    </p>
                    <p>
                      Bath rooms: <span>{house.bathRooms}</span>
                    </p>
                    <p>
                      With Kitchen:{" "}
                      <span
                        className={
                          house.hasKitchen ? "av-gr-status" : "un-rd-status"
                        }
                      >
                        {house.hasKitchen ? "Yes" : "No"}
                      </span>
                    </p>
                    <p>
                      Price: <span>{moneyFormatter(house.price)}</span>
                    </p>
                    <p>
                      Description: <span>{house.description}</span>
                    </p>
                    <p>
                      Available:{" "}
                      <span
                        className={
                          house.isAvailable ? "av-gr-status" : "un-rd-status"
                        }
                      >
                        {house.isAvailable ? "Yes" : "No"}
                      </span>
                    </p>
                    <p>
                      Active:{" "}
                      <span
                        className={
                          house.isActive ? "av-gr-status" : "un-rd-status"
                        }
                      >
                        {house.isActive ? "Yes" : "No"}
                      </span>
                    </p>
                  </div>{" "}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="icons">
        <AiFillPrinter onClick={() => hundlePrint()} className="myprint" />
      </div>
    </div>
  );
};

export default Alert;
