import React from "react";
import moment from "moment";
import "./PaymentDetails.scss";

const PaymentDetails = ({ details }) => {
  return (
    <div>
      <h3 className="py-details-hd">Client Payment Details</h3>
      <div>
        <p>
          <b>Name: </b>
          {details.fullName}
        </p>
        <p>
          <b>Gender: </b>
          {details.gender}
        </p>
        <p>
          <b>Email: </b>
          {details.email}
        </p>
        <p>
          <b>Phone: </b>
          {details.phone}
        </p>
        <p>
          <b>Date of birth: </b>
          {moment(details.birthDate).format("YYYY-MM-DD")}
        </p>
        <p>
          <b>Natinaol ID: </b>
          {details.nId}
        </p>
        <p>
          <b>Country: </b>
          {details.country}
        </p>
        <p>
          <b>District: </b>
          {details.district}
        </p>
        <p>
          <b>Province: </b>
          {details.province}
        </p>
        <p>
          <b>Sector: </b>
          {details.sector}
        </p>
      </div>
      <div>
        <h3 className="py-details-hd">Check in details</h3>
        <p>
          <b>In Date: </b>
          {moment(details.CheckInOuts[0].inDate).format("YYYY-MM-DD")}
        </p>
        <p>
          <b>Out Date: </b>
          {moment(details.CheckInOuts[0].outDate).format("YYYY-MM-DD")}
        </p>
        <p>
          <b>Property type: </b>
          {details.CheckInOuts[0].propertyType}
        </p>
        <p>
          <b>Property name: </b>
          {details.CheckInOuts[0].propertyName}
        </p>
        <p>
          <b>House/office name: </b>
          {details.CheckInOuts[0].houseName}
        </p>
      </div>
      <h3 className="py-details-hd">Payments</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Last paid date</th>
            <th>Next pay date</th>
            <th>Payment method</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {details.Payments.map((item) => (
            <tr key={item.id}>
              <td>{moment(item.lastPaidDate).format("yyyy-MM-DD")}</td>
              <td>{moment(item.nextPayDate).format("yyyy-MM-DD")}</td>
              <td>{item.paymentMethods}</td>
              <td>{item.amount}</td>
            </tr>
          ))}
          {details.PrevPayments.map((item) => (
            <tr key={item.id}>
              <td>{moment(item.lastPaidDate).format("yyyy-MM-DD")}</td>
              <td>{moment(item.nextPayDate).format("yyyy-MM-DD")}</td>
              <td>{item.paymentMethods}</td>
              <td>{item.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentDetails;
