import React from "react";
import CustomDropDown from "../../../CustomDropDown/CustomDropDown";
import moment from "moment";
import Button from "../../../Button";
import { moneyFormatter } from "../../../../utils/MoneyFormatter";

const HouseNotPayedTable = ({
  clients,
  addPaymentToHouse,
  getSingleClientHandler,
  updatePayment,
  deleteClient,
  lastPage,
  nextPage,
  isNextLoading,
  isPrevLoading,
  handleClickOnNext,
  handleClickOnPrev,
}) => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>House</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Last pay</th>
            <th>Next pay</th>
            <th>Amount</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {clients?.map((client) => (
            <tr key={client?.id}>
              <td>{client?.fullName}</td>
              <td>{client?.houseName}</td>
              <td>{client?.phone}</td>
              <td>{client?.email}</td>
              <td>
                {moment(client?.Houses_Payment?.lastPaidDate).format(
                  "yyyy-MM-DD"
                )}
              </td>
              <td>
                {moment(client?.Houses_Payment?.nextPayDate).format(
                  "yyyy-MM-DD"
                )}
              </td>
              <td>{moneyFormatter(client?.Houses_Payment?.amount)}</td>
              <td>
                <CustomDropDown>
                  <button onClick={() => getSingleClientHandler(client.id)}>
                    View
                  </button>
                  <button onClick={() => updatePayment(client)}>Edit</button>
                  <button onClick={() => deleteClient(client.id)}>
                    Delete
                  </button>
                  <button onClick={() => addPaymentToHouse(client)}>
                    Add Payment
                  </button>
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-btn">
        {(nextPage > 1 || isPrevLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Prev"
            color="#FF9066"
            bcolor="white"
            isLoading={isPrevLoading}
            onClick={handleClickOnPrev}
          />
        )}
        {(nextPage < lastPage || isNextLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Next"
            color="#FF9066"
            bcolor="white"
            isLoading={isNextLoading}
            onClick={handleClickOnNext}
          />
        )}
      </div>
    </div>
  );
};

export default HouseNotPayedTable;
