import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../configs/config";
import "./forgetpassword.scss";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confimNewPassword, setConfimNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const restPasswordHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(`${config.apiBaseUrl}/users/restPassword`, {
        email,
        resetPassword,
        newPassword,
        confimNewPassword,
      })
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        navigate("/");
      })
      .catch((error) => {
        if (error.response.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
        if (error.response.data.errors.length) {
          return toast.error(error.response.data.errors[0].msg, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="forgetpass">
      <div className="password-page">
        <div className="form">
          <h2>Provide your email</h2>
          <form className="password-form" onSubmit={restPasswordHandler}>
            <input
              type="text"
              placeholder="Rest code send"
              required
              onChange={(event) => setResetPassword(event.target.value)}
            />
            <input
              type="email"
              placeholder="Your email.."
              required
              onChange={(event) => setEmail(event.target.value)}
            />
            <input
              type="password"
              placeholder="New password"
              onChange={(event) => setNewPassword(event.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm new password"
              onChange={(event) => setConfimNewPassword(event.target.value)}
            />
            <button type="submit">
              {isLoading ? <div className="on-loading"></div> : "Send"}
            </button>
            <p className="message">
              Have an account? <a href="/">Back to login page</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
