import React, { useContext, useState, useEffect } from "react";
import "./overall-list.scss";
import config from "../../configs/config";
import { data } from "../../constants";
import axios from "axios";
import { MainContext } from "../../middleware/Contexts";

const icons = [
  <i className="bx bx-receipt"></i>,
  <i className="bx bx-user"></i>,
  <i className="bx bx-cube"></i>,
  <i className="bx bx-money"></i>,
  <i className="bx bx-arch" />,
  <i className="bx bxs-home-smile"></i>,
  <i className="bx bx-cube"></i>,
  <i className="bx bx-planet"></i>,
];

const OverallList = () => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [property, setProperty] = useState("");
  const [booking, setBooking] = useState("");
  const [emails, setEmails] = useState("");
  const [clients, setClients] = useState("");
  const [revenue, setRevenue] = useState("");
  const [isLoadingProperty, setIsLoadingProperty] = useState(false);

  // Get total nbr of Propertys
  const getTotalProperty = () => {
    setIsLoadingProperty(true);
    axios
      .get(`${config.apiBaseUrl}/dashboard/count-properties `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setProperty(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingProperty(false);
      });
  };

  // Get total nbr of total-bookings
  const getTotalBookings = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/total-bookings `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setBooking(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  // Get total nbr of total-setEmails
  const getTotalEmails = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/total-contact-us `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setEmails(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const getTotalRevenue = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/count-revenue `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setRevenue(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const getTotalClients = () => {
    axios
      .get(`${config.apiBaseUrl}/dashboard/count-participants  `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setClients(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  useEffect(() => {
    getTotalProperty();
    getTotalBookings();
    getTotalEmails();
    getTotalRevenue();
    getTotalClients();
  }, []);

  let client =
    clients.totalClientsOfApartments +
    clients.totalClientsOfHouses +
    clients.totalClientsOfOffices;
  let allRevenue =
    revenue.totalApartmentsPayments +
    revenue.totalHousesPayments +
    revenue.totalOfficesPayments;
  const formatter = new Intl.NumberFormat("en-rw", {
    style: "currency",
    currency: "rwf",
  });

  return (
    <ul className="overall-list">
      <li className="overall-list__item" key={`overall-${4}`}>
        <div className="overall-list__item__icon">{icons[4]}</div>
        <div className="overall-list__item__info">
          <div className="title">{property.totalApartment}</div>
          <span>Apartment</span>
        </div>
      </li>
      <li className="overall-list__item" key={`overall-${56}`}>
        <div className="overall-list__item__icon">{icons[5]}</div>
        <div className="overall-list__item__info">
          <div className="title">{property.totalHousesOfApartment}</div>
          <span>Ap(House)</span>
        </div>
      </li>
      <li className="overall-list__item" key={`overall-${5}`}>
        <div className="overall-list__item__icon">{icons[5]}</div>
        <div className="overall-list__item__info">
          <div className="title">{property.totalHouses}</div>
          <span>Houses</span>
        </div>
      </li>
      <li className="overall-list__item" key={`overall-${2}`}>
        <div className="overall-list__item__icon">{icons[2]}</div>
        <div className="overall-list__item__info">
          <div className="title">{property.totalOffices}</div>
          <span>Offices</span>
        </div>
      </li>
      <li className="overall-list__item" key={`overall-${22}`}>
        <div className="overall-list__item__icon">{icons[1]}</div>
        <div className="overall-list__item__info">
          <div className="title">{booking}</div>
          <span>Bookings</span>
        </div>
      </li>
      <li className="overall-list__item" key={`overall-${12}`}>
        <div className="overall-list__item__icon">{icons[0]}</div>
        <div className="overall-list__item__info">
          <div className="title">{emails}</div>
          <span>Emails</span>
        </div>
      </li>
      <li className="overall-list__item" key={`overall-${222}`}>
        <div className="overall-list__item__icon">{icons[1]}</div>
        <div className="overall-list__item__info">
          <div className="title">{client}</div>
          <span>Clients</span>
        </div>
      </li>
      <li className="overall-list__item" key={`overall-${202}`}>
        <div className="overall-list__item__icon">{icons[3]}</div>
        <div className="overall-list__item__info">
          <div className="title">{formatter.format(allRevenue)}</div>
          <span>Revenue</span>
        </div>
      </li>
    </ul>
  );
};

export default OverallList;
