import React, { useState, useEffect, useContext } from "react";
import MainLayout from "../layout/MainLayout";
import Box from "../components/box/Box";
import Button from "../components/Button";
import Alert from "../components/CustomAlert/Alert";
import { toast } from "react-toastify";
import config from "../configs/config";
import Loading from "../components/Loading";
import Modal from "../components/CustomModal/Modal";
import SingleView from "../components/dashboard/booking/ViewAlert";
import BookingTable from "../components/dashboard/booking/BookingTable";
import axios from "axios";
import { MainContext } from "../middleware/Contexts";

const Booked = () => {
  const { authUser, setAuthUser } = useContext(MainContext);
  const [bookings, setBookings] = useState([]);
  const [isOnViewSingleBooking, setIsOnViewSingleBooking] = useState(false);
  const [isOnViewBooking, setIsOnViewBooking] = useState([]);

  const fetchBookings = async () => {
    const response = await axios.get(`${config.apiBaseUrl}/bookings`, {
      headers: { authorization: authUser.token },
    });
    return setBookings(response.data);
  };
  useEffect(() => {
    fetchBookings();
    setTimeout(4000);
  }, []);

  const getSingleBookingHandler = (id) => {
    setIsOnViewSingleBooking(!isOnViewSingleBooking);
    const bookingData = bookings.find((booking) => booking?.id === id);
    setIsOnViewBooking([bookingData]);
  };
  // Deleting booking
  const deleteBookingWarning = (id) => {
    setIsOnDeletingBooking(!isOnDeletingBooking);
    setIsOnViewSingleBooking(isOnViewSingleBooking);
    setSelectedBookingId(id);
  };
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [isOnDeletingBooking, setIsOnDeletingBooking] = useState(false);
  const [isDeletingBooking, setIsDeletingBooking] = useState(false);
  // const [selectedBookingId, setSelectedBookingId] = useState(null);

  const deleteBooking = () => {
    setIsDeletingBooking(true);
    axios
      .delete(`${config.apiBaseUrl}/bookings/${selectedBookingId}`, {
        headers: { authorization: authUser.token },
      })
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setBookings(
          bookings.filter((booking) => booking.id !== selectedBookingId)
        );
        setIsOnDeletingBooking(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingBooking(false);
      });
  };
  return (
    <MainLayout>
      {/* Pop up warning while deleting */}
      {isOnDeletingBooking && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this client"
          handleCancel={deleteBookingWarning}
          handleContinue={deleteBooking}
          isLoading={isDeletingBooking}
        />
      )}
      {isOnViewSingleBooking && (
        <Modal handleClicks={getSingleBookingHandler}>
          <SingleView
            singleclients={isOnViewBooking}
            title="View client Booked"
            handleView={getSingleBookingHandler}
          />
        </Modal>
      )}

      <Box>
        <h3>Bookings</h3>
        {bookings?.length ? (
          <BookingTable
            bookings={bookings}
            deleteBooking={deleteBookingWarning}
            getSingleBookingHandler={getSingleBookingHandler}
          />
        ) : (
          <Loading />
        )}
      </Box>
    </MainLayout>
  );
};

export default Booked;
