import React, { useContext } from "react";
import config from "../../../configs/config";
import { MainContext } from "../../../middleware/Contexts";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Button from "../../Button";
import { moneyFormatter } from "../../../utils/MoneyFormatter";

const OfficeTable = ({
  offices,
  getSingleOfficeHandler,
  updateOffice,
  deleteOffice,
  getAllOfficesHandler,
  lastPage,
  nextPage,
  isNextLoading,
  isPrevLoading,
  handleClickOnNext,
  handleClickOnPrev,
}) => {
  const { authUser, setAuthUser } = useContext(MainContext);

  const activateOfficeHandler = (id) => {
    axios
      .post(
        `${config.apiBaseUrl}/offices/update-office`,
        { id, isActive: true },
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        getAllOfficesHandler();
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => {});
  };
  const deactivateOfficeHandler = (id) => {
    axios
      .post(
        `${config.apiBaseUrl}/offices/update-office`,
        { id, isActive: false },
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        getAllOfficesHandler();
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => {});
  };
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Office Name</th>
            <th>Owner Name</th>
            <th>Phone</th>
            <th>Province</th>
            <th>District</th>
            <th>Sector</th>
            <th>Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {offices.map((office) => (
            <tr key={office.id}>
              <td>{office.name}</td>
              <td>{office.ownerName}</td>
              <td>{office.ownerPhone}</td>
              <td>{office.province}</td>
              <td>{office.district}</td>
              <td>{office.sector}</td>
              <td>{moneyFormatter(office.price)}</td>
              <td>
                <CustomDropDown>
                  <button onClick={() => getSingleOfficeHandler(office.id)}>
                    View
                  </button>
                  <button onClick={() => updateOffice(office)}>Edit</button>
                  {office.isActive ? (
                    <button onClick={() => deactivateOfficeHandler(office.id)}>
                      Deactivate
                    </button>
                  ) : (
                    <button onClick={() => activateOfficeHandler(office.id)}>
                      Activate
                    </button>
                  )}
                  <button onClick={() => deleteOffice(office.id)}>
                    Delete
                  </button>
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-btn">
        {(nextPage > 1 || isPrevLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Prev"
            color="#FF9066"
            bcolor="white"
            isLoading={isPrevLoading}
            onClick={handleClickOnPrev}
          />
        )}
        {(nextPage < lastPage || isNextLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Next"
            color="#FF9066"
            bcolor="white"
            isLoading={isNextLoading}
            onClick={handleClickOnNext}
          />
        )}
      </div>
    </div>
  );
};

export default OfficeTable;
