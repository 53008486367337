import axios from "axios";
import { React, useContext, useEffect, useState } from "react";
import config from "../../../configs/config.js";
import { MainContext } from "../../../middleware/Contexts.js";
import Button from "../../Button.js";
import "../../form/form.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrFormClose } from "react-icons/gr";
export const ApartmentHouseForm = ({
  returnNewAddedHouse,
  isUpdateHouseInProcess,
  houseToUpdate,
  handleView,
  selectedApartment
}) => {
  const { authUser, setAuthUser } = useContext(MainContext);

  const [name, setName] = useState("");
  const [isLoadingApartments, setIsLoadingApartments] = useState(false);
  const [apartments, setApartments] = useState([]);
  const [LivingRooms, setLivingRooms] = useState("");
  const [ApartmentId, setApartmentId] = useState("");
  const [bedRooms, setBedRooms] = useState("");
  const [bathRooms, setBathRooms] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [hasKitchen, setHasKitchen] = useState("");
  const [isAddingNewApartmentHouse, setIsAddingNewApartmentHouse] =
    useState(false);
  const [isLoadingApartmentHouses, setIsLoadingApartmentHouses] =
    useState(false);
  const clearForm = () => {
    setName("");
    setApartmentId("");
    setLivingRooms("");
    setBedRooms("");
    setBathRooms("");
    setPrice("");
    setDescription("");
    setHasKitchen("");
  };
  useEffect(() => {
    if (isUpdateHouseInProcess) {
      setName(houseToUpdate.name);
      setLivingRooms(houseToUpdate.LivingRooms);
      setBedRooms(houseToUpdate.bedRooms);
      setBathRooms(houseToUpdate.bathRooms);
      setPrice(houseToUpdate.price);
      setDescription(houseToUpdate.description);
      setHasKitchen(houseToUpdate.hasKitchen);
      setApartmentId(houseToUpdate.ApartmentId);
    } else {
      setApartmentId(selectedApartment.id);
    }
  }, []);
  // Get list of Apartment
  const getAllApartmentsHandler = () => {
    setIsLoadingApartments(true);
    axios
      .get(`${config.apiBaseUrl}/apartments/get-all?page=1&limit=10 `, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        setApartments(response.data.data);
        // setLastPage(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setIsPrevLoading(false);
        // setIsNextLoading(false);
        setIsLoadingApartments(false);
      });
  };
  useEffect(() => {
    getAllApartmentsHandler();
  }, []);

  //add a house
  const addNewApartmentHouse = (e) => {
    e.preventDefault();
    setIsAddingNewApartmentHouse(true);
    let data = null;
    if (isUpdateHouseInProcess) {
      data = {
        id: houseToUpdate.id,
        ApartmentId,
        name,
        LivingRooms,
        bedRooms,
        bathRooms,
        price,
        description,
        hasKitchen,
      };
    } else {
      data = {
        name,
        ApartmentId,
        LivingRooms,
        bedRooms,
        bathRooms,
        price,
        description,
        hasKitchen,
      };
    }

    const userUrl = isUpdateHouseInProcess
      ? "/apartments/update-house "
      : "/apartments/add-new-house";

    axios
      .post(`${config.apiBaseUrl}${userUrl}`, data, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        clearForm();
        returnNewAddedHouse(response);
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, { theme: "colored" });
        }
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.errors?.length) {
          toast.error(error.response.data.errors[0].msg, { theme: "colored" });
        }
      })
      .finally(() => setIsAddingNewApartmentHouse(false));
  };

  return (
    <>
      <form className="userform" onSubmit={addNewApartmentHouse}>
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <h3>{isUpdateHouseInProcess ? 'Edit House of Apartment' : 'Add House to Apartment'}</h3>
        <div className="inputform">
          <div className="row">
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">House Name</label>
              <input
                type="text"
                value={name}
                required
                placeholder="Name a house"
                onChange={(event) => setName(event.target.value)}
              />
              <label for="name">House Price</label>
              <input
                type="number"
                required
                value={price}
                onChange={(event) => setPrice(event.target.value)}
                placeholder="Price"
              />
              <label for="name">Select Apartment House Belongs to:</label>
              <select
                disabled={!isUpdateHouseInProcess}
                type="text"
                value={ApartmentId}
                required
                placeholder="house name"
                onChange={(event) => setApartmentId(event.target.value)}
              >
                <option value="">----- select apartment -----</option>
                {apartments.map((apartment) => (
                  <option key={apartment.id} value={apartment.id}>
                    {apartment.name}
                  </option>
                ))}
              </select>
              <label for="name">Number of Livingrooms in House</label>

              <input
                type="number"
                value={LivingRooms}
                required
                placeholder="Living rooms"
                onChange={(event) => setLivingRooms(event.target.value)}
              />
            </div>
            <div className="col-6 col-md-6 col-sm-12 mb">
              <label for="name">Number of bedrooms in House</label>

              <input
                type="number"
                required
                value={bedRooms}
                onChange={(event) => setBedRooms(event.target.value)}
                placeholder="Bedrooms"
              />
              <label for="name">Number of bathrooms in House</label>

              <input
                type="number"
                value={bathRooms}
                required
                placeholder="Bathrooms"
                onChange={(event) => setBathRooms(event.target.value)}
              />
              <label for="name">Does this house have a kitchen</label>

              <select
                type="text"
                value={hasKitchen}
                required
                placeholder="House has kitchen"
                onChange={(event) => setHasKitchen(event.target.value)}
              >
                <option value="">----- Select option -----</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <label for="name">Additional comment</label>

              <input
                type="text"
                value={description}
                placeholder="Description"
                onChange={(event) => setDescription(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-button row ">
          {isUpdateHouseInProcess ? (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Edit house"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewApartmentHouse}
            />
          ) : (
            <Button
              width="90%"
              border="#FF9066 solid 1px "
              text="Save & Add"
              color="#FF9066"
              bcolor="white"
              className="col-2"
              isLoading={isAddingNewApartmentHouse}
            />
          )}
        </div>
      </form>
    </>
  );
};

export default ApartmentHouseForm;
