import React, { useState, useEffect, useContext } from "react";
import MainLayout from "../layout/MainLayout";
import Box from "../components/box/Box";
import { MainContext } from "../middleware/Contexts";
import axios from "axios";
import OverDueClientTable from "../components/dashboard/finance/apartment/OverDueClientTable";
import config from "../configs/config";
import Table from "../components/dashboard/finance/apartment/HouseTable";
import HouseNotPayedTable from "../components/dashboard/finance/apartment/HouseNotPayedTable";
import Modal from "../components/CustomModal/Modal";
import CheckoutForm from "../components/dashboard/finance/apartment/CheckoutForm";
import ViewClient from "../components/dashboard/finance/apartment/ViewAlert";
import ClientForm from "../components/dashboard/finance/apartment/ClientForm";
import PaymentForm from "../components/dashboard/finance/apartment/PaymentForm";
import PayedClientTable from "../components/dashboard/finance/apartment/PayedClientTable";
import Alert from "../components/CustomAlert/Alert";
import AvailableAlert from "../components/CustomAlert/AvailableAlert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { toast } from "react-toastify";
import "react-tabs/style/react-tabs.css";
import Button from "../components/Button";
import Loading from "../components/Loading";

const FinanceApartment = () => {
  const [isLoadingApartments, setIsLoadingApartments] = useState(false);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [isLoadingPaidClients, setIsLoadingPaidClients] = useState(false);
  const [isLoadingOverdueClients, setIsLoadingOverdueClients] = useState(false);
  const [apartmets, setApartments] = useState([]);
  const { authUser, setAuthUser } = useContext(MainContext);
  const [isAddNewClient, setIsAddNewClient] = useState(false);
  const [clientToUpdate, setClientToUpdate] = useState(null);
  const [isOnUpdateProcess, setIsOnUpdateProcess] = useState(false);
  const [addApartmentId, setAddApartmentId] = useState(null);
  const [overdueClients, setOverdueClients] = useState([]);
  const [addApartmentHouseId, setAddApartmentHouseId] = useState(null);
  const [houseClients, setHouseClients] = useState([]);
  const [isOnViewSingleClient, setIsOnViewSingleClient] = useState(false);
  const [singleClientData, setSingleClientData] = useState(null);
  const [addApartmentClient, setAddApartmentClient] = useState(null);
  const [isAddNewPayment, setIsAddNewPayment] = useState(false);
  const [payedClients, setPayedClients] = useState([]);
  const [isOnDeletingClient, setIsOnDeletingClient] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [isDeletingClient, setIsDeletingClient] = useState(false);
  const [isPutNewCheckout, setIsPutNewCheckout] = useState(false);
  const [checkoutClientId, setCheckoutClientId] = useState(false);
  const [selectedHouseId, setSelectedHouseId] = useState(null);
  const [isOnHouseAvailable, setIsOnHouseAvailable] = useState(false);
  const [isHouseAvailable, setIsHouseAvailable] = useState(false);
  const [aptClientValueToSearch, setAptClientValueToSearch] = useState("");
  const [isSearchingAptClient, setIsSearchingAptClient] = useState(false);

  const [dateRangeFom, setDateRangeFom] = useState("");
  const [dateRangeTo, setDateRangeTo] = useState("");
  const [isSearchingInRange, setIsSearchingInRange] = useState(false);

  const [clientPaymentToUpdate, setClientPaymentToUpdate] = useState(null);
  const [isUpdateClientPy, setIsUpdateClientPy] = useState(false);

  const [housesOfApartment, setHousesOfApartment] = useState(null);

  const [lastPageClient, setLastPageClient] = useState(0);
  const [nextPageClient, setNextPageClient] = useState(1);
  const [isNextLoadingClient, setIsNextLoadingClient] = useState(false);
  const [isPrevLoadingClient, setIsPrevLoadingClient] = useState(false);
  const [lastPagePaid, setLastPagePaid] = useState(0);
  const [nextPagePaid, setNextPagePaid] = useState(1);
  const [isNextLoadingPaid, setIsNextLoadingPaid] = useState(false);
  const [isPrevLoadingPaid, setIsPrevLoadingPaid] = useState(false);
  const [lastPageOverdue, setLastPageOverdue] = useState(0);
  const [nextPageOverdue, setNextPageOverdue] = useState(1);
  const [isNextLoadingOverdue, setIsNextLoadingOverdue] = useState(false);
  const [isPrevLoadingOverdue, setIsPrevLoadingOverdue] = useState(false);
  const [lastPageApt, setLastPageApt] = useState(0);
  const [nextPageApt, setNextPageApt] = useState(1);
  const [isNextLoadingApt, setIsNextLoadingApt] = useState(false);
  const [isPrevLoadingApt, setIsPrevLoadingApt] = useState(false);

  const getAllApartmentsHandler = () => {
    setIsLoadingApartments(true);
    axios
      .get(
        `${config.apiBaseUrl}/apartments/get-all?page=${nextPageApt}&limit=10`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        setApartments(response.data.data);

        setLastPageApt(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoadingApt(false);
        setIsNextLoadingApt(false);
        setIsLoadingApartments(false);
      });
  };
  useEffect(() => {
    getAllApartmentsHandler();
  }, []);

  useEffect(() => {
    if (lastPageApt) getAllApartmentsHandler();
  }, [nextPageApt]);

  const getAptsListOnNextPage = () => {
    setIsNextLoadingApt(true);
    setNextPageApt(nextPageApt + 1);
  };
  const getAptsListOnPrevPage = () => {
    setIsPrevLoadingApt(true);
    setNextPageApt(nextPageApt - 1);
  };

  useEffect(() => {
    if (apartmets.length) {
      setHousesOfApartment(apartmets[0]);
    }
  }, [apartmets]);

  const grabSelectedApartment = (client) => {
    setHousesOfApartment(client);
  };

  //List of all clients
  const getAllClientsHandler = () => {
    setIsLoadingClients(true);
    axios
      .get(
        `${config.apiBaseUrl}/apts-clients/get-list?page=${nextPageClient}&limit=10`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        setHouseClients(response.data.data);
        setLastPageClient(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoadingClient(false);
        setIsNextLoadingClient(false);
        setIsLoadingClients(false);
      });
  };
  useEffect(() => {
    getAllClientsHandler();
  }, []);

  //List of all PayedClients
  const getAllPayedClientsHandler = () => {
    setIsLoadingPaidClients(true);
    axios
      .get(
        `${config.apiBaseUrl}/apts-finances/paid-report?page=${nextPagePaid}&limit=10`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        setPayedClients(response.data.data);
        setLastPagePaid(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoadingPaid(false);
        setIsNextLoadingPaid(false);
        setIsLoadingPaidClients(false);
      });
  };
  useEffect(() => {
    getAllPayedClientsHandler();
  }, []);

  //List of all OverdueClients
  const getAllOverdueClientsHandler = () => {
    setIsLoadingOverdueClients(true);
    axios
      .get(
        `${config.apiBaseUrl}/apts-finances/overdue-report?&page=${nextPageOverdue}&limit=10`,
        {
          headers: { authorization: authUser.token },
        }
      )
      .then((response) => {
        setOverdueClients(response.data.data);
        setLastPageOverdue(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPrevLoadingOverdue(false);
        setIsNextLoadingOverdue(false);
        setIsLoadingOverdueClients(false);
      });
  };
  useEffect(() => {
    getAllOverdueClientsHandler();
  }, []);

  useEffect(() => {
    if (lastPageClient) getAllClientsHandler();
  }, [nextPageClient]);
  useEffect(() => {
    if (lastPagePaid) getAllPayedClientsHandler();
  }, [nextPagePaid]);
  useEffect(() => {
    if (lastPageOverdue) getAllOverdueClientsHandler();
  }, [nextPageOverdue]);

  // Getting users on the next page
  const getClientsListOnNextPage = () => {
    setIsNextLoadingClient(true);
    setNextPageClient(nextPageClient + 1);
  };
  const getClientsListOnPrevPage = () => {
    setIsPrevLoadingClient(true);
    setNextPageClient(nextPageClient - 1);
  };
  const getPaidListOnNextPage = () => {
    setIsNextLoadingPaid(true);
    setNextPagePaid(nextPagePaid + 1);
  };
  const getPaidListOnPrevPage = () => {
    setIsPrevLoadingPaid(true);
    setNextPagePaid(nextPagePaid - 1);
  };
  const getOverdueListOnNextPage = () => {
    setIsNextLoadingOverdue(true);
    setNextPageOverdue(nextPageOverdue + 1);
  };
  const getOverdueListOnPrevPage = () => {
    setIsPrevLoadingOverdue(true);
    setNextPageOverdue(nextPageOverdue - 1);
  };

  //adding new client
  const returnNewAddedClient = (data) => {
    setTimeout(() => {
      onAddNewClientHandler();
    }, 800);
    getAllClientsHandler();
    getAllApartmentsHandler();
  };

  //update client
  const updateClient = (client, houseid, apartmentid) => {
    setAddApartmentId(apartmentid);
    setAddApartmentHouseId(houseid);
    setClientToUpdate(client);
    setIsOnUpdateProcess(true);
    setIsAddNewClient(!isAddNewClient);
  };
  //adding new Payment
  const returnNewAddedPayment = (data) => {
    setTimeout(() => {
      onAddNewPaymentHandler();
    }, 800);
    getAllOverdueClientsHandler();
    getAllPayedClientsHandler();
  };

  // Open and Close modal for adding new house
  const onAddNewClientHandler = (id, apartmentid) => {
    setAddApartmentId(apartmentid);
    setAddApartmentHouseId(id);
    setIsAddNewClient(!isAddNewClient);
    setIsOnUpdateProcess(false);
  };

  // Open and Close modal for adding Payment
  const onAddNewPaymentHandler = (client) => {
    setIsUpdateClientPy(false);
    setIsOnUpdateProcess(false);
    setAddApartmentClient(client);
    setIsAddNewPayment(!isAddNewPayment);
  };

  // Open and Close modal for checkout
  const onCheckoutHandler = (id) => {
    setCheckoutClientId(id);
    setIsPutNewCheckout(!isPutNewCheckout);
  };

  //checkout client
  const returnCheckoutClient = (data) => {
    setPayedClients((current) => [data.data.data, ...current]);
    // setApartments((current) => [data.data.data, ...current]);
    setTimeout(() => {
      onCheckoutHandler();
    }, 800);
  };

  //View single client
  const getSingleClientHandler = (id) => {
    setIsOnViewSingleClient(!isOnViewSingleClient);
    const data = houseClients.find((client) => client?.id == id);
    if (data) {
      setSingleClientData({
        ApartmentId: data.ApartmentId,
        ApartmentsHouseId: data.ApartmentsHouseId,
        birthDate: data.birthDate,
        country: data.country,
        createdAt: data.createdAt,
        district: data.district,
        email: data.email,
        fullName: data.fullName,
        gender: data.gender,
        id: data.id,
        inDate: data.inDate,
        nId: data.nId,
        officeName: data.officeName,
        outDate: data.outDate,
        phone: data.phone,
        province: data.province,
        sector: data.sector,
        updatedAt: data.updatedAt,
        Apartments_Payment: null,
        Apartments_LastPayments: [],
      });
    }
  };

  const getSingleClientWithPayment = (id) => {
    setIsOnViewSingleClient(!isOnViewSingleClient);
    const data = payedClients.find((client) => client?.id == id);
    setSingleClientData(data);
  };
  const getSingleClientOverdue = (id) => {
    setIsOnViewSingleClient(!isOnViewSingleClient);
    const data = overdueClients.find((client) => client?.id == id);
    setSingleClientData(data);
  };

  // Warning for deleting client
  const deleteClientWarning = (id) => {
    setSelectedClientId(id);
    setIsOnDeletingClient(!isOnDeletingClient);
  };

  // Deleting client
  const deleteClient = () => {
    setIsDeletingClient(true);
    axios
      .delete(`${config.apiBaseUrl}/apts-clients/delete/${selectedClientId}`, {
        headers: { authorization: authUser.token },
      })
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
        setHouseClients(
          houseClients.filter((client) => client.id !== selectedClientId)
        );
        setIsOnDeletingClient(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsDeletingClient(false);
      });
  };
  // Warning for making house available
  const houseAvalableWarning = (id) => {
    setSelectedHouseId(id);
    setIsOnHouseAvailable(!isOnHouseAvailable);
  };

  // Available house
  let data = null;
  const availableHouse = () => {
    setIsHouseAvailable(true);
    axios
      .post(
        `${config.apiBaseUrl}/apartments/make-available/${selectedHouseId}`,
        data,

        {
          headers: { authorization: authUser.token },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });

        setIsOnHouseAvailable(false);
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsHouseAvailable(false);
      });
  };

  // search client of apartment
  const searchAptClientHandler = (event) => {
    if (event.keyCode === 13) {
      if (!aptClientValueToSearch) {
        return toast.error(
          "Please type either fullName, phone, email or national ID",
          {
            theme: "colored",
          }
        );
      }

      setIsSearchingAptClient(true);
      axios
        .get(
          `${config.apiBaseUrl}/apts-clients/search?searchValue=${aptClientValueToSearch} `,
          {
            headers: { authorization: authUser.token },
          }
        )
        .then((response) => {
          setHouseClients(response.data);
        })
        .catch((error) => {
          if (error.response?.data.error) {
            return toast.error(error.response.data.error, { theme: "colored" });
          }
          if (error.response?.data.message) {
            return toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          setIsSearchingAptClient(false);
          setLastPageClient(0);
          setNextPageClient(1);
        });
    }
  };

  // Search by range
  const searchPaymentByRangeHandler = ({ isPaid, isOverDue }) => {
    if (!dateRangeFom || !dateRangeTo) {
      return toast.error("Please select range dates", {
        theme: "colored",
      });
    }
    setIsSearchingInRange(true);
    const paidURL = `apts-finances/paid-range-report?startDate=${dateRangeFom}&endDate=${dateRangeTo}&page=1&limit=10`;
    const overDueURL = `apts-finances/overdue-range-report?startDate=${dateRangeFom}&endDate=${dateRangeTo}&page=1&limit=10`;
    const urlToUser = isPaid ? paidURL : overDueURL;
    axios
      .get(`${config.apiBaseUrl}/${urlToUser}`, {
        headers: { authorization: authUser.token },
      })
      .then((response) => {
        if (isPaid) {
          setPayedClients(response.data.data);
        }
        if (isOverDue) {
          setOverdueClients(response.data.data);
        }
      })
      .catch((error) => {
        if (error.response?.data.error) {
          return toast.error(error.response.data.error, { theme: "colored" });
        }
        if (error.response?.data.message) {
          return toast.error(error.response.data.message, {
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsSearchingInRange(false);
        setLastPagePaid(0);
        setNextPagePaid(1);
        setLastPageOverdue(0);
        setNextPageOverdue(1);
      });
  };

  // get payed excel
  const exportExcelPaidMethod = () => {
    if (!dateRangeFom || !dateRangeTo) {
      return toast.error("Please select range of dates", {
        theme: "colored",
      });
    }
    axios
      .get(
        `${config.apiBaseUrl}/export-data/apts-paid-range-report?startDate=${dateRangeFom}&endDate=${dateRangeTo}`,
        {
          headers: { authorization: authUser.token },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `paid_client.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        alert("Something went wrong");
      });
  };
  // get overdue excel
  const exportExcelOverDueMethod = () => {
    if (!dateRangeFom || !dateRangeTo) {
      return toast.error("Please select range of dates", {
        theme: "colored",
      });
    }
    axios
      .get(
        `${config.apiBaseUrl}/export-data/apts-paid-range-report?startDate=${dateRangeFom}&endDate=${dateRangeTo}`,
        {
          headers: { authorization: authUser.token },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Overdue_client.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        alert("Something went wrong");
      });
  };

  // Update payment
  const updateClientPayment = (clientPayment) => {
    setClientPaymentToUpdate(clientPayment);
    setIsUpdateClientPy(!isUpdateClientPy);
    setIsAddNewPayment(!isAddNewPayment);
  };

  return (
    <MainLayout>
      {/* deleting client */}
      {isOnDeletingClient && (
        <Alert
          title="Warning"
          warningMessage="Are you sure you want delete this user"
          handleCancel={deleteClientWarning}
          handleContinue={deleteClient}
          isLoading={isDeletingClient}
        />
      )}
      {/* house availability */}
      {isOnHouseAvailable && (
        <AvailableAlert
          title="Warning"
          warningMessage="Are you sure you want to make house available"
          handleCancel={houseAvalableWarning}
          handleContinue={availableHouse}
          isLoading={isHouseAvailable}
        />
      )}
      {/* adding new client */}
      {isAddNewClient && (
        <Modal handleClicks={onAddNewClientHandler}>
          <ClientForm
            returnNewAddedClient={returnNewAddedClient}
            isUpdateInProcess={isOnUpdateProcess}
            clientToUpdate={clientToUpdate}
            ApartmentId={addApartmentId}
            ApartmentsHouseId={addApartmentHouseId}
            handleView={onAddNewClientHandler}
          />
        </Modal>
      )}
      {/* is Adding NewPayment */}
      {isAddNewPayment && (
        <Modal handleClicks={onAddNewPaymentHandler}>
          <PaymentForm
            returnNewAddedPayment={returnNewAddedPayment}
            isUpdateInProcess={isUpdateClientPy}
            paymentToUpdate={clientPaymentToUpdate}
            ApartmentsClient={addApartmentClient}
            handleView={onAddNewPaymentHandler}
          />
        </Modal>
      )}

      {/*checkout client */}
      {isPutNewCheckout && (
        <Modal handleClicks={onCheckoutHandler}>
          <CheckoutForm
            returnCheckoutClient={returnCheckoutClient}
            id={checkoutClientId}
            handleView={onCheckoutHandler}
          />
        </Modal>
      )}

      {/* view single client */}
      {isOnViewSingleClient && (
        <Modal handleClicks={getSingleClientHandler}>
          <ViewClient
            singleClient={singleClientData}
            title="View Client"
            handleView={getSingleClientHandler}
          />
        </Modal>
      )}
      <div className="row fn-apts-cont">
        {apartmets.map((client, index) => (
          <div
            className={`col-2 col-md-3 col-sm-6 mb txt-success fn-apt-list ${
              client?.id == housesOfApartment?.id ? "fn-make-active" : ""
            }`}
            onClick={() => grabSelectedApartment(client)}
            key={index}
          >
            <p>{client?.name}</p>
          </div>
        ))}
      </div>
      <div className="ft-apts-pagination">
        {lastPageApt > 1 && nextPageApt < lastPageApt && (
          <p>
            <b>Click to load more apartments</b>
          </p>
        )}
        {nextPageApt === lastPageApt && nextPageApt > 1 && (
          <p>
            <b>Click to load previous apartments</b>
          </p>
        )}
        {(nextPageApt > 1 || isPrevLoadingApt) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Prev Page"
            color="#FF9066"
            bcolor="white"
            isLoading={isPrevLoadingApt}
            onClick={getAptsListOnPrevPage}
          />
        )}
        {(nextPageApt < lastPageApt || isNextLoadingApt) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Next Page"
            color="#FF9066"
            bcolor="white"
            isLoading={isNextLoadingApt}
            onClick={getAptsListOnNextPage}
          />
        )}
      </div>
      <div className="row">
        <div className="col-12 col-md-12 col-sm-12 mb">
          <Box>
            All Houses in: {housesOfApartment?.name}
            <Table
              houses={housesOfApartment?.ApartmentsHouses}
              addClientToHouse={onAddNewClientHandler}
              availableHouse={houseAvalableWarning}
            />
          </Box>
        </div>
      </div>
      <Tabs>
        <TabList className="title mb react-tabs__tab-panel--selected">
          <Tab>Clients</Tab>
          <Tab>Paid</Tab>
          <Tab>Overdue</Tab>
        </TabList>
        <TabPanel>
          <Box>
            <div className="searchbox">
              <h3>Apartments clients list</h3>

              <div className="group">
                <svg className="icon" aria-hidden="true" viewBox="0 0 24 24">
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                  </g>
                </svg>
                <input
                  placeholder="Search by name, phone, email or ID number"
                  type="search"
                  className="input"
                  onChange={(event) =>
                    setAptClientValueToSearch(event.target.value)
                  }
                  onKeyDown={searchAptClientHandler}
                />
                {isSearchingAptClient && <span>Loading...</span>}
              </div>
            </div>
            <HouseNotPayedTable
              clients={houseClients}
              getSingleClientHandler={getSingleClientHandler}
              addPaymentToHouse={onAddNewPaymentHandler}
              deleteClient={deleteClientWarning}
              updateClient={updateClient}
              removeClientToHouse={onCheckoutHandler}
              lastPage={lastPageClient}
              nextPage={nextPageClient}
              isPrevLoading={isPrevLoadingClient}
              isNextLoading={isNextLoadingClient}
              handleClickOnNext={getClientsListOnNextPage}
              handleClickOnPrev={getClientsListOnPrevPage}
            />
            {isLoadingClients && <Loading />}
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <div className="row">
              <div className="col-6 col-md-6 col-sm-12">
                <div className="date-range-container">
                  <div className="date-range">
                    <label>From</label>
                    <input
                      type="date"
                      className="date-range-search"
                      onChange={(event) => setDateRangeFom(event.target.value)}
                    />
                  </div>
                  <div className="date-range">
                    <label>To</label>
                    <input
                      type="date"
                      className="date-range-search"
                      onChange={(event) => setDateRangeTo(event.target.value)}
                    />
                  </div>

                  <Button
                    width="6rem"
                    border="#FF9066 solid 1px "
                    text="Search"
                    color="#FF9066"
                    bcolor="white"
                    className="col-2"
                    onClick={() =>
                      searchPaymentByRangeHandler({
                        isPaid: true,
                        isOverDue: false,
                      })
                    }
                  />
                  {isSearchingInRange && <span>Loading...</span>}
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-12">
                <Button
                  width="10rem"
                  border="#4CAF50 solid 1px "
                  text="Download excel"
                  color="#fff"
                  bcolor="#4CAF50"
                  className="col-2"
                  onClick={() => exportExcelPaidMethod()}
                />
              </div>
            </div>
            <PayedClientTable
              clients={payedClients}
              getSingleClientHandler={getSingleClientWithPayment}
              addPaymentToHouse={onAddNewPaymentHandler}
              updatePayment={updateClientPayment}
              lastPage={lastPagePaid}
              nextPage={nextPagePaid}
              isPrevLoading={isPrevLoadingPaid}
              isNextLoading={isNextLoadingPaid}
              handleClickOnNext={getPaidListOnNextPage}
              handleClickOnPrev={getPaidListOnPrevPage}
            />
            {isLoadingPaidClients && <Loading />}
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <div className="row">
              <div className="col-6 col-md-6 col-sm-12">
                <div className="date-range-container">
                  <div className="date-range">
                    <label>From</label>
                    <input
                      type="date"
                      className="date-range-search"
                      onChange={(event) => setDateRangeFom(event.target.value)}
                    />
                  </div>
                  <div className="date-range">
                    <label>To</label>
                    <input
                      type="date"
                      className="date-range-search"
                      onChange={(event) => setDateRangeTo(event.target.value)}
                    />
                  </div>

                  <Button
                    width="6rem"
                    border="#FF9066 solid 1px "
                    text="Search"
                    color="#FF9066"
                    bcolor="white"
                    className="col-2"
                    onClick={() =>
                      searchPaymentByRangeHandler({
                        isPaid: false,
                        isOverDue: true,
                      })
                    }
                  />
                  {isSearchingInRange && <span>Loading...</span>}
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-12">
                <Button
                  width="10rem"
                  border="#4CAF50 solid 1px "
                  text="Download excel"
                  color="#fff"
                  bcolor="#4CAF50"
                  className="col-2"
                  onClick={() => exportExcelOverDueMethod()}
                />
              </div>
            </div>
            <OverDueClientTable
              clients={overdueClients}
              getSingleClientHandler={getSingleClientOverdue}
              addPaymentToHouse={onAddNewPaymentHandler}
              lastPage={lastPageOverdue}
              nextPage={nextPageOverdue}
              isPrevLoading={isPrevLoadingOverdue}
              isNextLoading={isNextLoadingOverdue}
              handleClickOnNext={getOverdueListOnNextPage}
              handleClickOnPrev={getOverdueListOnPrevPage}
            />
            {isLoadingOverdueClients && <Loading />}
          </Box>
        </TabPanel>
      </Tabs>
    </MainLayout>
  );
};

export default FinanceApartment;
