import React from "react";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
import moment from "moment";
import Button from "../../Button";
const ServiceTable = ({
  services,
  getSingleServiceHandler,
  deleteService,
  lastPage,
  nextPage,
  isNextLoading,
  isPrevLoading,
  handleClickOnNext,
  handleClickOnPrev,
}) => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Time</th>
            <th>Name</th>
            <th>Email</th>

            <th>Service</th>
            <th>Status</th>
            <th>Comment</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {services.map((service) => (
            <tr key={service.id}>
              <td>{moment(service.createdAt).format("yyyy-MM-DD")}</td>
              <td>{service.fullName}</td>
              <td>{service.email}</td>

              <td>{service.service}</td>
              <td>
                <select>
                  <option value={service.status}>pending</option>
                  <option value={service.status}>in progress</option>
                  <option value={service.status}>Done</option>
                </select>
              </td>
              <td>{service.addComment}</td>
              <td>
                <CustomDropDown>
                  <button onClick={() => getSingleServiceHandler(service.id)}>
                    View
                  </button>

                  <button onClick={() => deleteService(service.id)}>
                    Delete
                  </button>
                </CustomDropDown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-btn">
        {(nextPage > 1 || isPrevLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Prev"
            color="#FF9066"
            bcolor="white"
            isLoading={isPrevLoading}
            onClick={handleClickOnPrev}
          />
        )}
        {(nextPage < lastPage || isNextLoading) && (
          <Button
            width="7rem"
            border="#FF9066 solid 1.5px"
            text="Next"
            color="#FF9066"
            bcolor="white"
            isLoading={isNextLoading}
            onClick={handleClickOnNext}
          />
        )}
      </div>
    </div>
  );
};

export default ServiceTable;
