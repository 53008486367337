import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import config from "../configs/config";
import Box from "../components/box/Box";
import MainLayout from "../layout/MainLayout";
import { MainContext } from "../middleware/Contexts";
import moment from "moment";
import { AiOutlineDelete, AiOutlineFolderOpen } from "react-icons/ai";
import Modal from "../components/CustomModal/Modal";
import PaymentDetails from "../components/dashboard/payment/PaymentDetails";
import { CiEdit } from "react-icons/ci";

const Finance = () => {
  const { authUser, setAuthUser } = useContext(MainContext);

  const [lastPage, setLastPage] = useState(0);
  const [paymentsList, setPaymentsList] = useState([]);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const handlerOpenDetail = (item) => {
    setIsOpenDetail(!isOpenDetail);
    setSelectedPayment(item);
  };

  useEffect(() => {
    axios(`${config.apiBaseUrl}/clients/report`, {
      headers: { authorization: authUser.token },
    })
      .then((response) => {
        setPaymentsList(response.data.data);
        setLastPage(response.data.lastPage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  }, []);
  return (
    <MainLayout>
      <Box>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Property type</th>
              <th>Property name</th>
              <th>House/office name</th>
              <th>Last paid date</th>
              <th>Amount</th>

              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {paymentsList.map((item) => (
              <tr key={item.id}>
                <td>{item.fullName}</td>
                <td>{item.gender}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>
                <td>{item.CheckInOuts[0].propertyType}</td>
                <td>{item.CheckInOuts[0].propertyName}</td>
                <td>{item.CheckInOuts[0].houseName}</td>
                <td>
                  {moment(
                    item.Payments.map((payment) => payment.lastPaidDate)
                  ).format("yyyy-MM-DD")}
                </td>
                <td>{item.Payments.map((payment) => payment.amount)}</td>

                <td>
                  <AiOutlineFolderOpen
                    title="View"
                    className="view"
                    onClick={() => handlerOpenDetail(item)}
                  />
                  <CiEdit
                    title="Edit"
                    className="edit"
                    // onClick={() => updatePayment(user)}
                  />
                  <AiOutlineDelete
                    title="Delete"
                    className="delete"
                    // onClick={() => deletePayment(user.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isOpenDetail && (
          <Modal handleClicks={handlerOpenDetail}>
            <PaymentDetails details={selectedPayment} />
          </Modal>
        )}
      </Box>
    </MainLayout>
  );
};

export default Finance;
