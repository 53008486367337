import { Title } from "chart.js";
import React, { useRef, forwardRef } from "react";
import { useReactToPrint } from "react-to-print";
import "../../CustomModal/singleview.scss";
import { AiFillPrinter } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import moment from "moment";
// import { useRef } from "react";
const Input = forwardRef((props, ref) => {
  return <input ref={ref} {...props} />;
});
const Alert = ({
  isLoading,
  handleView,
  handleContinue,
  title,
  singleclients,
}) => {
  const printingRef = useRef();
  const hundlePrint = useReactToPrint({
    content: () => printingRef.current,
    documentTitle: "Sampro print",
    onafterprint: () => alert("print is ok"),
  });
  return (
    <div ref={printingRef} className="print">
      <div className="singleview-shadow">
        <div className="icons">
          <GrFormClose onClick={handleView} className="close" />
        </div>
        <div className="singleview-center">
          <div className="singleview-header">
            <h3>{title}</h3>
          </div>
          <div className="singleview-content">
            {singleclients.map((client) => (
              <div key={client.id}>
                <div className="row">
                  <div className="col-6 col-md-6 col-sm-12 mb">
                    <p>
                      Fullname :<span>{client.fullName} </span>
                    </p>

                    <p>
                      birthDate :
                      <span>
                        {" "}
                        {moment(client.birthDate).format("yyyy-MM-DD")}
                      </span>
                    </p>
                    <p>
                      Gender :<span> {client.gender}</span>
                    </p>
                    <p>
                      Email :<span>{client.email}</span>
                    </p>
                  </div>
                  <div className="col-6 col-md-6 col-sm-12 mb">
                    <p>
                      Telephone :<span>{client.phone}</span>
                    </p>
                    <p>
                      Province :<span>{client.province}</span>
                    </p>
                    <p>
                      District :<span>{client.district}</span>
                    </p>
                    <p>
                      Sector :<span>{client.sector}</span>
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="icons">
        <AiFillPrinter onClick={() => hundlePrint()} className="myprint" />
      </div>
    </div>
  );
};

export default Alert;
